import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useCommunityChannelDetail } from 'hooks/community/queries';

import CommunityChannelEditContainer from 'containers/community/channel/CommunityChannelEditContainer';

import PageLoading from 'components/common/loading/PageLoading';
import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import EditChannelHeader from 'components/pages/community/channel/edit/EditChannelHeader';

import { ChannelPatchInfo } from 'types/community/internal';

const CommunityChannelEditPage = () => {
  const { id } = useParams();

  const { data: channelData, isLoading: channelIsLoading } = useCommunityChannelDetail({
    channel_id: id ? Number(id) : undefined,
  });
  const [channelInfo, setChannelInfo] = useState<ChannelPatchInfo | null>(null);

  useEffect(() => {
    if (channelData) {
      setChannelInfo({
        id: channelData.id,
        name: channelData.name,
        is_display: channelData.is_display,
        logo_url: channelData.logo_url,
        description: channelData.description,
        background_url: channelData.background_url,
      });
    }
  }, [channelData]);

  if (channelIsLoading || !channelData) return <PageLoading isLoading />;

  return (
    <DashboardLayout
      headerTitle="Community"
      innerPadding="40px"
      headerRight={<EditChannelHeader channelInfo={channelInfo} />}
    >
      <CommunityChannelEditContainer channelInfo={channelInfo} setChannelInfo={setChannelInfo} />
    </DashboardLayout>
  );
};

export default CommunityChannelEditPage;
