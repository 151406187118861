import { MessageDataTypeEnum } from 'constants/chatConstants';
import { t } from 'i18next';
import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import { getDate, getDateTime } from 'utils/common/day';

import StyledText from 'components/common/text/StyledText';
import UserMessage from 'components/pages/chat/room/talkPlus/Message/Normal/UserMessage';
import AcceptMsg from 'components/pages/chat/room/talkPlus/Message/Notice/AcceptMsg';
import Complete from 'components/pages/chat/room/talkPlus/Message/Notice/Complete';
import DeepLinkMessage from 'components/pages/chat/room/talkPlus/Message/Notice/DeepLinkMsg';
import Dropped from 'components/pages/chat/room/talkPlus/Message/Notice/Dropped';
import EndMessage from 'components/pages/chat/room/talkPlus/Message/Notice/End';
import JoinMessage from 'components/pages/chat/room/talkPlus/Message/Notice/Join';
import PrivateStartedMsg from 'components/pages/chat/room/talkPlus/Message/Notice/PrivateStartedMsg';
import WelcomeMessage from 'components/pages/chat/room/talkPlus/Message/Notice/Welcome';

import { color } from 'styles/assets';

interface Props {
  message: Message;
  dateDisplay: boolean;
  timeDisplay: boolean;
  profileDisplay: boolean;
  channelId: string;
  channelOwnerId?: string;
  viewMode?: boolean;
}

const ChatMessage = (props: Props) => {
  const { message, dateDisplay, channelId } = props;

  const type = message?.data?.['type'] as MessageDataTypeEnum;
  const shouldDisplayDateWithTime = ['1', '2', '3', '7', '8'].includes(type); // 그룹메세지의 맨 첫번째 메세지도 조건에 추가

  const renderMessageByType = () => {
    switch (type) {
      case MessageDataTypeEnum.ACCEPTED: {
        return <AcceptMsg channelId={channelId} />;
      }
      case MessageDataTypeEnum.COMPLETED: {
        return <Complete />;
      }
      case MessageDataTypeEnum.DROPPED: {
        return <Dropped text="Campaign Dropped 🕳️" />;
      }
      case MessageDataTypeEnum.PRIVATE_START: {
        return <PrivateStartedMsg />;
      }
      case MessageDataTypeEnum.PRIVATE_END: {
        return <Dropped text={`${t('chat.msg-notice-privateEnd')} 🕳️`} />;
      }
      case MessageDataTypeEnum.GROUP_START: {
        return <WelcomeMessage message={message} />;
      }
      case MessageDataTypeEnum.GROUP_JOIN: {
        return <JoinMessage message={message} />;
      }
      case MessageDataTypeEnum.GROUP_END: {
        return <EndMessage message={message} />;
      }
      case MessageDataTypeEnum.DEEPLINK: {
        return <DeepLinkMessage {...props} />;
      }
      default: {
        return <UserMessage {...props} />;
      }
    }
  };

  return (
    <>
      {shouldDisplayDateWithTime ? (
        <DateWrapper data-testid="date">
          <StyledText
            fontSize="sm"
            fontWeight="medium"
            fontColor="greya1"
            text={getDateTime(message.createdAt, 'DD / MMM / YYYY hh:mmA')}
          />
        </DateWrapper>
      ) : dateDisplay ? (
        <DateWrapper data-testid="date">
          <StyledText
            fontSize="sm"
            fontWeight="medium"
            fontColor="greya1"
            text={getDate(message.createdAt, 'DD / MMM / YYYY')}
          />
        </DateWrapper>
      ) : null}
      {renderMessageByType()}
    </>
  );
};

export default ChatMessage;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: fit-content;
  border-radius: 60px;

  background-color: ${color.greyf4};

  padding: 6px 18px;
`;
