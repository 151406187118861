import { colors } from '@nurihaus/web-design-system';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface TooltipProps {
  text: string;
  position: { x: number; y: number };
}

const Tooltip = ({ text, position }: TooltipProps) => {
  if (!text) return null;

  const tooltipContainer = document.getElementById('gnb-container');

  if (!tooltipContainer) return null;

  return createPortal(
    <TooltipContainer style={{ left: position.x, top: position.y }}>
      <Triangle />
      <TextContainer>{text}</TextContainer>
    </TooltipContainer>,
    tooltipContainer
  );
};

export default Tooltip;

const TooltipContainer = styled.div`
  position: fixed;
  z-index: 1000;
  pointer-events: none;
  display: flex;
  align-items: center;
`;

const Triangle = styled.div`
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid ${colors.purple39};
  width: 0;
  height: 0;
`;

const TextContainer = styled.div`
  background-color: ${colors.purple39};
  padding: 12px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: ${colors.white};
  white-space: nowrap;
`;
