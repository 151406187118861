import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getCreditHistory, getInfluencerDetail, getOtherUserProfile, getPointHistory } from 'api/account/User';
import { getCreditOrderList, getJellyOrderList } from 'api/lounge';

import { selectAccount } from 'features/redux/selectors/accounts';

import { PaginationType } from 'types/common';
import { JellyOrderData } from 'types/myJelly/internal';
import { PointHistoryDetail } from 'types/point/internal';

interface HistoryApiProps {
  page?: number;
  page_size?: number;
  type?: 'charge' | 'spend';
  username?: string | false;
  user_detail?: boolean;
  from?: string;
  to?: string;
}

interface OrderApiProps {
  page?: number;
  page_size?: number;
  username?: string;
  from?: string;
  to?: string;
  status?: string;
}

export const useUserPointHistory = ({
  pointType,
  apiProps,
}: {
  pointType: 'jelly' | 'credit';
  enabled?: boolean;
  apiProps: HistoryApiProps;
}) => {
  // * if username is undefined, login user's point history will be fetched
  // * if username is false, empty request will be sent
  const account = useSelector(selectAccount);
  const { access_token } = account.userInfo;

  const { page, page_size, type, username, user_detail, from, to } = apiProps;

  const getApi = {
    jelly: getPointHistory,
    credit: getCreditHistory,
  };

  return useQuery<PaginationType<PointHistoryDetail>, AxiosError>(
    ['pointHistory', { pointType, apiProps }],
    () => {
      const emptyRequest: () => Promise<PaginationType<PointHistoryDetail>> = async () => ({
        results: [],
        previous: null,
        next: null,
        count: 0,
      });
      if (username === false) return emptyRequest();

      return getApi[pointType](access_token, {
        page: page || 1,
        page_size: page_size || 10,
        type: type || 'charge',
        username,
        user_detail,
        from,
        to,
      });
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useUserOrderHistory = ({
  pointType,
  apiProps,
  enabled,
}: {
  pointType: 'jelly' | 'credit';
  enabled?: boolean;
  apiProps: OrderApiProps;
}) => {
  const account = useSelector(selectAccount);
  const { access_token } = account.userInfo;

  const { page, page_size, username, from, to, status } = apiProps;

  const getApi = {
    jelly: getJellyOrderList,
    credit: getCreditOrderList,
  };

  return useQuery<PaginationType<JellyOrderData>, AxiosError>(
    ['orderHistory', { pointType, apiProps }],
    () => {
      return getApi[pointType](access_token, {
        page: page || 1,
        page_size: page_size || 10,
        username,
        from,
        to,
        status,
      });
    },
    {
      keepPreviousData: true,
      enabled,
    }
  );
};

export const useUserDataDetail = ({ id, onSuccess }: { id: number; onSuccess?: (data: any) => void }) => {
  const { access_token } = useSelector(selectAccount).userInfo;

  const { data, isLoading, error, isError } = useQuery<any, AxiosError>(
    ['userDetail', { id }],
    () => getInfluencerDetail(access_token, id),
    {
      onSuccess,
    }
  );

  return { data, isLoading, error, isError };
};

export const useGetOtherUserProfile = ({ username, enabled }: { username: string; enabled?: boolean }) => {
  const { access_token } = useSelector(selectAccount).userInfo;

  return useQuery(['otherUserProfile', username], () => getOtherUserProfile(access_token, username), {
    retry: true,
    enabled,
  });
};

export const useGetInfluencerDetail = ({ id, enabled }: { id: number; enabled?: boolean }) => {
  const { access_token } = useSelector(selectAccount).userInfo;

  return useQuery(['influencerDetail', id], () => getInfluencerDetail(access_token, id), {
    retry: true,
    enabled,
  });
};
