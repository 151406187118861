/* eslint-disable */
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';

import { getLowerMenu, getUpperMenu } from 'utils/account/accessLevel';

import { usePostLogout } from 'hooks/account/queries';

import MenuIcon from 'components/Icons/MenuIcon';
import Avatar from 'components/common/Avatar';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import PointLabel from 'components/layout/dashboard/PointLabel';
import GNBItem from 'components/layout/dashboard/nav/GNBItem';

import { selectAccount } from 'features/redux/selectors/accounts';
import { selectCommon } from 'features/redux/selectors/common';
import { selectOption } from 'features/redux/selectors/options';
import { SetSidebarState } from 'features/redux/slices/optionSlice';

import { color } from 'styles/assets';

const GNB = () => {
  const dispatch = useDispatch();
  const option = useSelector(selectOption);
  const { guideLink } = useSelector(selectCommon);
  const account = useSelector(selectAccount);
  const { mutate } = usePostLogout();

  const { sidebarOpened } = option;
  const { user } = account.userInfo;
  const { get_profile, nickname, username } = user;

  const toggleDrawer = () => dispatch(SetSidebarState());

  const lowerMenu = getLowerMenu(guideLink, account.userInfo, mutate);
  const upperMenu = getUpperMenu(user);

  return (
    <ThemeProvider theme={{ isOpen: sidebarOpened }}>
      <Container id="gnb-container">
        <StateSection>
          <LogoBig src={require('../../../assets/Images/logo_renewal.png')} alt="logo" />
          <UserState>
            <Avatar avatarSize="s" src={get_profile} />
            <UserContents>
              <Gap side={10} />
              <StyledText fontWeight="bold" text={nickname || username} numberOfLines={1} textAlign="center" />
              <Gap side={10} />
              <PointLabel type="jelly" />
            </UserContents>
          </UserState>
        </StateSection>
        <NavSection>
          <UpperNav>
            {upperMenu.map((item, idx) => (
              <GNBItem key={idx} item={item} />
            ))}
          </UpperNav>
          <LowerNav>
            {lowerMenu.map((item, idx) => (
              <GNBItem item={item} key={idx} />
            ))}
          </LowerNav>
        </NavSection>
        <ButtonSection>
          <IconButton onClick={toggleDrawer} data-testid="drawer-btn">
            <MenuIcon type={sidebarOpened ? 'chevron_left' : 'chevron_right'} fill="grey18" />
          </IconButton>
        </ButtonSection>
      </Container>
    </ThemeProvider>
  );
};

export default GNB;

const Container = styled.div`
  width: ${({ theme }) => (theme.isOpen ? '248px' : '80px')};
  flex-shrink: 0;
  height: 100%;
  background-color: ${color.white};
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-out;
  overflow-y: scroll;
  z-index: 1;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StateSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => (theme.isOpen ? '74px 16px 13px 16px' : '93px 16px 35px 16px')};
  transition: height 0.3s ease-out;
`;

const LogoBig = styled.img`
  width: 103px;
  object-fit: cover;
  visibility: ${({ theme }) => (theme.isOpen ? 'visible' : 'hidden')};
  height: ${({ theme }) => (theme.isOpen ? '59px' : '0')};
  transition: height 0.3s ease-out;
`;

const UserState = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ theme }) => (theme.isOpen ? `1px solid ${color.greye4}` : 'none')};
  border-radius: 4px;
  padding: ${({ theme }) => (theme.isOpen ? '20px 12px' : '0')};
  margin-top: ${({ theme }) => (theme.isOpen ? '20px' : '0')};
  transition: all 0.3s ease-out;
  overflow: hidden;
`;

const UserContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  display: ${({ theme }) => (theme.isOpen ? 'auto' : 'none')};
  opacity: ${({ theme }) => (theme.isOpen ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in;
`;

const NavSection = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  // 스크롤 바 숨김
  ::-webkit-scrollbar {
    display: none;
  }
`;

const UpperNav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LowerNav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled.div`
  width: 100%;
  height: 42px;
  min-height: 42px;
  padding: ${({ theme }) => (theme.isOpen ? '9px 16px' : '9px 0')};
  display: flex;
  align-items: center;
  position: relative;
  border-top: 1px solid ${color.greye4};
  margin-top: 24px;
`;

const IconButton = styled.button`
  width: 24px;
  height: 24px;
  transform: ${({ theme }) => (theme.isOpen ? 'translateX(0)' : 'translateX(50%)')};
  position: absolute;
  right: ${({ theme }) => (theme.isOpen ? '16px' : '50%')};
  transition: transform 0.3s ease-out, right 0.3s ease-out;
`;
