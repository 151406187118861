import { ChangeEvent } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { Pagination } from '@mui/material';
import styled from 'styled-components';

import SectionLine from 'components/common/SectionLine';
import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import ReportTableItem from 'components/pages/community/report/list/ReportTableItem';

import { ReportListAPI } from 'types/account/remote';

interface Props {
  page: number;
  paginationHandler: (event: ChangeEvent<unknown>, page: number) => void;
  listData: ReportListAPI['Get']['Response'];
}

const ReportTable = ({ page, paginationHandler, listData }: Props) => {
  const { count, results: reportListResults } = listData;

  return (
    <Container>
      <Table gap={0} isEmpty={reportListResults?.length === 0}>
        <Table.Header borderBottom={{ width: 1, style: 'solid', color: 'greye4' }} paddingBottom={24} paddingTop={24}>
          <Table.Head minWidth="62px" flex={0.78}>
            <StyledText text={'Report ID'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
          <Table.Head minWidth="165px" flex={2.1}>
            <StyledText text={'Type'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
          <Table.Head minWidth="79px" flex={1}>
            <StyledText text={'Contents Type'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
          <Table.Head minWidth="79px" flex={1}>
            <StyledText text={'Contents ID'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
          <Table.Head minWidth="79px" flex={1}>
            <StyledText text={'Report User'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
          <Table.Head minWidth="134px" flex={1.7}>
            <StyledText text={'Report date'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
          <Table.Head minWidth="56px" flex={0.71}>
            <StyledText text={'Handled'} fontSize="sm" fontWeight="bold" />
          </Table.Head>
        </Table.Header>

        <Table.Body borderBottom={{ width: 1, style: 'solid', color: 'greye4' }}>
          {reportListResults?.map((data, index) => {
            return <ReportTableItem key={index} data={data} />;
          })}
        </Table.Body>
      </Table>

      <SectionLine height="1px" backgroundColor={color.greye4} marginBottom="0px" marginTop="0px" />

      <Pagination
        count={Math.ceil(count / 15) || 1}
        page={page}
        onChange={paginationHandler}
        boundaryCount={3}
        style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px', marginBottom: '20px' }}
      />
    </Container>
  );
};

export default ReportTable;

const Container = styled.div`
  background-color: ${color.white};
  border-radius: 6px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
