import { useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { OptionBadge } from '@nurihaus/web-design-system';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import MenuIcon from 'components/Icons/MenuIcon';
import StyledText from 'components/common/text/StyledText';
import ChannelDetailStatus from 'components/pages/community/channel/detail/ChannelDetailStatus';

import { CommunityChannelDetailAPI } from 'types/community/remote';

interface Props {
  data: CommunityChannelDetailAPI['Get']['Response'];
}

const ChannelDetailHeader = ({ data }: Props) => {
  const navigate = useNavigate();
  const [folded, setFolded] = useState(false);
  const handleNavigateToCreate = () => {
    navigate(`/community/manage/channel/edit/${data.id}`);
  };

  return (
    <Container>
      <ChannelInfoWrapper>
        <HeaderWrapper>
          <HeaderText>
            <StyledText text={`Channel Number: ${data.id}`} fontWeight="bold" />
            <IconButton
              onClick={handleNavigateToCreate}
              option={<Icon type="create" width={20} height={20} />}
              tooltipText={'Edit Channel'}
              tooltipStyle={{ top: '36px' }}
            />
          </HeaderText>
          <FoldButton onClick={() => setFolded(!folded)}>
            <MenuIcon type={folded ? 'chevron_bottom' : 'chevron_top'} fill="grey71" />
          </FoldButton>
        </HeaderWrapper>
      </ChannelInfoWrapper>

      {!folded && <ChannelDetailStatus data={data} />}
    </Container>
  );
};

export default ChannelDetailHeader;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const HeaderText = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const FoldButton = styled.button``;

const ChannelInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.greyd4};
  padding: 13px 30px;
`;

const IconButton = styled(OptionBadge)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: ${color.greyf4};

  :hover {
    background-color: ${color.violetf4};
    z-index: 2;
  }
`;
