import { Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';

interface Props {
  tab: string;
  handleChange: (e: React.SyntheticEvent, newValue: string) => void;
  tabArr: Array<{ label: string; value: string }>;
  style?: React.CSSProperties;
}

const ListTab = ({ tab, handleChange, tabArr, style }: Props) => {
  const getTabProps = (index: string): object => {
    return {
      id: `simple-tab-${index}`,
      key: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      value: index,
    };
  };

  return (
    <Tabs style={{ minHeight: 40, ...style }} value={tab} onChange={handleChange} className={'tabBtn-container'}>
      {tabArr.map((el, index) => (
        <Tab key={index} style={{ minHeight: 40, fontSize: '16px' }} {...el} {...getTabProps(el.value)} />
      ))}
      <style>
        {`
          .tabBtn-container::-webkit-scrollbar {
            display: none; /* Webkit 기반 브라우저에서 스크롤바 숨김 */
          }
        `}
      </style>
    </Tabs>
  );
};

export default ListTab;
