import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const PostListSearchInput = (props: Props) => {
  return (
    <Container>
      <SearchInput {...props} />
    </Container>
  );
};

export default PostListSearchInput;

const SearchInput = styled.input`
  height: 50px;
  width: 343px;
  padding: 14px;
  background-color: ${color.greyfa};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  ::placeholder {
    color: ${color.greya1};
  }
`;
const Container = styled.div`
  padding: 20px;
`;
