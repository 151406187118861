import { getUserRole } from '@NURIHAUS-Dev/core-business';
import { Avatar } from '@nurihaus/web-design-system';
import styled from 'styled-components';

import { getKSTFormattedDate } from 'utils/common/dateFormat';

import { useCountry } from 'hooks/common/queries';

import BadgeIcon from 'components/Icons/BadgeIcon';
import StyledText from 'components/common/text/StyledText';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

import { CommunityPostDetailAPI } from 'types/community/remote';

interface Props {
  author: CommunityPostDetailAPI['Get']['Response']['author'];
  createdAt: string;
}

const PostAuthorDateInfo = ({ author, createdAt }: Props) => {
  const { filteredCountry } = useCountry({ id: author.branch_id });

  const getNicknameText = () => {
    let text = '';
    if (filteredCountry) {
      text += filteredCountry.emoji;
    }

    text += author.nickname;

    return text;
  };

  return (
    <Container>
      <KeyValueWrapper style={{ width: '472px' }}>
        <KeyBadge text="Author" />
        <AvatarWrapper>
          <Avatar size="s" src={author.profile} badgeOption={<BadgeIcon userType={getUserRole(author as any)} />} />
          <StyledText text={getNicknameText()} fontSize="sm" fontWeight="bold" />
        </AvatarWrapper>
      </KeyValueWrapper>
      <KeyValueWrapper>
        <KeyBadge text="Created Date" />
        <StyledText text={getKSTFormattedDate(createdAt)} />
      </KeyValueWrapper>
    </Container>
  );
};

export default PostAuthorDateInfo;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const KeyValueWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
