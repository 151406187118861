import { useContext, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { Channel } from 'talkplus-sdk';

import Block from 'components/layout/common/Block';
import ChatStaffBrandList from 'components/pages/chat/list/staff/ChatStaffBrandList';
import ChatStaffCampaignList from 'components/pages/chat/list/staff/ChatStaffCampaignList';
import ChatStaffChannelList from 'components/pages/chat/list/staff/ChatStaffChannelList';
import TalkPlusChatRoom from 'components/pages/chat/room/talkPlus/TalkPlusChatRoom';

import { AppContext } from 'features/redux/context';
import { selectAccount } from 'features/redux/selectors/accounts';

import { UserDetails } from 'types/account/internal';
import { Campaign } from 'types/campaign/internal';

const ChatStaffContainer = () => {
  const { talkPlus } = useContext(AppContext);
  const { userInfo } = useSelector(selectAccount);
  const [loggedInAccount, setLoggedInAccount] = useState<{
    id: string;
    username: string;
    profileImageUrl: string;
  } | null>(null);
  const [focusedChannel, setFocusedChannel] = useState<string | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<UserDetails | null>(null);
  const [selectedCampaign, setSelectedCampaign] = useState<{
    campaignType: 'quest' | 'benefit';
    campaignData: Campaign;
  } | null>(null);
  const [mode, setMode] = useState<'edit' | 'view'>('view');

  const handleChannelFocus = (channelId: Channel['id'] | null) => setFocusedChannel(channelId);

  const handleSelectCampaign = (campaignType: 'quest' | 'benefit', campaignData: Campaign) => {
    handleChannelFocus(null);
    loginTalkPlus(campaignData.user).then(() => {
      setSelectedCampaign({ campaignType, campaignData });
    });
  };

  const handleSelectBrand = (brand: UserDetails) => {
    setSelectedBrand(brand);
  };

  const loginTalkPlus = async (user: UserDetails) => {
    try {
      await talkPlus.logout();

      const res = await talkPlus.loginAnonymous({
        userId: user.id.toString(),
        username: user.nickname,
        profileImageUrl: user.profile ?? '',
        data: {
          nuriUsername: user.username,
        },
      });

      setLoggedInAccount(res.user);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      setLoggedInAccount(null);
    }
  };

  const toggleMode = () => setMode((prev) => (prev === 'edit' ? 'view' : 'edit'));

  useEffect(() => {
    handleChannelFocus(null);
  }, [mode]);

  useEffect(() => {
    loginTalkPlus(selectedBrand ?? userInfo.user);
  }, []);

  if (!loggedInAccount) return null;

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          flexWrap: 'nowrap',
          overflowX: 'auto',
        }}
      >
        <ChatStaffBrandList selectBrand={handleSelectBrand} selectedBrand={selectedBrand} />
        {selectedBrand && (
          <ChatStaffCampaignList
            selectCampaign={handleSelectCampaign}
            selectedCampaign={selectedCampaign}
            selectedBrand={selectedBrand}
          />
        )}
        {selectedCampaign && (
          <ChatStaffChannelList
            key={selectedCampaign?.campaignData.id}
            focusChannel={handleChannelFocus}
            focusedChannel={focusedChannel}
            selectedCampaign={selectedCampaign}
            mode={mode}
            toggleMode={toggleMode}
          />
        )}

        {focusedChannel && (
          <Grid item sx={{ flex: '0 0 auto', width: { xs: '404px', md: '404px', lg: '404px' } }}>
            <Block height="630px" padding="0">
              <TalkPlusChatRoom
                channelId={focusedChannel}
                key={focusedChannel}
                focusChannel={handleChannelFocus}
                channelOwner={selectedBrand}
                viewMode={mode === 'view'}
              />
            </Block>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ChatStaffContainer;
