import { Dispatch, SetStateAction } from 'react';

import styled from 'styled-components';

import SelectInput from 'components/common/input/customMuiInput/SelectInput';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

import { ChannelPatchInfo } from 'types/community/internal';

interface Props {
  id?: number;
  name?: string;
  is_display?: boolean;
  setChannelInfo?: Dispatch<SetStateAction<ChannelPatchInfo | null>>;
}

const EditChannelID = ({ id, name, is_display, setChannelInfo }: Props) => {
  const selectInfo = [
    { value: true, label: 'Active' },
    { value: false, label: 'Disabled' },
  ];

  const handleChangeStatus = (e: any) => {
    if (setChannelInfo) {
      setChannelInfo((prev) => (prev ? { ...prev, is_display: e.target.value } : prev));
    }
  };

  return (
    <Block>
      <Container>
        <KeyValueWrapper>
          <KeyBadge text="Status" />
          <SelectInput width="150px" items={selectInfo} value={is_display || false} onChange={handleChangeStatus} />
        </KeyValueWrapper>
        <KeyValueWrapper>
          <KeyBadge text="Channel ID" />
          <StyledText text={String(id)} fontSize="sm" />
        </KeyValueWrapper>
        <KeyValueWrapper>
          <KeyBadge text="Channel Name" />
          <StyledText text={name} fontSize="sm" />
        </KeyValueWrapper>
      </Container>
    </Block>
  );
};

export default EditChannelID;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 40px;
`;

const KeyValueWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
