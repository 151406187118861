import { UserType } from '@NURIHAUS-Dev/core-business';

import { UserAccessControl } from 'types/permissions';

const NO_ACCESS: UserAccessControl = {
  dashboard: { access: false },

  chat: {
    access: false,
    groupChat: false,
    privateChat: false,
    campaignChat: false,
  },
  bulkChat: {
    access: false,
  },

  staffChat: {
    access: false,
  },

  banner: {
    access: false,
    allBranchAccess: false,
  },

  benefitList: {
    access: false,
    allBranchAccess: false,
  },

  benefitDetail: {
    access: false,
    bulkChat: false,
  },
  benefitCreateEdit: {
    access: false,
  },

  questList: {
    access: false,
    allBranchAccess: false,
  },

  questDetail: {
    access: false,
    bulkChat: false,
  },

  questCreateEdit: {
    access: false,
  },

  users: {
    access: false,
    allBranchAccess: false,
  },

  usersCreateEdit: {
    access: false,
  },

  client: {
    access: false,
  },

  myJellyCredit: {
    access: false,
    brandPurchaseHistory: false,
    remainingJelly: false,
    purchaseHistory: false,
    chargeHistory: false,
    paymentHistory: false,
    inquiries: false,
  },

  payout: {
    access: false,
  },

  analytics: {
    access: false,
  },

  notifications: {
    access: false,
  },

  community: {
    access: false,
  },

  setting: {
    access: false,
  },
};

export const USER_PERMISSIONS: Record<UserType, UserAccessControl> = {
  super: {
    dashboard: { access: true },

    chat: {
      access: true,
      groupChat: true,
      privateChat: true,
      campaignChat: true,
    },
    bulkChat: {
      access: true,
    },

    staffChat: {
      access: true,
    },

    banner: {
      access: true,
      allBranchAccess: true,
    },

    benefitList: {
      access: true,
      allBranchAccess: true,
    },

    benefitDetail: {
      access: true,
      bulkChat: true,
    },
    benefitCreateEdit: {
      access: true,
    },

    questList: {
      access: true,
      allBranchAccess: true,
    },

    questDetail: {
      access: true,
      bulkChat: true,
    },

    questCreateEdit: {
      access: true,
    },

    users: {
      access: true,
      allBranchAccess: true,
    },
    usersCreateEdit: {
      access: true,
    },

    client: {
      access: true,
    },

    myJellyCredit: {
      access: true,
      brandPurchaseHistory: true,
      remainingJelly: false,
      purchaseHistory: false,
      chargeHistory: false,
      paymentHistory: false,
      inquiries: false,
    },

    payout: {
      access: true,
    },

    analytics: {
      access: true,
    },

    notifications: {
      access: true,
    },

    community: {
      access: true,
    },

    setting: {
      access: true,
    },
  },

  partner: {
    ...NO_ACCESS,
    dashboard: { access: true },

    chat: {
      access: true,
      groupChat: true,
      privateChat: true,
      campaignChat: true,
    },

    community: {
      access: true,
    },

    bulkChat: {
      access: true,
    },

    staffChat: {
      access: true,
    },

    banner: {
      access: true,
      allBranchAccess: false,
    },

    benefitList: {
      access: true,
      allBranchAccess: false,
    },

    benefitDetail: {
      access: true,
      bulkChat: true,
    },

    benefitCreateEdit: {
      access: true,
    },

    questList: {
      access: true,
      allBranchAccess: false,
    },

    questDetail: {
      access: true,
      bulkChat: true,
    },

    questCreateEdit: {
      access: true,
    },

    users: {
      access: true,
      allBranchAccess: false,
    },

    usersCreateEdit: {
      access: false,
    },

    client: {
      access: false,
    },

    myJellyCredit: {
      access: false,
      brandPurchaseHistory: false,
      remainingJelly: false,
      purchaseHistory: false,
      chargeHistory: false,
      paymentHistory: false,
      inquiries: false,
    },

    payout: {
      access: false,
    },

    analytics: {
      access: false,
    },

    notifications: {
      access: true,
    },

    setting: {
      access: true,
    },
  },

  brand: {
    ...NO_ACCESS,
    dashboard: { access: true },

    chat: {
      access: true,
      groupChat: false,
      privateChat: false,
      campaignChat: true,
    },
    bulkChat: {
      access: false,
    },

    staffChat: {
      access: false,
    },

    banner: {
      access: false,
      allBranchAccess: false,
    },

    benefitList: {
      access: true,
      allBranchAccess: false,
    },

    benefitDetail: {
      access: true,
      bulkChat: false,
    },
    benefitCreateEdit: {
      access: true,
    },

    questList: {
      access: true,
      allBranchAccess: false,
    },

    questDetail: {
      access: true,
      bulkChat: false,
    },

    questCreateEdit: {
      access: true,
    },

    users: {
      access: false,
      allBranchAccess: false,
    },

    usersCreateEdit: {
      access: false,
    },

    client: {
      access: false,
    },

    myJellyCredit: {
      access: true,
      brandPurchaseHistory: false,
      remainingJelly: true,
      purchaseHistory: true,
      chargeHistory: true,
      paymentHistory: true,
      inquiries: true,
    },

    payout: {
      access: false,
    },

    analytics: {
      access: false,
    },

    notifications: {
      access: false,
    },

    setting: {
      access: true,
    },
  },

  influencer: NO_ACCESS,
  verified: NO_ACCESS,
  rejected: NO_ACCESS,
  normal: NO_ACCESS,

  nuricrew_leader: {
    ...NO_ACCESS,
    dashboard: {
      access: true,
    },
    chat: {
      access: true,
      groupChat: false,
      privateChat: false,
      campaignChat: true,
    },
    benefitList: {
      access: true,
      allBranchAccess: false,
    },

    benefitDetail: {
      access: true,
      bulkChat: false,
    },

    questList: {
      access: true,
      allBranchAccess: false,
    },

    questDetail: {
      access: true,
      bulkChat: false,
    },
  },
};

export const canPerformAction = (userType: UserType, action: keyof UserAccessControl): boolean => {
  const permission = USER_PERMISSIONS[userType]?.[action];

  // 권한 항목이 객체이면 `.access` 필드를 사용, 아니면 그대로 반환
  return typeof permission === 'boolean' ? permission : permission?.access ?? false;
};

export const hasAccessToPage = (userType: UserType, page: keyof UserAccessControl): boolean => {
  return canPerformAction(userType, page);
};
