// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio {
  &:where(.radio-group) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &:where(.radio-button) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--color-primary-gray-e4);
  }
  &:where(.radio-indicator) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-point-purple-39);
  }
  &:where(.radio-label) {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--color-primary-black);
  }
}
`, "",{"version":3,"sources":["webpack://./components/radio/radio-style.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,8CAA8C;EAChD;EACA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,8CAA8C;EAChD;EACA;IACE,8BAA8B;IAC9B,sCAAsC;IACtC,iCAAiC;EACnC;AACF","sourcesContent":[".radio {\n  &:where(.radio-group) {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n  }\n  &:where(.radio-button) {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 18px;\n    height: 18px;\n    border-radius: 50%;\n    background-color: var(--color-primary-gray-e4);\n  }\n  &:where(.radio-indicator) {\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    background-color: var(--color-point-purple-39);\n  }\n  &:where(.radio-label) {\n    font-size: var(--font-size-sm);\n    font-weight: var(--font-weight-medium);\n    color: var(--color-primary-black);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
