import { requestApi } from 'utils/common/axios-utils';

import { ReportDetailAPI, ReportListAPI } from 'types/account/remote';

export const getReportListAPI = (queryParams: ReportListAPI['Get']['QueryParams']) => {
  const { page, page_size } = queryParams;

  return requestApi({
    method: 'get',
    url: `accounts/report/list/`,
    query: { page, page_size, content_type: 'post,comment' },
  });
};

export const getReportDetailAPI = (queryParams: ReportDetailAPI['Get']['QueryParams']) => {
  const { report_id } = queryParams;

  return requestApi({
    method: 'get',
    url: `accounts/report/${report_id}/`,
  });
};

export const patchReportDetailAPI = (
  queryParams: ReportDetailAPI['Patch']['QueryParams'],
  data: ReportDetailAPI['Patch']['RequestBody']
) => {
  const { report_id } = queryParams;

  return requestApi({
    method: 'patch',
    url: `accounts/report/${report_id}/`,
    data,
  });
};
