import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, forwardRef } from "react";
import "./avatar-style.css";
import { BaseBadge } from "../badge/base-badge";
import defaultAvatar from "../../assets/icons/defaultAvatar.svg";
var Avatar = forwardRef(function (props, ref) {
    var size = props.size, src = props.src, alt = props.alt, badgeOption = props.badgeOption, style = props.style;
    var _a = useState(false), isImageDownload = _a[0], setIsImageDownload = _a[1];
    return (_jsxs("div", { className: "avatar ".concat(size), children: [_jsx("img", { src: src !== null && src !== void 0 ? src : defaultAvatar, alt: alt !== null && alt !== void 0 ? alt : "avatar", className: "avatar ".concat(size, " ").concat(!isImageDownload ? "hidden" : ""), onLoad: function () { return setIsImageDownload(true); }, style: style }), !isImageDownload && (_jsx("div", { className: "avatar avatar-skeleton ".concat(size), children: _jsx("div", { className: "avatar skeleton-animation" }) })), badgeOption && (_jsx(BaseBadge, { asChild: true, style: {
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                }, ref: ref, children: badgeOption }))] }));
});
Avatar.displayName = "Avatar";
export default Avatar;
