import { CTAButton } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { usePatchCommunityChannel } from 'hooks/community/queries';

import PageLoading from 'components/common/loading/PageLoading';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { ChannelPatchInfo } from 'types/community/internal';

interface Props {
  close: () => void;
  channelInfo: ChannelPatchInfo | null;
}

const EditConfirmModal = ({ close, channelInfo }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSuccess = () => {
    if (channelInfo) {
      close();
      navigate(`community/manage/channel/detail/${channelInfo.id}/`);
    }
  };
  const handleError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };
  const { mutate: patchChannel, isLoading } = usePatchCommunityChannel({ handleSuccess, handleError });

  const handleEditConfirmClick = () => {
    if (channelInfo && !isLoading) {
      patchChannel({ queryParams: { channel_id: channelInfo.id }, data: channelInfo });
    }
  };

  return (
    <ModalForm width="400px" handleClose={close}>
      {isLoading && <PageLoading isLoading />}
      <Container>
        <StyledText text="Edit Channel" fontSize="lg" fontWeight="bold" textAlign="center" />
        <Gap side={10} />
        <StyledText
          text={`Editing this channel may immediately impact the app. Click edit to continue.`}
          fontSize="sm"
          textAlign="center"
        />

        <FooterWrapper>
          <CTAButton size="m" style={{ width: '100%' }} variant="ghost" text="Cancel" onClick={close} />
          <CTAButton
            size="m"
            style={{ width: '100%' }}
            variant="primary"
            text="Edit"
            onClick={handleEditConfirmClick}
            disabled={isLoading}
          />
        </FooterWrapper>
      </Container>
    </ModalForm>
  );
};

export default EditConfirmModal;

const Container = styled.div`
  margin: -15px 0 0 0;
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 30px;
`;
