import { useOverlay } from '@nurihaus/use-overlay';
import { CTAButton } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { usePostCommunityPost } from 'hooks/community/queries';

import PageLoading from 'components/common/loading/PageLoading';
import CreatePostConfirmModal from 'components/pages/community/post/create/CreatePostConfirmModal';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { PostCreateInfo } from 'types/community/internal';
import { CommunityPostCreateAPI } from 'types/community/remote';

interface Props {
  postInfo: PostCreateInfo;
}

const CreatePostHeader = ({ postInfo }: Props) => {
  const dispatch = useDispatch();
  const confirmOverlay = useOverlay();
  const navigate = useNavigate();

  const handleSuccess = (response?: CommunityPostCreateAPI['Post']['Response']) => {
    navigate(`/community/manage/channel/post/${response?.id}`);
  };

  const handleError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };

  const { mutate: createPost, isLoading: isCreatePostLoading } = usePostCommunityPost({ handleSuccess, handleError });

  const handleCancelClick = () => {
    if (!isCreatePostLoading) {
      navigate(-1);
    }
  };

  const handleConfirm = () => {
    if (!isCreatePostLoading) {
      createPost(postInfo);
    }
  };

  const handlePostClick = () => {
    confirmOverlay.open(({ close }) => {
      return <CreatePostConfirmModal close={close} handleConfirm={handleConfirm} />;
    });
  };

  const disableToPost = postInfo.title.length < 5 || postInfo.content.length < 10;

  return (
    <Container>
      <PageLoading isLoading={isCreatePostLoading} />
      <CTAButton
        variant="ghost"
        text="Cancel"
        size="r"
        style={{ width: '172px' }}
        onClick={handleCancelClick}
        loading={isCreatePostLoading}
      />
      <CTAButton
        text="Post"
        size="r"
        style={{ width: '172px' }}
        onClick={handlePostClick}
        disabled={disableToPost}
        loading={isCreatePostLoading}
      />
    </Container>
  );
};

export default CreatePostHeader;

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
