import { color } from '@NURIHAUS-Dev/core-business';
import { OptionBadge } from '@nurihaus/web-design-system';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { addCommaToNumber } from 'utils/common/addCommaToNumber';

import Icon from 'components/Icons/Icon';
import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';

import { CommunityChannelListAPI } from 'types/community/remote';

interface Props {
  data: CommunityChannelListAPI['Get']['Response']['results'][0];
}

const ListTableRow = ({ data }: Props) => {
  const navigate = useNavigate();

  const handleCellClick = (id: number) => {
    navigate(`/community/manage/channel/detail/${id}`);
  };
  const handleEditClick = (id: number) => {
    navigate(`/community/manage/channel/edit/${id}`);
  };

  const status = data.is_display ? 'Active' : 'Disabled';

  return (
    <Container>
      <Table.Cell flex={1} minWidth="111px">
        <StyledText text={String(data.id)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell flex={0.4} minWidth="40px">
        <LogoImage src={data.logo_url} />
      </Table.Cell>
      <Table.Cell flex={2} handleClick={() => handleCellClick(data.id)}>
        <StyledText text={data.name} fontSize="sm" fontWeight="semiBold" textDecoration="underline" />
      </Table.Cell>
      <Table.Cell flex={0.8}>
        <StatusBadge isActive={status === 'Active'}>
          <StyledText
            text={status}
            fontSize="sm"
            fontWeight="semiBold"
            fontColor={status === 'Active' ? 'violet39' : 'grey71'}
          />
        </StatusBadge>
      </Table.Cell>
      <Table.Cell flex={0.6}>
        <StyledText text={addCommaToNumber(data.post_count)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell flex={0.6}>
        <StyledText text={addCommaToNumber(data.total_comments)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell flex={0.7}>
        <StyledText text={addCommaToNumber(data.total_views)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell flex={0.6}>
        <StyledText text={addCommaToNumber(data.total_likes)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell flex={0.4}>
        <EditButton
          option={<Icon type="edit" width={20} height={20} />}
          onClick={() => handleEditClick(data.id)}
          tooltipText={'Edit'}
          tooltipStyle={{
            top: '40px',
          }}
        />
      </Table.Cell>
    </Container>
  );
};

export default ListTableRow;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${color.greyf4};
`;

const EditButton = styled(OptionBadge)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${color.greyf4};

  :hover {
    background-color: ${color.violetf4};
    z-index: 2;
  }
`;

const StatusBadge = styled.div<{ isActive: boolean }>`
  width: 90px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ isActive }) => (isActive ? color.violet39 : color.grey71)};
`;
