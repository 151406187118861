import { useOverlay } from '@nurihaus/use-overlay';
import { CTAButton } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { addCommaToNumber } from 'utils/common/addCommaToNumber';
import { getKSTFormattedDate } from 'utils/common/dateFormat';
import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useCountry } from 'hooks/common/queries';
import { useGetCommunityPostDetail, usePatchCommunityPostDetail } from 'hooks/community/queries';

import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import HideContentsModal from 'components/pages/community/common/hide/HideContentsModal';
import ReactivateContentsModal from 'components/pages/community/common/hide/ReactivateContentsModal';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { CommunityChannelPostListAPI } from 'types/community/remote';

interface Props {
  index: number;
  data: CommunityChannelPostListAPI['Get']['Response']['results'][0];
}

const PostListTableRow = ({ data, index }: Props) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const hideOverlay = useOverlay();
  const dispatch = useDispatch();
  const reActiveOverlay = useOverlay();

  const { id, title, author, comment_count, view_count, like_count, created_at, is_display } = data;
  const { filteredCountry } = useCountry({ id: author.branch_id });
  const authorText = `${filteredCountry?.emoji || ''} ${author.nickname}`;

  const hideButtonText = is_display ? 'Hide' : 'Hidden';
  const hideButtonStyle = is_display ? 'primary' : 'ghost';
  const handleNavigateToDetailPost = () => {
    navigate(`/community/manage/channel/post/${id}`);
  };

  const { data: postData } = useGetCommunityPostDetail({ post_id: id });

  const handleSuccess = () => {
    queryClient.invalidateQueries(['postList']);
    queryClient.invalidateQueries(['channelDetail']);
    queryClient.invalidateQueries(['postDetail', id]);

    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: 'Success !',
        severity: 'success',
      })
    );
  };
  const handleError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };

  const { mutate: updatePost, isLoading: isUpdateLoading } = usePatchCommunityPostDetail({
    handleSuccess,
    handleError,
  });

  const hidePost = ({ reason_for_display_off }: { reason_for_display_off: string }) => {
    if (!isUpdateLoading) {
      const data = { reason_for_display_off, is_display: false };
      updatePost({ post_id: id, data }, { onSuccess: handleSuccess, onError: handleError });
    }
  };
  const reActivatePost = () => {
    if (!isUpdateLoading) {
      const data = { reason_for_display_off: '', is_display: true };
      updatePost({ post_id: id, data }, { onSuccess: handleSuccess, onError: handleError });
    }
  };
  const openHidePostModal = () => {
    hideOverlay.open(({ close }) => (
      <HideContentsModal type="post" contents={title} close={close} handleHideClick={hidePost} />
    ));
  };

  const openReactivatePostModal = () => {
    reActiveOverlay.open(({ close }) => (
      <ReactivateContentsModal
        type="Post"
        contents={postData?.title}
        reason={postData?.reason_for_display_off}
        close={close}
        handleReactivate={reActivatePost}
      />
    ));
  };

  const handleHideClick = () => {
    if (is_display) openHidePostModal();

    if (!is_display) openReactivatePostModal();
  };

  return (
    <Container>
      <Table.Cell flex={0.3} minWidth="57px">
        <StyledText text={String(index + 1)} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={0.7} minWidth="106px">
        <StyledText text={String(id)} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={1.6} minWidth="220px" handleClick={handleNavigateToDetailPost}>
        <StyledText text={title} fontSize="sm" fontWeight="semiBold" textDecoration="underline" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={1.2} minWidth="170px">
        <StyledText text={authorText} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={0.6} minWidth="90px">
        <StyledText text={addCommaToNumber(comment_count)} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={0.4} minWidth="70px">
        <StyledText text={addCommaToNumber(view_count)} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={0.7} minWidth="90px">
        <StyledText text={addCommaToNumber(like_count)} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={1.7} minWidth="207px">
        <StyledText text={getKSTFormattedDate(created_at)} fontSize="sm" numberOfLines={1} />
      </Table.Cell>
      <Table.Cell flex={0.7} minWidth="110px">
        <CTAButton
          style={{ width: '90px', height: '40px' }}
          size="s"
          text={hideButtonText}
          variant={hideButtonStyle}
          onClick={handleHideClick}
        />
      </Table.Cell>
    </Container>
  );
};

export default PostListTableRow;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
