import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';
import PostAuthorDateInfo from 'components/pages/community/post/detail/PostAuthorDateInfo';
import PostChannelInfo from 'components/pages/community/post/detail/PostChannelInfo';
import PostDetailInfo from 'components/pages/community/post/detail/PostDetailInfo';

import { CommunityPostDetailAPI } from 'types/community/remote';

interface Props {
  postData: CommunityPostDetailAPI['Get']['Response'];
}

const CommunityPostDetailContainer = ({ postData }: Props) => {
  const {
    channel_id,
    author,
    created_at,
    title,
    like_count,
    images,
    view_count,
    video_url,
    video_key,
    hashtags,
    content,
  } = postData;

  return (
    <>
      <Block padding="29px 30px" overflowY="hidden">
        <PostChannelInfo channelId={channel_id} />
      </Block>
      <Gap side={10} />
      <Block padding="27px 30px" overflowY="hidden">
        <PostAuthorDateInfo author={author} createdAt={created_at} />
      </Block>
      <Gap side={10} />
      <Block padding="0px" overflowY="hidden">
        <PostDetailInfo postData={{ title, like_count, images, view_count, video_url, video_key, hashtags, content }} />
      </Block>
    </>
  );
};

export default CommunityPostDetailContainer;
