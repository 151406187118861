import styled, { css } from 'styled-components';

import Icon from 'components/Icons/Icon';
import Ballon from 'components/layout/common/Ballon';

import { ColorTypes } from 'styles/assets';

interface Props {
  children: React.ReactNode;
  hoverElement?: React.ReactNode;
  tooltipDirection?: 'bottom' | 'right' | 'top' | 'left';
  testID?: string;
  width?: string;
  padding?: string;
  backgroundColor?: ColorTypes;
  borderColor?: ColorTypes;
}

const Tooltip = ({
  children,
  hoverElement,
  tooltipDirection = 'right',
  testID = 'tooltip',
  width = '250px',
  padding,
  backgroundColor,
  borderColor,
}: Props) => {
  return (
    <Container data-testid={testID}>
      {hoverElement || <Icon type="warning_circle" fill="reddf" />}
      <BallonWrapper direction={tooltipDirection} width={width}>
        <Ballon
          direction={tooltipDirection}
          padding={padding}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          {children}
        </Ballon>
      </BallonWrapper>
    </Container>
  );
};

export default Tooltip;

const ballonPosition = {
  bottom: css`
    top: 32px;
  `,
  right: css`
    left: 32px;
  `,
  top: css`
    bottom: 32px;
  `,
  left: css`
    right: 32px;
  `,
};

const BallonWrapper = styled.div<{ direction: Props['tooltipDirection']; width: string }>`
  display: none;
  transition: display 0.8s ease-in-out;
  position: absolute;
  ${({ direction }) => ballonPosition[direction as keyof typeof ballonPosition]}
  flex-direction: column;
  width: ${({ width }) => width};
  z-index: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  /* 하위 BallonWrapper display block */
  &:hover {
    ${BallonWrapper} {
      display: flex;
      margin-top: 3px;
    }
  }
`;
