import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { addCommaToNumber } from 'utils/common/addCommaToNumber';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

import { CommunityPostDetailAPI } from 'types/community/remote';

interface Props {
  postData: Pick<
    CommunityPostDetailAPI['Get']['Response'],
    'title' | 'images' | 'view_count' | 'like_count' | 'images' | 'hashtags' | 'video_key' | 'video_url' | 'content'
  >;
}

const PostDetailInfo = ({ postData }: Props) => {
  const { title, like_count, view_count, content, hashtags, video_url, images } = postData;

  return (
    <Container>
      <TitleWrapper>
        <KeyValueWrapper style={{ columnGap: '10px' }}>
          <KeyBadge text="Post Title" />
          <StyledText text={title} fontSize="sm" />
        </KeyValueWrapper>
        <TitleCountWrapper>
          <KeyValueWrapper style={{ columnGap: '6px' }}>
            <Icon type="heart" width={20} height={20} />
            <StyledText text={addCommaToNumber(like_count)} fontSize="sm" />
          </KeyValueWrapper>
          <KeyValueWrapper style={{ columnGap: '6px' }}>
            <Icon type="eye" width={20} height={20} />
            <StyledText text={addCommaToNumber(view_count)} fontSize="sm" />
          </KeyValueWrapper>
        </TitleCountWrapper>
      </TitleWrapper>
      <BodyWrapper>
        <KeyBadge text="Post Body" />
        <DescriptionWrapper>
          <StyledText text={content} fontSize="sm" />
        </DescriptionWrapper>
        <HashtagWrapper>
          {hashtags.map((hashtag, index) => {
            return (
              <StyledText key={index} text={`# ${hashtag}`} fontSize="sm" fontWeight="semiBold" fontColor="violet4c" />
            );
          })}
        </HashtagWrapper>

        <KeyBadge text="Attached Media" />

        {(video_url || images.length > 0) && (
          <VideoImageWrapper>
            {video_url && <VideoPreview src={video_url} controls preload="none" />}
            {images.map((img, index) => {
              return <ImagePreview key={index} src={img.file_url} alt={`Uploaded ${index + 1}`} />;
            })}
          </VideoImageWrapper>
        )}
      </BodyWrapper>
    </Container>
  );
};

export default PostDetailInfo;

const Container = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-bottom: 1px solid ${color.greyd4};
`;

const KeyValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleCountWrapper = styled.div`
  display: flex;
  column-gap: 30px;
`;

const BodyWrapper = styled.div`
  padding: 30px;
`;

const DescriptionWrapper = styled.div`
  padding: 20px 0 30px 0;
`;

const HashtagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  margin-bottom: 30px;
`;

const VideoImageWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  height: 209px;
  margin: 20px 0 0 0;
`;

const VideoPreview = styled.video`
  width: 100%;
  height: 100%;
  max-width: 209px;
  aspect-ratio: 1;
  border-radius: 6px;
  object-fit: cover;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  max-width: 209px;
  aspect-ratio: 1;
  border-radius: 6px;
  object-fit: cover;
`;
