import { ColorTypes } from 'styles/assets';

interface IButtonStyleThemeDetail {
  backgroundColor: ColorTypes;
  fontColor: ColorTypes;
  border?: {
    borderWidth: string;
    borderStyle: string;
    borderColor: ColorTypes;
  };
}
export type TButtonStyle = 'primary' | 'secondary' | 'ghost' | 'disabled' | 'sns' | 'underlined' | 'warning' | 'drop';

const buttonStyleTheme: Record<TButtonStyle, IButtonStyleThemeDetail> = {
  primary: {
    backgroundColor: 'violet39',
    fontColor: 'white',
    border: undefined,
  },
  secondary: {
    backgroundColor: 'violetf4',
    fontColor: 'violet39',
    border: undefined,
  },
  ghost: {
    backgroundColor: 'white',
    fontColor: 'violet4c',
    border: {
      borderWidth: '1.5px',
      borderStyle: 'solid',
      borderColor: 'greyd4',
    },
  },
  disabled: {
    backgroundColor: 'greye4',
    fontColor: 'white',
    border: undefined,
  },
  sns: {
    backgroundColor: 'transparent',
    fontColor: 'black',
    border: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'greyd4',
    },
  },
  underlined: {
    backgroundColor: 'transparent',
    fontColor: 'violet4c',
    border: undefined,
  },
  warning: {
    backgroundColor: 'reddf',
    fontColor: 'white',
    border: undefined,
  },
  drop: {
    backgroundColor: 'transparent',
    fontColor: 'magentaB0',
    border: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'magentaB0',
    },
  },
};

export type ButtonStyleTypes = keyof typeof buttonStyleTheme;
export { buttonStyleTheme };
