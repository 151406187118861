import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, } from "react";
import "./text-field-style.css";
import "../../styles/token/font.css";
var TextLabel = function (props) {
    var text = props.text, className = props.className;
    return _jsx("div", { className: className, children: text });
};
var InputField = function (props) {
    var className = props.className, placeholder = props.placeholder, name = props.name, onChange = props.onChange;
    var _a = useContext(TextFieldContext), convertErrorMessage = _a.convertErrorMessage, convertErrorState = _a.convertErrorState, validations = _a.validations;
    var validateInput = function (value) {
        if (validations) {
            var failedValidation = validations.find(function (validation) { return !validation.rule.test(value); });
            if (failedValidation) {
                convertErrorState(true);
                convertErrorMessage(failedValidation.message);
                return false;
            }
            convertErrorState(false);
            convertErrorMessage("");
            return true;
        }
        return true;
    };
    return (_jsx("input", { className: className, placeholder: placeholder, name: name, onChange: function (e) {
            validateInput(e.target.value);
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
        } }));
};
var HelperText = function (props) {
    var text = props.text, _a = props.className, className = _a === void 0 ? false : _a;
    var _b = useContext(TextFieldContext), errorMessage = _b.errorMessage, isError = _b.isError;
    var errorClassName = isError ? "error" : "";
    return (_jsx("div", { className: "".concat(className, " ").concat(errorClassName), children: errorMessage !== null && errorMessage !== void 0 ? errorMessage : text }));
};
var TextFieldContext = createContext({
    isError: false,
    convertErrorState: function () { },
    validations: [],
    errorMessage: "",
    convertErrorMessage: function (value) { },
});
var TextFieldRoot = function (_a) {
    var children = _a.children, validations = _a.validations, width = _a.width;
    var _b = useState(false), isError = _b[0], setIsError = _b[1];
    var _c = useState(""), errorMessage = _c[0], setErrorMessage = _c[1];
    var convertErrorState = function (value) {
        setIsError(value);
    };
    var convertErrorMessage = function (value) {
        setErrorMessage(value);
    };
    return (_jsx("span", { className: "text-field-root", style: { display: "block", width: "".concat(width, "px") }, children: _jsx(TextFieldContext.Provider, { value: {
                isError: isError,
                convertErrorState: convertErrorState,
                validations: validations,
                errorMessage: errorMessage,
                convertErrorMessage: convertErrorMessage,
            }, children: children }) }));
};
TextFieldRoot.TextLabel = TextLabel;
TextFieldRoot.InputField = InputField;
TextFieldRoot.HelperText = HelperText;
export default TextFieldRoot;
