var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { BaseBadge } from "./base-badge";
import { colors } from "../../styles/token/colors";
var OptionBadge = function (props) {
    var option = props.option, tooltipText = props.tooltipText, tooltipStyle = props.tooltipStyle, _a = props.tooltipPosition, tooltipPosition = _a === void 0 ? "bottom" : _a, restProps = __rest(props, ["option", "tooltipText", "tooltipStyle", "tooltipPosition"]);
    var _b = useState(false), isTooltipOpen = _b[0], setIsTooltipOpen = _b[1];
    return (_jsxs(BaseBadge, __assign({}, restProps, { children: [_jsx("div", { style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }, onMouseEnter: function () { return setIsTooltipOpen(true); }, onMouseLeave: function () { return setIsTooltipOpen(false); }, children: option }), tooltipText && (_jsxs("div", { style: __assign({ position: "absolute", display: isTooltipOpen ? "block" : "none" }, tooltipStyle), children: [_jsx("div", { style: __assign(__assign({ width: 0, height: 0 }, (tooltipPosition === "right"
                            ? {
                                borderTop: "6px solid transparent",
                                borderBottom: "6px solid transparent",
                                borderRight: "6px solid ".concat(colors.purple39),
                                position: "absolute",
                                top: "50%",
                                left: "-6px",
                                transform: "translateY(-50%)",
                            }
                            : {
                                borderLeft: "6px solid transparent",
                                borderRight: "6px solid transparent",
                                borderBottom: "6px solid ".concat(colors.purple39),
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                            })), { zIndex: 3 }) }), _jsx("div", { style: __assign(__assign({ backgroundColor: colors.purple39, color: "white", padding: "12px", borderRadius: "6px", fontSize: "12px", fontWeight: "400" }, (tooltipPosition === "right" ? {} : { marginTop: "6px" })), { whiteSpace: "nowrap" }), children: tooltipText })] }))] })));
};
export default OptionBadge;
