import { color } from '@NURIHAUS-Dev/core-business';
import { CTAButton } from '@nurihaus/web-design-system';
import styled from 'styled-components';

import { firstLetterUpperCase } from 'utils/common/firstLetterUpperCase';

import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

interface Props {
  type: string;
  contents?: string;
  reason?: string;
  close: () => void;
  handleReactivate: () => void;
}

const ReactivateContentsModal = ({ type, contents, reason, close, handleReactivate }: Props) => {
  return (
    <ModalForm handleClose={close} width="500px">
      <Container>
        <TitleSection>
          <StyledText text="Reactivate Hidden Contents" fontSize="lg" fontWeight="bold" />
          <StyledText
            text="Are you sure you want to display this hidden content again?"
            fontSize="sm"
            fontColor="grey52"
          />
        </TitleSection>
        <Gap side={30} />
        <DescriptionSection>
          <TextWrapper>
            <StyledText text="• Type:" fontSize="sm" fontColor="grey52" fontWeight="semiBold" />
            <StyledText text={firstLetterUpperCase(type)} fontSize="sm" />
          </TextWrapper>
          <Gap side={16} />
          <TextWrapper>
            <StyledText text="• Contents:" fontSize="sm" fontColor="grey52" fontWeight="semiBold" />
            <StyledText text={contents} numberOfLines={1} fontSize="sm" wordBreak="break-word" />
          </TextWrapper>
          <Gap side={16} />

          <StyledText text="• Hide Reason" fontSize="sm" fontColor="grey52" fontWeight="semiBold" />
          <Gap side={14} />
          <ReasonWrapper>
            <StyledText text={reason} numberOfLines={1} fontSize="xs" fontWeight="regular" wordBreak="break-word" />
          </ReasonWrapper>
        </DescriptionSection>
        <Gap side={50} />
        <FooterSection>
          <CTAButton style={{ width: '100%' }} text="Cancel" size="m" variant="ghost" onClick={close} />
          <CTAButton
            style={{ width: '100%' }}
            text="Reactivate"
            size="m"
            onClick={() => {
              handleReactivate();
              close();
            }}
          />
        </FooterSection>
      </Container>
    </ModalForm>
  );
};

export default ReactivateContentsModal;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 60px);
  justify-content: center;
  align-items: center;
  margin: -15px -30px 0px -30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${color.greye4};
`;
const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterSection = styled.div`
  display: flex;
  column-gap: 16px;
`;
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const ReasonWrapper = styled.div`
  width: 100%;
  height: 200px;
  padding: 14px;
  background-color: ${color.greyf4};
  border-radius: 4px;
`;
