import Block from 'components/layout/common/Block';
import ReportCommentInfo from 'components/pages/community/report/detail/ReportCommentInfo';
import ReportPostInfo from 'components/pages/community/report/detail/ReportPostInfo';

interface Props {
  contentId: number;
  contentType: number;
}

const ReportPostBlock = ({ contentId, contentType }: Props) => {
  return (
    <Block padding="0px">
      {contentType === 342 && <ReportPostInfo postId={contentId} />}
      {contentType === 345 && <ReportCommentInfo commentId={contentId} />}
    </Block>
  );
};

export default ReportPostBlock;
