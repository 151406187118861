import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import { useExternalState } from "../../helpers/hooks/useExternalState";
import Composer from "../core/composer";
import "../../styles/token/color.css";
import "../../styles/token/font.css";
var RadioButton = function (props) {
    var children = props.children, asChild = props.asChild, className = props.className, id = props.id;
    var _a = useContext(RadioContext), selected = _a.selected, onChange = _a.onChange;
    var Comp = asChild ? Composer : "div";
    return (_jsx(Comp, { className: "nuri-theme ".concat(className), onClick: function () { return onChange(id); }, children: id === selected ? children : null }));
};
var RadioIndicator = function (props) {
    var children = props.children, asChild = props.asChild, id = props.id, className = props.className;
    var selected = useContext(RadioContext).selected;
    var Comp = asChild ? Composer : "input";
    return (_jsx(Comp, { type: "radio", className: "nuri-theme ".concat(selected === id ? "selected" : "", " ").concat(className), checked: selected === id, children: children }));
};
var RadioLabel = function (props) {
    var children = props.children, asChild = props.asChild, className = props.className;
    var Comp = asChild ? Composer : "label";
    return _jsx(Comp, { className: "nuri-theme ".concat(className), children: children });
};
var RadioOptionGroup = function (props) {
    var children = props.children, asChild = props.asChild, className = props.className;
    var Comp = asChild ? Composer : "ul";
    return _jsx(Comp, { className: className, children: children });
};
var RadioContext = createContext({
    options: [],
    selected: null,
    onChange: function (id) { },
});
var RadioRoot = function (props) {
    var children = props.children, options = props.options, _a = props.selected, selected = _a === void 0 ? null : _a, externalOnChange = props.onChange;
    var _b = useExternalState({
        prop: selected,
        handler: externalOnChange,
        defaultProp: selected,
    }), selectedId = _b[0], setSelectedId = _b[1];
    var onChange = function (id) {
        setSelectedId(id);
    };
    return (_jsx(RadioContext.Provider, { value: { selected: selectedId, onChange: onChange, options: options }, children: children }));
};
RadioRoot.Indicator = RadioIndicator;
RadioRoot.Label = RadioLabel;
RadioRoot.OptionGroup = RadioOptionGroup;
RadioRoot.Button = RadioButton;
export default RadioRoot;
