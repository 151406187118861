import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

interface HashtagInputProps {
  hashtags: string[];
  setHashtags: (hashtags: string[]) => void;
}

const HashtagInput = ({ hashtags, setHashtags }: HashtagInputProps) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const widthRefs = useRef<number[]>([]);
  const [lastFocusedIndex, setLastFocusedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (hashtags.length > 0 && lastFocusedIndex === null) {
      setTimeout(() => {
        const lastIndex = hashtags.length - 1;
        inputRefs.current[lastIndex]?.focus();
      }, 50);
    }
  }, [hashtags, lastFocusedIndex]);

  const updateWidth = (index: number) => {
    requestAnimationFrame(() => {
      const input = inputRefs.current[index];
      if (input) {
        const textWidth = input.scrollWidth;
        widthRefs.current[index] = Math.max(40, textWidth);
        input.style.width = `${widthRefs.current[index]}px`;
      }
    });
  };

  const handleHashtagChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/^#\s*/, ''); // `# ` 자동 제거
    const newHashtags = [...hashtags];
    newHashtags[index] = value;
    setHashtags(newHashtags);
    updateWidth(index);
  };

  const handleHashtagKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (hashtags[index].trim() === '' || hashtags[hashtags.length - 1] === '') return;

      setLastFocusedIndex(null); // ✅ 수동 포커스 해제
      setHashtags([...hashtags, '']);
    }

    if (e.key === 'Backspace' && hashtags[index].trim() === '' && hashtags.length > 1) {
      e.preventDefault();
      setLastFocusedIndex(null); // ✅ 수동 포커스 해제
      setHashtags(hashtags.filter((_, i) => i !== index));
    }
  };

  const handleHashtagBlur = (index: number) => {
    if (hashtags[index].trim() === '') {
      setLastFocusedIndex(null); // ✅ 수동 포커스 해제
      setHashtags(hashtags.filter((_, i) => i !== index));
    }
  };

  const handleHashtagFocus = (index: number) => {
    setLastFocusedIndex(index); // ✅ 사용자가 직접 클릭한 input 기억
  };

  return (
    <HashtagContainer>
      {hashtags.map((tag, index) => (
        <HashtagInputWrapper key={index}>
          <span>#</span>
          <HashtagInputStyled
            ref={(el) => (inputRefs.current[index] = el)}
            value={tag}
            onChange={(e) => handleHashtagChange(index, e)}
            onKeyDown={(e) => handleHashtagKeyDown(index, e)}
            onBlur={() => handleHashtagBlur(index)}
            onFocus={() => handleHashtagFocus(index)}
            style={{ width: `${widthRefs.current[index] || 40}px` }}
          />
        </HashtagInputWrapper>
      ))}
    </HashtagContainer>
  );
};

export default HashtagInput;

const HashtagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 4px;
`;

const HashtagInputWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${color.violet4c};
  font-size: 14px;
  font-weight: 600;
`;

const HashtagInputStyled = styled.input`
  height: 18px;
  min-width: 40px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  border: none;
  color: ${color.violet4c};
  background: transparent;
  text-align: left;
`;
