import { useRef, useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useRouter } from 'hooks/common/useRouter';

import GNBIcon, { GNBIconType } from 'components/Icons/GNBIcon';
import StyledText from 'components/common/text/StyledText';
import Tooltip from 'components/layout/dashboard/nav/Tooltip';

import { selectOption } from 'features/redux/selectors/options';

const GNBItem = ({
  item,
  isSubItem = false,
}: {
  item: {
    primary: string;
    pathname: string;
    query?: { [key: string]: string };
    check?: (p: string) => boolean;
    onClick?: () => void;
    subMenu?: { primary: string; pathname: string; check?: (p: string) => boolean }[];
  };
  isSubItem?: boolean;
}) => {
  const route = useRouter();
  const location = useLocation();
  const { pathname } = location;
  const option = useSelector(selectOption);
  const { sidebarOpened } = option;

  const { primary, pathname: itemPath, query, check, onClick, subMenu } = item;

  const isCurrentItemSelected = check ? check(pathname) : false;
  const isSubMenuSelected = subMenu?.some((subItem) => subItem.check?.(pathname)) ?? false;
  const selected = isCurrentItemSelected || isSubMenuSelected;

  const [isOpen, setIsOpen] = useState(selected);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const iconRef = useRef<HTMLDivElement>(null);

  const toggleSubMenu = () => {
    setIsOpen((prev) => !prev);
    route.push(`/${itemPath}`, query);
  };

  const handleMouseEnter = () => {
    if (sidebarOpened) return;

    const iconElement = iconRef.current;

    const tooltipHeight = 36;

    if (iconElement) {
      const rect = iconElement.getBoundingClientRect();
      setTooltipPosition({
        x: rect.right + 12,
        y: (rect.top + rect.bottom) / 2 - tooltipHeight / 2,
      });
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <NavItem
        data-testid="menu-item"
        selected={selected}
        onClick={subMenu ? toggleSubMenu : onClick ? onClick : () => route.push(`/${itemPath}`, query)}
        isSubItem={isSubItem}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <NavItemContent>
          <IconWrapper ref={iconRef}>
            <GNBIcon type={itemPath as GNBIconType} width={24} height={24} color="grey52" />
          </IconWrapper>
          {sidebarOpened && (
            <StyledText
              fontSize="base"
              text={primary}
              numberOfLines={1}
              fontWeight={selected ? 'bold' : 'medium'}
              fontColor={selected ? 'violet39' : 'grey52'}
            />
          )}
        </NavItemContent>
      </NavItem>

      {!sidebarOpened && showTooltip && <Tooltip text={primary || ''} position={tooltipPosition} />}

      {/* 서브 메뉴 렌더링 */}
      {subMenu && isOpen && (
        <SubMenu>
          {subMenu.map((subItem, idx) => (
            <GNBItem key={idx} item={subItem} isSubItem />
          ))}
        </SubMenu>
      )}
    </>
  );
};

export default GNBItem;

const NavItem = styled.button<{ selected: boolean; isSubItem?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease-out;
  background-color: ${({ selected, isSubItem }) => (selected && !isSubItem ? color.greyf4 : color.white)};
  opacity: 0.8;
  &:hover {
    opacity: 1;
    background-color: ${({ isSubItem }) => (isSubItem ? color.transparent : color.greyf4)};
  }
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;

const NavItemContent = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${({ theme }) => (theme.isOpen ? '16px' : '50%')};
  transform: ${({ theme }) => (theme.isOpen ? 'translateX(0)' : 'translateX(-50%)')};
  transition: transform 0.3s ease-out, right 0.3s ease-out;
  gap: 8px;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`;
