import { useParams } from 'react-router-dom';

import { useGetReportDetail } from 'hooks/account/queries';

import PageLoading from 'components/common/loading/PageLoading';
import Gap from 'components/layout/common/Gap';
import ReportDetailHeader from 'components/pages/community/report/detail/ReportDetailHeader';
import ReportDetailIDBlock from 'components/pages/community/report/detail/ReportDetailIDBlock';
import ReportPostBlock from 'components/pages/community/report/detail/ReportPostBlock';
import ReportUserBlock from 'components/pages/community/report/detail/ReportUserBlock';

const CommunityReportDetailContainer = () => {
  const { id } = useParams();

  const { data, isLoading } = useGetReportDetail({ report_id: Number(id) });

  if (isLoading || !data) return <PageLoading isLoading />;

  return (
    <>
      <ReportDetailHeader
        reportId={data.report_id}
        contentId={data.object_id}
        contentType={data.content_type_id}
        isActive={data.is_active}
      />
      <Gap side={14} />
      <ReportDetailIDBlock reportId={data.report_id} reportType={data.report_type} created={data.created} />
      <Gap side={10} />
      <ReportUserBlock reportUserId={data?.report_author.id} contentStatus={data.content_status} />
      <Gap side={10} />
      <ReportPostBlock contentId={data.object_id} contentType={data.content_type_id} />
    </>
  );
};

export default CommunityReportDetailContainer;
