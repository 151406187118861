import { memo, useState } from 'react';

import { MenuItem, styled as MuiStyled, Select, SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';

import MenuIcon from 'components/Icons/MenuIcon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import { Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';

interface Props {
  label?: string;
  items?: { value: string | number | any; label: string }[];
  value?: string | number | boolean;
  disabled?: boolean;
  onChange?: (e: SelectChangeEvent<any>) => void;
  required?: boolean;
  width?: string;
  height?: string;
  padding?: number[];
  placeholder?: string;
  displayEmpty?: boolean;
  children?: any;
}

const SelectInput = ({
  label,
  items = [],
  value,
  disabled = false,
  onChange,
  required = false,
  width = '100%',
  height = '50px',
  placeholder = '',
  displayEmpty = false,
  padding = [14, 0, 14, 14],
  children,
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpenClose = () => {
    if (disabled) return;

    setOpen(!open);
  };

  return (
    <Wrapper disabled={disabled} data-testid={`select-input-wrapper-${label}`} width={width} onClick={handleOpenClose}>
      {label && (
        <>
          <Row justifyContent="flex-start">
            <StyledText fontSize="base" fontWeight="bold" text={label} />
            {required && <StyledText fontSize="base" fontWeight="bold" text="*" fontColor="reddf" />}
          </Row>
          <Gap side={6} />
        </>
      )}

      <SelectorContentContainer>
        <SelectCustom
          data-testid="custom-input"
          name="select"
          SelectDisplayProps={{
            style: {
              width: `calc(100% - ${24 + padding[3] * 2}px)`,
              paddingTop: padding[0],
              paddingRight: padding[1],
              paddingBottom: padding[2],
              paddingLeft: padding[3],
              display: 'flex',
              alignItems: 'center',
            },
          }}
          open={open}
          onOpen={handleOpenClose}
          onClose={handleOpenClose}
          IconComponent={() => <></>}
          onChange={onChange}
          disabled={disabled}
          sx={{ height, borderRadius: '6px' }}
          value={value}
          displayEmpty={displayEmpty}
          fullWidth
        >
          {placeholder && (
            <MenuItem value="" disabled>
              <StyledText text={placeholder} fontColor="greya1" fontSize="sm" />
            </MenuItem>
          )}

          {children
            ? children
            : items.map((el) => (
                <MenuItem
                  data-testid={`select-option-${el?.value}`}
                  value={el?.value}
                  key={`${el?.label}-selectItem${el?.value}`}
                >
                  <StyledText text={el?.label} fontSize="sm" />
                </MenuItem>
              ))}
        </SelectCustom>
        {!disabled && (
          <IconPosition>
            <OpenIcon data-testid="icon-open" opened={open} paddingTop={padding[0]} disabled={disabled}>
              <MenuIcon type="chevron_bottom" fill="grey71" width={18} height={18} />
            </OpenIcon>
          </IconPosition>
        )}
      </SelectorContentContainer>
    </Wrapper>
  );
};

export default memo(SelectInput);

const SelectCustom = MuiStyled(Select)(() => ({
  '& .MuiOutlinedInput-input': {
    '&.MuiSelect-select': {
      minHeight: '18px',
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: 'black',
      cursor: 'not-allowed',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1.5px solid ${color.greyd4}`,
  },
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1.5px solid ${color.violet39}`,
    },
  },
  ':hover .MuiOutlinedInput-notchedOutline': {
    border: `1.5px solid ${color.violet39}`,
  },
  '&.Mui-disabled': {
    backgroundColor: color.greyf4,
    svg: {
      display: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

const Wrapper = styled.div<{ width: string; disabled?: boolean }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const IconPosition = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const OpenIcon = styled.div<{ opened: boolean; paddingTop: any; disabled?: boolean }>`
  width: 18px;
  height: 18px;
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const SelectorContentContainer = styled.div`
  position: relative;
`;
