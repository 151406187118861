import { RefObject, useEffect, useLayoutEffect, useRef } from 'react';

import { useIntersection } from '@nurihaus/react';
import { getDate } from 'date-fns';
import styled from 'styled-components';
import { Message } from 'talkplus-sdk';

import { compareTimestampsByMinute } from 'utils/chat/compareTimestampsByMinute';

import { useChat } from 'hooks/chat/queries';
import { useQueryParams } from 'hooks/common/useQueryParams';

import PinnedArea from 'components/pages/chat/groupChat/talkplus/pinned/PinnedArea';
import CampaignInfo from 'components/pages/chat/room/talkPlus/CampaignInfo';
import ChatInput from 'components/pages/chat/room/talkPlus/ChatInput';
import ChatMessage from 'components/pages/chat/room/talkPlus/Message';
import ChatUserInfo from 'components/pages/chat/room/talkPlus/UserInfo';

import { UserDetails } from 'types/account/internal';
import { ChannelListTabType } from 'types/chat/internal';

interface Props {
  channelId: string;
  maxHeight?: string;
  focusChannel?: (channelId: string | null) => void;
  channelOwner?: UserDetails | null;
  viewMode?: boolean;
}

const TalkPlusChatRoom = ({ maxHeight, channelId, focusChannel, channelOwner, viewMode = false }: Props) => {
  const { useGetMessages } = useChat();
  const { data, fetchNextPage, isSuccess, hasNextPage } = useGetMessages({ channelId: channelId });

  const { intersectionTarget } = useIntersection(fetchNextPage, {}, hasNextPage ?? false);

  const chatLogRef = useRef<HTMLDivElement | null>(null);
  const height = useRef<number | null>(null);
  const query = useQueryParams();
  const tab = query.get('tab') as ChannelListTabType;

  const messageRenderer = (message: Message, index: number) => {
    const messagesList = data?.slice(0).reverse() || [];
    let dateDisplay = false;
    let timeDisplay = false;
    let profileDisplay = false;

    if (index === 0) dateDisplay = true; // first msg
    else if (getDate(messagesList[index - 1].createdAt) !== getDate(message.createdAt)) dateDisplay = true; // date change

    if (index === messagesList.length - 1) timeDisplay = true; // last msg
    else if (!compareTimestampsByMinute(messagesList[index + 1]?.createdAt, message?.createdAt)) timeDisplay = true; // time change

    if (index === 0) profileDisplay = true; // first msg
    else if (messagesList[index - 1].username !== message.username) profileDisplay = true; // sender change
    else if (dateDisplay) profileDisplay = true; // date change
    else if ('type' in (messagesList[index - 1].data ?? {}) && !('type' in (messagesList[index].data ?? {})))
      profileDisplay = true; // previous message is system message and current message is not

    return (
      <ChatMessage
        key={index}
        message={message}
        dateDisplay={dateDisplay}
        timeDisplay={timeDisplay}
        profileDisplay={profileDisplay}
        channelId={channelId}
        channelOwnerId={channelOwner?.id.toString()}
        viewMode={viewMode}
      />
    );
  };

  useLayoutEffect(() => {
    const chatLog = chatLogRef.current;

    if (!chatLog || !data || !isSuccess) return;

    const { scrollHeight } = chatLog;

    if (!height.current) {
      height.current = scrollHeight;
      chatLog.scrollTop = scrollHeight; // 처음 로딩 시 가장 아래로 스크롤

      return;
    }

    const gap = scrollHeight - height.current;

    if (gap !== 0) {
      chatLog.scrollTop += gap; // 새 메시지 추가 시 아래로 스크롤
      height.current = scrollHeight; // 높이 업데이트
    }
  }, [data, isSuccess]);

  useEffect(() => {
    const handleMessageSent = () => {
      if (!height.current || !chatLogRef.current?.scrollTop) return;

      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    };

    window.addEventListener('chatMessageSent', handleMessageSent);

    return () => {
      window.removeEventListener('chatMessageSent', handleMessageSent);
    };
  }, []);

  return (
    <Container maxHeight={maxHeight} data-testid="chat-channel">
      {tab === 'private' ? (
        focusChannel && <ChatUserInfo channelId={channelId} focusChannel={focusChannel} />
      ) : tab === 'group' ? (
        <PinnedArea channelId={channelId} pinnedMsg={'pinnedMsg'} />
      ) : (
        <CampaignInfo channelId={channelId} />
      )}
      <ChatLog className="chat-log" ref={chatLogRef}>
        <ChatLogContent>
          <div
            ref={intersectionTarget as RefObject<HTMLDivElement>}
            style={{ height: '10px', width: '100%', backgroundColor: 'transparent' }}
          />
          <EmptyPlace />
          {data?.slice(0).reverse().map(messageRenderer)}
        </ChatLogContent>
      </ChatLog>
      <ChatInput channelId={channelId} channelOwner={channelOwner} viewMode={viewMode} />
    </Container>
  );
};

export default TalkPlusChatRoom;

const Container = styled.div<{ maxHeight?: string }>`
  width: 100%;
  height: 100%;
  max-height: ${(props) => props.maxHeight || 'auto'};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatLog = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const ChatLogContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 26px 16px;
`;

const EmptyPlace = styled.div`
  width: 100%;
  height: 80px;
`;
