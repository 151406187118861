import StatusBadge from 'components/common/badge/StatusBadge';

import { ColorTypes } from 'styles/assets';

import { MessagingStatus } from 'types/bulkMessge';

interface Props {
  status: MessagingStatus;
}

const StatusLabel = ({ status }: Props) => {
  const type = MessagingStatus[status] as keyof typeof MessagingStatus;

  const theme: {
    [key: string]: {
      color: ColorTypes;
      text: string;
    };
  } = {
    PENDING: {
      color: 'mauve95',
      text: 'Pending',
    },
    SCHEDULED: {
      color: 'mauve95',
      text: 'Scheduled',
    },
    CANCELED: {
      color: 'grey71',
      text: 'Canceled',
    },
    SENT: {
      color: 'violet39',
      text: 'Sent',
    },
  };

  return (
    <StatusBadge width={180} text={theme[type].text} fontColor={theme[type].color} borderColor={theme[type].color} />
  );
};

export default StatusLabel;
