import { useOverlay } from '@nurihaus/use-overlay';
import { CTAButton } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { usePatchReportDetail } from 'hooks/account/queries';
import {
  useGetCommunityCommentDetail,
  useGetCommunityPostDetail,
  usePatchCommunityComment,
  usePatchCommunityPostDetail,
} from 'hooks/community/queries';

import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import HideContentsModal from 'components/pages/community/common/hide/HideContentsModal';
import ReactivateContentsModal from 'components/pages/community/common/hide/ReactivateContentsModal';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

interface Props {
  reportId: number;
  isActive: boolean;
  contentType: number;
  contentId: number;
}

const ReportDetailHeader = ({ reportId, isActive, contentType, contentId }: Props) => {
  const queryClient = useQueryClient();
  const hideOverlay = useOverlay();
  const reActiveOverlay = useOverlay();
  const dispatch = useDispatch();

  const { data: postData, isLoading: isPostDataLoading } =
    contentType === 342 ? useGetCommunityPostDetail({ post_id: Number(contentId) }) : { data: null, isLoading: false };

  const { data: commentData, isLoading: isCommentDataLoading } =
    contentType === 345 ? useGetCommunityCommentDetail({ comment_id: contentId }) : { data: null, isLoading: false };

  const is_display = contentType === 342 ? postData?.is_display : commentData?.is_display;

  const handlePatchSuccess = () => {
    queryClient.invalidateQueries(['reportDetail', reportId]);
    queryClient.invalidateQueries(['reportList']);

    if (contentType === 342) {
      queryClient.invalidateQueries(['postList']);
      queryClient.invalidateQueries(['channelDetail']);
      queryClient.invalidateQueries(['postDetail', contentId]);
    }

    if (contentType === 345) {
      queryClient.invalidateQueries(['commentDetail', contentId]);
    }

    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: 'Success !',
        severity: 'success',
      })
    );

    close();
  };

  const handleError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };
  const { mutate: updateReport, isLoading: isUpdateReportLoading } = usePatchReportDetail({
    handleSuccess: handlePatchSuccess,
    handleError,
  });

  const { mutate: updatePost, isLoading: isUpdatePostLoading } = usePatchCommunityPostDetail({
    handleSuccess: handlePatchSuccess,
    handleError,
  });

  const { mutate: patchComment, isLoading: isUpdateCommentLoading } = usePatchCommunityComment({
    handleError,
    handleSuccess: handlePatchSuccess,
  });
  const hidePost = ({ reason_for_display_off }: { reason_for_display_off: string }) => {
    if (!isUpdatePostLoading) {
      const data = { reason_for_display_off, is_display: false };
      updatePost({ post_id: contentId, data }, { onSuccess: handlePatchSuccess, onError: handleError });
    }
  };
  const reActivatePost = () => {
    if (!isUpdatePostLoading) {
      const data = { reason_for_display_off: '', is_display: true };
      updatePost({ post_id: contentId, data }, { onSuccess: handlePatchSuccess, onError: handleError });
    }
  };
  const openHidePostModal = () => {
    hideOverlay.open(({ close }) => (
      <HideContentsModal type="post" contents={postData?.title || ''} close={close} handleHideClick={hidePost} />
    ));
  };
  const openReactivatePostModal = () => {
    reActiveOverlay.open(({ close }) => (
      <ReactivateContentsModal
        type="Post"
        contents={postData?.title}
        reason={postData?.reason_for_display_off}
        close={close}
        handleReactivate={reActivatePost}
      />
    ));
  };

  const hideComment = (request: { reason_for_display_off: string }) => {
    if (!isUpdateCommentLoading) {
      const { reason_for_display_off } = request;
      patchComment({ queryParams: { comment_id: contentId }, data: { is_display: false, reason_for_display_off } });
    }
  };
  const handleReactivate = () => {
    if (!isUpdateCommentLoading) {
      patchComment({ queryParams: { comment_id: contentId }, data: { is_display: true, reason_for_display_off: '' } });
    }
  };
  const openHideCommentModal = () => {
    hideOverlay.open(({ close }) => {
      return (
        <HideContentsModal
          type="Comment"
          contents={commentData?.content || ''}
          handleHideClick={hideComment}
          close={close}
        />
      );
    });
  };
  const openReactivateCommentModal = () => {
    reActiveOverlay.open(({ close }) => (
      <ReactivateContentsModal
        type="Comment"
        contents={commentData?.content}
        reason={commentData?.reason_for_display_off}
        handleReactivate={handleReactivate}
        close={close}
      />
    ));
  };

  const hideReportedContentClick = () => {
    if (contentType === 342) {
      return is_display ? openHidePostModal() : openReactivatePostModal();
    }

    if (contentType === 345) {
      return is_display ? openHideCommentModal() : openReactivateCommentModal();
    }
  };

  const markAsHandledClick = () => {
    if (!isUpdateReportLoading) {
      updateReport({ queryParams: { report_id: reportId }, data: { is_active: !isActive } });
    }
  };

  const hideButtonText = is_display ? 'Hide' : 'Reactivate';
  const hideButtonStyle = is_display ? 'primary' : 'ghost';

  const markButtonText = isActive ? 'Mark as UnHandled' : 'Mark as Handled';
  const markButtonStyle = isActive ? 'ghost' : 'primary';

  if (isPostDataLoading || isCommentDataLoading) return <ComponentLoading size={20} />;

  return (
    <Container>
      <StyledText text="Report" fontSize="xl2" fontWeight="bold" />

      <ButtonWrapper>
        <CTAButton
          style={{ width: 'auto', padding: '15px 20px' }}
          text={`${hideButtonText} Reported Content`}
          onClick={hideReportedContentClick}
          variant={hideButtonStyle}
        />
        <CTAButton
          style={{ width: 'auto', padding: '15px 20px' }}
          text={markButtonText}
          onClick={markAsHandledClick}
          variant={markButtonStyle}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default ReportDetailHeader;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
