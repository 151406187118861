import CommunityReportDetailContainer from 'containers/community/report/CommunityReportDetailContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const CommunityReportDetail = () => {
  return (
    <DashboardLayout headerTitle="Community">
      <CommunityReportDetailContainer />
    </DashboardLayout>
  );
};

export default CommunityReportDetail;
