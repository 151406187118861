import { color, getUserRole } from '@NURIHAUS-Dev/core-business';
import { useOverlay } from '@nurihaus/use-overlay';
import { Avatar, CTAButton, OptionBadge, colors } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { addCommaToNumber } from 'utils/common/addCommaToNumber';
import { errToErrMsg } from 'utils/common/errToErrMsg';

import { useCountry } from 'hooks/common/queries';
import { useCommunityCommentReplies, usePatchCommunityComment } from 'hooks/community/queries';

import BadgeIcon from 'components/Icons/BadgeIcon';
import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import HideContentsModal from 'components/pages/community/common/hide/HideContentsModal';
import ReactivateContentsModal from 'components/pages/community/common/hide/ReactivateContentsModal';
import PostCommentReplyModal from 'components/pages/community/post/detail/comment/PostCommentReplyModal';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { CommunityCommentAPI } from 'types/community/remote';

interface Props {
  data: CommunityCommentAPI['Get']['Response']['results'][0];
  depth?: number;
  postID: number;
  page: number;
}

const PostComment = ({ data: currentCommentData, postID, page, depth = 1 }: Props) => {
  const dispatch = useDispatch();
  const replyOverlay = useOverlay();
  const hideOverlay = useOverlay();
  const reActiveOverlay = useOverlay();
  const queryClient = useQueryClient();

  const { id, author, content, like_count, is_display, reason_for_display_off } = currentCommentData;
  const { data: repliesData } = useCommunityCommentReplies({ comment_id: currentCommentData.id });
  const { filteredCountry } = useCountry({ id: currentCommentData.author.branch_id });
  const repliesFlatData = repliesData?.pages.flatMap((page) => page.results);

  const usernameText = `${filteredCountry?.emoji || ''} ${currentCommentData.author.nickname}`;

  const handleReplyButtonClick = () => {
    replyOverlay.open(({ close }) => {
      return (
        <PostCommentReplyModal close={close} postID={postID} parentID={id} content={content} commentListPage={page} />
      );
    });
  };

  const hideButtonText = is_display ? 'Hide' : 'Hidden';
  const hideButtonStyle = is_display ? 'primary' : 'ghost';

  const handlePatchSuccess = () => {
    queryClient.invalidateQueries(['channelDetail']);
    queryClient.invalidateQueries(['commentList', page, 20, postID]);
    queryClient.invalidateQueries(['postDetail', postID]);

    close();
  };
  const handlePatchError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };

  const { mutate: patchComment, isLoading } = usePatchCommunityComment({
    handleError: handlePatchError,
    handleSuccess: handlePatchSuccess,
  });

  const handleHideClick = (request: { reason_for_display_off: string }) => {
    if (!isLoading) {
      const { reason_for_display_off } = request;
      patchComment({ queryParams: { comment_id: id }, data: { is_display: false, reason_for_display_off } });
    }
  };
  const handleReactivate = () => {
    if (!isLoading) {
      patchComment({ queryParams: { comment_id: id }, data: { is_display: true, reason_for_display_off: '' } });
    }
  };

  const openHideCommentModal = () => {
    hideOverlay.open(({ close }) => {
      return <HideContentsModal type="Comment" contents={content} handleHideClick={handleHideClick} close={close} />;
    });
  };

  const openReactiveCommentModal = () => {
    reActiveOverlay.open(({ close }) => (
      <ReactivateContentsModal
        type="Comment"
        contents={content}
        reason={reason_for_display_off}
        handleReactivate={handleReactivate}
        close={close}
      />
    ));
  };

  const handleHideButtonClick = () => {
    if (is_display) openHideCommentModal();

    if (!is_display) openReactiveCommentModal();
  };

  return (
    <>
      <Container depth={depth}>
        <ContentSection>
          <AvatarWrapper>
            {depth > 1 && <Icon type="arrow_subdown_right" />}
            <StyledText text={String(id)} fontSize="sm" />
            <Avatar size="s" src={author.profile} badgeOption={<BadgeIcon userType={getUserRole(author as any)} />} />
            <StyledText text={usernameText} fontSize="sm" whiteSpace="nowrap" />
          </AvatarWrapper>
          <ContentWrapper>
            <StyledText text={content} wordBreak="break-word" />
          </ContentWrapper>
        </ContentSection>

        <ActionSection>
          <Icon type="heart" width={20} height={20} />
          <StyledText text={addCommaToNumber(like_count)} />
          {!is_display || depth >= 3 ? (
            <EmptyButton />
          ) : (
            <ReplyButton
              size="m"
              option={<Icon type="reply" width={20} height={20} fill="black" />}
              onClick={handleReplyButtonClick}
              tooltipText="Reply"
              tooltipStyle={{ top: '40px' }}
            />
          )}

          <CTAButton
            style={{ width: '90px', height: '40px' }}
            text={hideButtonText}
            size="m"
            onClick={handleHideButtonClick}
            variant={hideButtonStyle}
          />
        </ActionSection>
      </Container>

      <ReplyContainer>
        {repliesFlatData?.map((reply) => (
          <PostComment key={reply.id} postID={postID} data={reply} depth={depth + 1} page={page} />
        ))}
      </ReplyContainer>
    </>
  );
};

export default PostComment;

const Container = styled.div<{ depth: number }>`
  display: flex;
  width: 100%;
  min-height: 80px;
  padding: 20px;
  padding-left: ${({ depth }) => depth * 20}px;
  border-top: ${({ depth }) => (depth === 1 ? 1 : 0)}px solid ${color.greyd4};
  border-top: ${({ depth }) => (depth === 1 ? 1 : 0)}px solid ${color.greyd4};
`;

const ContentSection = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
  align-items: flex-start;
`;
const ActionSection = styled.div`
  display: flex;
  height: 100%;
  column-gap: 30px;
  align-items: center;
`;

const ReplyButton = styled(OptionBadge)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: ${colors.grayF4};

  :hover {
    background-color: ${colors.purpleF4};
    z-index: 2;
  }
`;
const EmptyButton = styled.div`
  width: 40px;
  height: 40px;
  background-color: transparent;
`;

const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
