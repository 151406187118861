var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Children, cloneElement, forwardRef, isValidElement, } from "react";
var Composer = forwardRef(function (_a, ref) {
    var children = _a.children, composerProps = __rest(_a, ["children"]);
    var childArray = Children.toArray(children);
    if (childArray.length > 1) {
        throw new Error("Composer can only have one child");
    }
    var isValidChild = isValidElement(childArray[0]);
    if (!isValidChild) {
        throw new Error("child should be React node!");
    }
    var child = childArray[0];
    var childRef = getElementRef(child);
    var mergedProps = mergeProps(composerProps, child.props);
    return cloneElement(child, __assign(__assign({}, mergedProps), { ref: ref ? composeRefs(ref, childRef) : childRef }));
});
Composer.displayName = "Composer";
function mergeProps(composerProps, childProps) {
    var overrideProps = __assign({}, childProps);
    var _loop_1 = function (propName) {
        var composerPropValue = composerProps[propName];
        var childPropValue = childProps[propName];
        var isHandler = /^on[A-Z]/.test(propName);
        if (isHandler) {
            if (composerPropValue && childPropValue) {
                overrideProps[propName] = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    childPropValue.apply(void 0, args);
                    composerPropValue.apply(void 0, args);
                };
            }
            else if (composerPropValue) {
                overrideProps[propName] = composerPropValue;
            }
        }
        else if (propName === "style") {
            overrideProps[propName] = __assign(__assign({}, composerPropValue), childPropValue);
        }
        else if (propName === "className") {
            overrideProps[propName] = [composerPropValue, childPropValue]
                .filter(Boolean)
                .join(" ");
        }
    };
    for (var propName in composerProps) {
        _loop_1(propName);
    }
    return __assign(__assign({}, composerProps), overrideProps);
}
function getElementRef(element) {
    var _a, _b;
    var childGetter = (_a = Object.getOwnPropertyDescriptor(element.props, "ref")) === null || _a === void 0 ? void 0 : _a.get;
    var mayWarnInChild = childGetter &&
        "isReactWarning" in childGetter &&
        childGetter.isReactWarning;
    if (mayWarnInChild) {
        return element.ref;
    }
    var getter = (_b = Object.getOwnPropertyDescriptor(element, "ref")) === null || _b === void 0 ? void 0 : _b.get;
    var mayWarn = getter && "isReactWarning" in getter && getter.isReactWarning;
    if (mayWarn) {
        return element.props.ref;
    }
    return (element.props.ref || element.ref);
}
var composeRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    return function (node) {
        refs.forEach(function (ref) {
            if (ref) {
                if (typeof ref === "function") {
                    ref(node);
                }
                else {
                    ref.current = node;
                }
            }
        });
    };
};
export default Composer;
