import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Client } from 'talkplus-sdk';

import { AppContext } from 'features/redux/context';
import { persistor, store } from 'features/redux/store';

import { ThemeProvider } from 'styles/theme/ThemeProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      useErrorBoundary: true,
    },
  },
});

const talkPlus = new Client({ appId: process.env.REACT_APP_TALKPLUS_APP_ID as string });

const initialState: { talkPlus: Client } = {
  talkPlus: talkPlus,
};

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppContext.Provider value={initialState}>
            <ThemeProvider>{children}</ThemeProvider>
          </AppContext.Provider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};
