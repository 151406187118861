import { useOverlay } from '@nurihaus/use-overlay';
import { CTAButton } from '@nurihaus/web-design-system';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import EditConfirmModal from 'components/pages/community/channel/edit/EditConfirmModal';

import { ChannelPatchInfo } from 'types/community/internal';

interface Props {
  channelInfo: ChannelPatchInfo | null;
}

const EditChannelHeader = ({ channelInfo }: Props) => {
  const navigate = useNavigate();
  const editOverlay = useOverlay();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleEditClick = () => {
    editOverlay.open(({ close }) => <EditConfirmModal channelInfo={channelInfo} close={close} />);
  };

  return (
    <Container>
      <CTAButton style={{ width: '172px' }} variant="ghost" text="Cancel" onClick={handleCancelClick} />
      <CTAButton style={{ width: '172px' }} variant="primary" text="Edit" onClick={handleEditClick} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

export default EditChannelHeader;
