import { ChangeEvent, KeyboardEvent, useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { Grid, Pagination } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useCommunityChannelDetail, useCommunityChannelPostList } from 'hooks/community/queries';

import SectionLine from 'components/common/SectionLine';
import PageLoading from 'components/common/loading/PageLoading';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';
import ChannelDetailHeader from 'components/pages/community/channel/detail/ChannelDetailHeader';
import PostListSearchInput from 'components/pages/community/channel/detail/PostListSearchInput';
import PostListTable from 'components/pages/community/channel/detail/PostListTable';

const CommunityChannelDetailContainer = () => {
  const { id } = useParams();
  const page_size = 50;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  if (!id) return <></>;

  const { data: channelData, isLoading: channelIsLoading } = useCommunityChannelDetail({
    channel_id: id ? Number(id) : undefined,
  });
  const {
    data: postListData,
    isLoading: postIsLoading,
    refetch,
  } = useCommunityChannelPostList({
    channel_id: Number(id),
    page,
    page_size,
    search,
  });

  const paginationHandler = (page: number) => {
    setPage(page);
  };
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPage(1);
      refetch();
    }
  };

  if (channelIsLoading || !channelData || postIsLoading || !postListData) return <PageLoading isLoading />;

  const count = postListData?.count || 0;
  const postListFlatData = postListData?.results || [];

  return (
    <>
      <StyledText text="Channel" fontWeight="bold" fontSize="xl2" />
      <Gap side={30} />
      <Grid>
        <Grid maxWidth={1212}>
          <Block padding="0" scrollable={false}>
            <ChannelDetailHeader data={channelData} />
          </Block>
        </Grid>
        <Gap side={10} />
        <Grid maxWidth={1212}>
          <Block padding="0" scrollable={false}>
            <PostListSearchInput
              placeholder="Search"
              value={search}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
            />
            <PostListTable data={postListFlatData} page={page} />
            <SectionLine marginBottom="0" marginTop="0" backgroundColor={color.greyd4} height="1px" />
            <Pagination
              count={Math.ceil(count / page_size) || 1}
              page={page}
              boundaryCount={3}
              onChange={(_e, value) => paginationHandler(value)}
              style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '20px' }}
            />
          </Block>
        </Grid>
      </Grid>
    </>
  );
};

export default CommunityChannelDetailContainer;
