import { getUserRole } from '@NURIHAUS-Dev/core-business';
import { OverlayProvider } from '@nurihaus/use-overlay';
import * as Sentry from '@sentry/react';
import {
  AdminOrderHistoryPage,
  AnalyticsPage,
  BannerDetailPage,
  BannerListPage,
  CampaignCreatePage,
  CampaignDetailPage,
  CampaignEditPage,
  CampaignGuidePage,
  CampaignListPage,
  ChatPage,
  DashboardMainPage,
  EditBannerPage,
  EditUserPage,
  LoginPage,
  MailAuthPage,
  MyJellyPage,
  NewBannerPage,
  NotificationsCreatePage,
  NotificationsDetailPage,
  NotificationsEditPage,
  NotificationsListPage,
  PackageSettingPage,
  PayoutRequestDetailPage,
  PayoutRequestListPage,
  SettingsMainPage,
  UserChargeHistoryPage,
  UserDetailPage,
  UserSpendHistoryPage,
  UsersMainPage,
} from 'pages';
import ChatStaffPage from 'pages/chat/ChatStaffPage';
import ChatBulkMessageDetailPage from 'pages/chat/bulkMessage/ChatBulkMessageDetailPage';
import ChatBulkMessageEditPage from 'pages/chat/bulkMessage/ChatBulkMessageEditPage';
import ChatBulkMessageListPage from 'pages/chat/bulkMessage/ChatBulkMessageListPage';
import CommunityChannelDetailPage from 'pages/community/channel/CommunityChannelDetailPage';
import CommunityChannelEditPage from 'pages/community/channel/CommunityChannelEditPage';
import CommunityChannelListPage from 'pages/community/channel/CommunityChannelListPage';
import CommunityPostCreate from 'pages/community/post/CommunityPostCreate';
import CommunityPostDetail from 'pages/community/post/CommunityPostDetail';
import CommunityReportDetail from 'pages/community/report/CommunityReportDetail';
import CommunityReportListPage from 'pages/community/report/CommunityReportList';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'router/ProtectedRoute';

import SafeBoundary from 'components/common/errorBoundary/SafeBoundary';

import { selectAccount } from 'features/redux/selectors/accounts';

export default function Router() {
  const account = useSelector(selectAccount);
  const { userInfo, isLogin } = account;
  const { user } = userInfo;
  const userType = getUserRole(user);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <BrowserRouter>
      <OverlayProvider>
        <SafeBoundary fallbackStyle={{ height: '100vh', width: '100vw' }} isAtRoot>
          <SentryRoutes>
            {!isLogin ? (
              <>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/account/mail/:key" element={<MailAuthPage />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
              </>
            ) : (
              <>
                <Route element={<ProtectedRoute userType={userType} page="dashboard" />}>
                  <Route path="/" element={<DashboardMainPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="chat" />}>
                  <Route path="/chat" element={<ChatPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="bulkChat" />}>
                  <Route path="/chat/bulk-message" element={<ChatBulkMessageListPage />} />
                  <Route path="/chat/bulk-message/:id" element={<ChatBulkMessageDetailPage />} />
                  <Route path="/chat/bulk-message/:id/edit" element={<ChatBulkMessageEditPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="staffChat" />}>
                  <Route path="/chat/staff" element={<ChatStaffPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="banner" />}>
                  <Route path="/banner" element={<BannerListPage />} />
                  <Route path="/banner/:id" element={<BannerDetailPage />} />
                  <Route path="/banner/:id/edit" element={<EditBannerPage />} />
                  <Route path="/banner/new" element={<NewBannerPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="benefitList" />}>
                  <Route path="/list/:campaign" element={<CampaignListPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="benefitDetail" />}>
                  <Route path="/detail/:campaign/:id" element={<CampaignDetailPage />} />
                  <Route path="/detail/:campaign/:type/:id" element={<CampaignDetailPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="benefitCreateEdit" />}>
                  <Route path="/add/:campaign/" element={<CampaignCreatePage />} />
                  <Route path="/add/:campaign/:type" element={<CampaignCreatePage />} />
                  <Route path="/edit/:campaign/:id" element={<CampaignEditPage />} />
                  <Route path="/edit/:campaign/:type/:id" element={<CampaignEditPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="questList" />}>
                  <Route path="/list/:campaign" element={<CampaignListPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="questDetail" />}>
                  <Route path="/detail/:campaign/:id" element={<CampaignDetailPage />} />
                  <Route path="/detail/:campaign/:type/:id" element={<CampaignDetailPage />} />
                </Route>
                <Route element={<ProtectedRoute userType={userType} page="questCreateEdit" />}>
                  <Route path="/add/:campaign/" element={<CampaignCreatePage />} />
                  <Route path="/add/:campaign/:type" element={<CampaignCreatePage />} />
                  <Route path="/edit/:campaign/:id" element={<CampaignEditPage />} />
                  <Route path="/edit/:campaign/:type/:id" element={<CampaignEditPage />} />
                </Route>

                <Route path="/guide/campaign/:campaign" element={<CampaignGuidePage />} />

                <Route element={<ProtectedRoute userType={userType} page="users" />}>
                  <Route path="/users" element={<UsersMainPage />} />
                  <Route path="/users/:id" element={<UserDetailPage />} />
                  <Route path="/users/:id/charge-history" element={<UserChargeHistoryPage />} />
                  <Route path="/users/:id/spend-history" element={<UserSpendHistoryPage />} />
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="usersCreateEdit" />}>
                  <Route path="/users/:id/edit" element={<EditUserPage />} />
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="myJellyCredit" />}>
                  <Route path="/point/:pointType" element={<MyJellyPage />} />
                  <Route path="/point/:pointType/admin-order-history" element={<AdminOrderHistoryPage />} />
                  <Route path="/point/:pointType/package-setting/:id" element={<PackageSettingPage />} />
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="payout" />}>
                  <Route path="/payout" element={<PayoutRequestListPage />} />
                  <Route path="/payout/detail/:id" element={<PayoutRequestDetailPage />} />
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="analytics" />}>
                  <Route path="/analytics" element={<AnalyticsPage />} />
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="notifications" />}>
                  <Route path="/notifications" element={<NotificationsListPage />} />
                  <Route path="/notifications/detail/:id" element={<NotificationsDetailPage />} />
                  <Route path="/notifications/create" element={<NotificationsCreatePage />} />
                  <Route path="/notifications/edit/:id" element={<NotificationsEditPage />} />
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="community" />}>
                  <Route path="/community/manage/channel">
                    <Route path="list" element={<CommunityChannelListPage />} />
                    <Route path="detail/:id" element={<CommunityChannelDetailPage />} />
                    <Route path="edit/:id" element={<CommunityChannelEditPage />} />
                    <Route path="post">
                      <Route path=":id" element={<CommunityPostDetail />} />
                      <Route path="create/:channelId" element={<CommunityPostCreate />} />
                    </Route>
                  </Route>
                  <Route path="/community/manage/report">
                    <Route path="list" element={<CommunityReportListPage />} />
                    <Route path="detail/:id" element={<CommunityReportDetail />} />
                  </Route>
                </Route>

                <Route element={<ProtectedRoute userType={userType} page="setting" />}>
                  <Route path="/settings" element={<SettingsMainPage />} />
                </Route>

                <Route path="*" element={<Navigate replace to="/" />} />
              </>
            )}
          </SentryRoutes>
        </SafeBoundary>
      </OverlayProvider>
    </BrowserRouter>
  );
}
