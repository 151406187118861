import { RefObject, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useDebounce, useIntersection } from '@nurihaus/react';
import { ChannelCategoryEnum } from 'constants/chatConstants';
import styled, { keyframes } from 'styled-components';
import { Channel } from 'talkplus-sdk';

import { useChat } from 'hooks/chat/queries';
import { useQueryParams } from 'hooks/common/useQueryParams';
import { useRouter } from 'hooks/common/useRouter';

import Checkbox from 'components/common/input/Checkbox';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import { FullBorder } from 'components/layout/common/InfoCard';
import TalkPlusSingleList from 'components/pages/chat/list/TalkPlusSingleList';

import { color } from 'styles/assets';

import { Campaign } from 'types/campaign/internal';

interface Props {
  focusedChannel: string | null;
  focusChannel: (channelId: string | null) => void;
  selectedCampaign: {
    campaignType: 'quest' | 'benefit';
    campaignData: Campaign;
  } | null;
  mode: 'edit' | 'view';
  toggleMode: () => void;
}

const ChatStaffChannelList = ({ focusChannel, focusedChannel, selectedCampaign, mode, toggleMode }: Props) => {
  const [unreadCheck, setUnreadCheck] = useState(false);

  const { useGetChannels } = useChat();
  const {
    data: channels,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useGetChannels({
    hasUnread: unreadCheck,
    category:
      ChannelCategoryEnum[selectedCampaign?.campaignType.toUpperCase() as unknown as keyof typeof ChannelCategoryEnum],
    subcategory: String(selectedCampaign?.campaignData.id),
  });

  const toggleUnreadCheck = () => setUnreadCheck((prev) => !prev);

  const { intersectionTarget } = useIntersection(fetchNextPage, {}, hasNextPage ?? false);

  const handleClickChatSingList = (channel: Channel | undefined) => {
    focusChannel(channel?.id ?? null);
  };

  return (
    <Grid item sx={{ flex: '0 0 auto', width: { xs: '404px', md: '404px', lg: '404px' } }}>
      <Block height="830px" padding="0">
        <ToolBar unreadCheck={unreadCheck} toggleUnreadCheck={toggleUnreadCheck} mode={mode} toggleMode={toggleMode} />
        <FullBorder margin="0px" />
        <ChannelListSection id="chat-channel-list">
          {isLoading ? (
            Array.from({ length: 5 }, (_, index) => index + 1).map((index) => {
              return <SingleListSkeleton key={index} />;
            })
          ) : channels?.length === 0 ? (
            <EmptyWrapper>
              <StyledText text="It is empty now." fontSize="sm" fontColor="greyd4" />
            </EmptyWrapper>
          ) : (
            channels?.map((channel) => {
              return (
                <div key={channel?.id} onClick={() => focusChannel(channel?.id ?? null)}>
                  <TalkPlusSingleList
                    id={channel?.id as string}
                    handleClickChatSingList={() => handleClickChatSingList(channel)}
                    isFocused={channel?.id === focusedChannel}
                    focussed={focusedChannel}
                    channel={channel as Channel}
                    viewMode={mode === 'view'}
                  />
                </div>
              );
            })
          )}
          <IntersectionTarget ref={intersectionTarget as RefObject<HTMLDivElement>} />
        </ChannelListSection>
      </Block>
    </Grid>
  );
};

const ToolBar = ({
  unreadCheck,
  toggleUnreadCheck,
  mode,
  toggleMode,
}: {
  unreadCheck: boolean;
  toggleUnreadCheck: () => void;
  mode: 'edit' | 'view';
  toggleMode: () => void;
}) => {
  const route = useRouter();
  const query = useQueryParams();
  const initialSearchInput = query.get('search') || '';
  const [searchInput, setSearchInput] = useState(initialSearchInput);
  const debouncedSearchInput = useDebounce(searchInput, 500);

  useEffect(() => {
    route.replaceQS({ search: searchInput });
  }, [debouncedSearchInput]);

  return (
    <ToolSection>
      <SelectRow>
        <Checkbox
          id="unread-check"
          checked={unreadCheck}
          onChange={toggleUnreadCheck}
          label="Unread"
          fontSize="sm"
          fontWeight="medium"
        />
        <Checkbox
          id="edit-check"
          checked={mode === 'edit'}
          onChange={toggleMode}
          label="Chat Mode"
          fontSize="sm"
          fontWeight="medium"
        />
      </SelectRow>
      <SearchInput
        className="search-channel-input"
        onChange={(e) => setSearchInput(e.target.value)}
        value={searchInput}
        placeholder="Search by user's nickname"
      />
    </ToolSection>
  );
};

const SingleListSkeleton = () => {
  return (
    <SkeletonContainer>
      <CircleSkeleton />
      <div>
        <TextSkeleton width="100px" />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          <TextSkeleton width="50px" />
          <SmallRectSkeleton />
        </div>
      </div>
      <RightAvatarSkeleton />
    </SkeletonContainer>
  );
};

export default ChatStaffChannelList;

const ToolSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
`;
const SearchInput = styled.input`
  width: 100%;
  height: 50px;
  padding: 14px;
  background-color: ${color.greyfa};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  ::placeholder {
    color: ${color.greya1};
  }
`;

const ChannelListSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: calc(100% - 177px);
`;

const EmptyWrapper = styled.div`
  width: 100%;
  min-height: 655px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

const IntersectionTarget = styled.div`
  background-color: transparent;
  height: 10px;
  margin-top: -10px;
`;
// 스켈레톤 애니메이션 설정
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// 애니메이션 적용한 컨테이너
const SkeletonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 116px;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards; // 페이드인 애니메이션 적용
`;

// 원형 스켈레톤 (아바타용)
const CircleSkeleton = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 50%;
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite, ${fadeIn} 0.5s forwards;
  margin-right: 16px;
`;

// 텍스트 스켈레톤
const TextSkeleton = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  height: 20px;
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite, ${fadeIn} 0.5s forwards;
  border-radius: 4px;
`;

// 작은 직사각형 스켈레톤 (국기용)
const SmallRectSkeleton = styled.div`
  width: 20px;
  height: 15px;
  background: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: ${shimmer} 2s infinite, ${fadeIn} 0.5s forwards;
  border-radius: 2px;
  margin-left: 8px;
`;

// 오른쪽 아바타 스켈레톤
const RightAvatarSkeleton = styled(CircleSkeleton)`
  margin-left: auto;
  margin-right: 0;
  border-radius: 5px;
`;
