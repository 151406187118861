// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  position: relative;
  & div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  & img {
    object-fit: cover;
  }

  border-radius: 50%;

  &:where(.xl) {
    width: 124px;
    height: 124px;
  }

  &:where(.l) {
    width: 74px;
    height: 74px;
  }

  &:where(.m) {
    width: 58px;
    height: 58px;
  }

  &:where(.s) {
    width: 40px;
    height: 40px;
  }

  .avatar-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e0e0e0;
    border-radius: 50%;
    width: 100%;
    height: 100%;

    .skeleton-animation {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.4) 50%,
        rgba(255, 255, 255, 0) 100%
      );
      animation: shimmer 1.5s infinite;
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
`, "",{"version":3,"sources":["webpack://./components/avatar/avatar-style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB;IACE,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA,kBAAkB;;EAElB;IACE,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;;IAEZ;MACE,WAAW;MACX,YAAY;MACZ;;;;;OAKC;MACD,gCAAgC;IAClC;EACF;AACF;;AAEA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".avatar {\n  position: relative;\n  & div {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    overflow: hidden;\n  }\n\n  & img {\n    object-fit: cover;\n  }\n\n  border-radius: 50%;\n\n  &:where(.xl) {\n    width: 124px;\n    height: 124px;\n  }\n\n  &:where(.l) {\n    width: 74px;\n    height: 74px;\n  }\n\n  &:where(.m) {\n    width: 58px;\n    height: 58px;\n  }\n\n  &:where(.s) {\n    width: 40px;\n    height: 40px;\n  }\n\n  .avatar-skeleton {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: #e0e0e0;\n    border-radius: 50%;\n    width: 100%;\n    height: 100%;\n\n    .skeleton-animation {\n      width: 100%;\n      height: 100%;\n      background: linear-gradient(\n        90deg,\n        rgba(255, 255, 255, 0) 0%,\n        rgba(255, 255, 255, 0.4) 50%,\n        rgba(255, 255, 255, 0) 100%\n      );\n      animation: shimmer 1.5s infinite;\n    }\n  }\n}\n\n@keyframes shimmer {\n  0% {\n    transform: translateX(-100%);\n  }\n  100% {\n    transform: translateX(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
