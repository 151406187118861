// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nuri-theme {
  --color-primary-black: #000000;
  --color-primary-white: #ffffff;
  --color-primary-gray-fa: #fafafa;
  --color-primary-gray-f4: #f4f4f5;
  --color-primary-gray-e4: #e4e4e7;
  --color-primary-gray-d4: #d4d4d8;
  --color-primary-gray-a1: #a1a1aa;
  --color-primary-gray-71: #71717a;
  --color-primary-gray-52: #52525b;
  --color-primary-gray-3f: #3f3f46;
  --color-primary-gray-27: #27272a;
  --color-primary-gray-18: #18181b;

  --color-point-purple-39: #391d5d;
  --color-point-purple-4c: #4c277c;
  --color-point-purple-dd: #ddd1f0;
  --color-point-purple-e9: #e9e0f5;
  --color-point-purple-f4: #f4effa;
  --color-point-purple-41: #4c1d88;
  --color-point-purple-95: #957eb4;

  --color-accent-red-df: #df535c;
  --color-accent-red-c8: #c8424b;
  --color-accent-magenta-b0: #b02e4c;
  --color-accent-green-28: #289a3a;

  --color-etc-skeleton-e4: #e4e4e7;
  --color-etc-skeleton-f7: #f7f7f8;
  --color-etc-skeleton-ff: #ffffff;

  --color-etc-hover-ee: #eeeeee;
}
`, "",{"version":3,"sources":["webpack://./styles/token/color.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,8BAA8B;EAC9B,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;;EAEhC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;;EAEhC,8BAA8B;EAC9B,8BAA8B;EAC9B,kCAAkC;EAClC,gCAAgC;;EAEhC,gCAAgC;EAChC,gCAAgC;EAChC,gCAAgC;;EAEhC,6BAA6B;AAC/B","sourcesContent":[".nuri-theme {\n  --color-primary-black: #000000;\n  --color-primary-white: #ffffff;\n  --color-primary-gray-fa: #fafafa;\n  --color-primary-gray-f4: #f4f4f5;\n  --color-primary-gray-e4: #e4e4e7;\n  --color-primary-gray-d4: #d4d4d8;\n  --color-primary-gray-a1: #a1a1aa;\n  --color-primary-gray-71: #71717a;\n  --color-primary-gray-52: #52525b;\n  --color-primary-gray-3f: #3f3f46;\n  --color-primary-gray-27: #27272a;\n  --color-primary-gray-18: #18181b;\n\n  --color-point-purple-39: #391d5d;\n  --color-point-purple-4c: #4c277c;\n  --color-point-purple-dd: #ddd1f0;\n  --color-point-purple-e9: #e9e0f5;\n  --color-point-purple-f4: #f4effa;\n  --color-point-purple-41: #4c1d88;\n  --color-point-purple-95: #957eb4;\n\n  --color-accent-red-df: #df535c;\n  --color-accent-red-c8: #c8424b;\n  --color-accent-magenta-b0: #b02e4c;\n  --color-accent-green-28: #289a3a;\n\n  --color-etc-skeleton-e4: #e4e4e7;\n  --color-etc-skeleton-f7: #f7f7f8;\n  --color-etc-skeleton-ff: #ffffff;\n\n  --color-etc-hover-ee: #eeeeee;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
