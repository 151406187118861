import { DefaultTheme } from 'styled-components';

export const color = {
  greyf9: '#F9FAFB',
  greyfa: '#FAFAFA',
  greyf4: '#F4F4F5',
  greye4: '#E4E4E7',
  greyd4: '#D4D4D8',
  greya1: '#A1A1AA',
  grey71: '#71717A',
  grey52: '#52525B',
  grey3f: '#3F3F46',
  grey27: '#27272A',
  grey54: '#545454',
  grey18: '#18181B',
  greyee: '#EEEEEE',
  reddf: '#DF535C',
  green: '#289A3A',
  violet39: '#391D5D',
  violet4c: '#4C277C',
  violetdd: '#DDD1F0',
  violete9: '#E9E0F5',
  violetf4: '#F4EFFA',
  mauve95: '#957EB4',
  magentaB0: '#B02E4C',
  white: '#FFFFFF',
  black: '#000000',
  yellowff: '#FFB800',
  transparent: 'transparent',
};

export const fontSize: {
  [key: string]: {
    size: string;
    height: string;
  };
} = {
  xxs: { size: '10px', height: '14px' },
  xs: { size: '12px', height: '16px' },
  sm: { size: '14px', height: '18px' },
  base: { size: '16px', height: '22px' },
  lg: { size: '18px', height: '26px' },
  xl: { size: '20px', height: '28px' },
  xl2: { size: '24px', height: '32px' },
  xl3: { size: '30px', height: '36px' },
  xl4: { size: '36px', height: 'normal' },
};

export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

export type FontWeightTypes = keyof typeof fontWeight;
export type FontSizeTypes = keyof typeof fontSize;
export type ColorTypes = keyof typeof color;

const theme: DefaultTheme = {
  fontWeight,
  fontSize,
  color,
};

export default theme;
