import { getContentTypeInfo, getReportTypeInfo } from '@NURIHAUS-Dev/core-business';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { formatDateAndTime } from 'utils/common/dateFormat';

import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';

import { ReportListAPI } from 'types/account/remote';

interface Props {
  data: ReportListAPI['Get']['Response']['results'][0];
}

const ReportTableItem = ({ data }: Props) => {
  const navigate = useNavigate();
  const handledStatusText = data.is_active ? 'Y' : 'N';
  const contesTypeText = getContentTypeInfo(data.content_type_id)?.title;
  const reportTypeText = getReportTypeInfo(data.report_type)?.title;

  const handleCellClick = () => {
    navigate(`/community/manage/report/detail/${data.id}`);
  };

  return (
    <Container>
      <Table.Cell minWidth="62px" flex={0.78}>
        <TextButton onClick={handleCellClick}>
          <StyledText text={String(data.id)} fontSize="sm" textDecoration="underline" />
        </TextButton>
      </Table.Cell>
      <Table.Cell minWidth="165px" flex={2.1}>
        <StyledText text={String(reportTypeText)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell minWidth="79px" flex={1}>
        <StyledText text={String(contesTypeText)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell minWidth="79px" flex={1}>
        <StyledText text={String(data.object_id)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell minWidth="79px" flex={1}>
        <StyledText text={String(data.user?.nickname || '')} fontSize="sm" />
      </Table.Cell>
      <Table.Cell minWidth="134px" flex={1.7}>
        <StyledText text={formatDateAndTime(data.created)} fontSize="sm" />
      </Table.Cell>
      <Table.Cell minWidth="56px" flex={0.71}>
        <StyledText text={handledStatusText} fontSize="sm" />
      </Table.Cell>
    </Container>
  );
};

export default ReportTableItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
`;

const TextButton = styled.button``;
