import { color, getPostStatusColorByLabel, getPostStatusLabel } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { useCommunityChannelDetail } from 'hooks/community/queries';

import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

interface Props {
  channelId: number;
}

const PostChannelInfo = ({ channelId }: Props) => {
  const { data, isLoading } = useCommunityChannelDetail({
    channel_id: channelId,
  });

  if (isLoading || !data) return <ComponentLoading size={20} justifyContent="center" />;

  const statusText = getPostStatusLabel(data?.is_display || false);
  const statusColor = getPostStatusColorByLabel(statusText);

  return (
    <Container>
      <KeyValueWrapper style={{ width: '172px' }}>
        <KeyBadge text="Status" />
        <StatusBadge statusColor={color[statusColor]}>
          <StyledText text={statusText} fontColor={statusColor} fontSize="sm" fontWeight="semiBold" />
        </StatusBadge>
      </KeyValueWrapper>
      <Gap side={40} />
      <KeyValueWrapper style={{ width: '220px' }}>
        <KeyBadge text="Channel ID" />
        <StyledText text={String(data.id)} fontSize="sm" width="100px" numberOfLines={1} wordBreak="break-word" />
      </KeyValueWrapper>
      <Gap side={40} />
      <KeyValueWrapper>
        <KeyBadge text="Channel Name" />
        <StyledText text={String(data.name)} width="100%" fontSize="sm" numberOfLines={1} wordBreak="break-word" />
      </KeyValueWrapper>
    </Container>
  );
};

export default PostChannelInfo;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const KeyValueWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const StatusBadge = styled.div<{ statusColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ statusColor }) => statusColor};
  width: 80px;
  height: 36px;
  border-radius: 40px;
`;
