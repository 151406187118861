import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

interface Props {
  text: string;
}

const KeyBadge = ({ text }: Props) => {
  return (
    <Container>
      <StyledText text={text} fontColor="grey52" fontSize="sm" numberOfLines={1} />
    </Container>
  );
};

export default KeyBadge;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: ${color.greyf4};
  border-radius: 50px;
  min-width: fit-content;
  white-space: nowrap;
`;
