import { ChangeEvent, useRef } from 'react';

import styled from 'styled-components';

import { uploadToPresigned } from 'utils/common/file/uploadToPresigned';

import Icon from 'components/Icons/Icon';

import { ImageWithLoading } from 'types/community/internal';

interface Props {
  images: ImageWithLoading[];
  setImages: (images: ImageWithLoading[]) => void;
}

const ImageUploadInput = ({ images, setImages }: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSize = 5 * 1024 * 1024; // 5MB
    const existingCount = images.length;

    const validFiles = Array.from(files).filter((file) => validTypes.includes(file.type) && file.size <= maxSize);
    if (validFiles.length === 0) return;

    const tempImages: ImageWithLoading[] = validFiles.map((file, index) => {
      const previewUrl = URL.createObjectURL(file);

      return {
        order: existingCount + index + 1,
        file_key: '',
        file_url: '',
        previewUrl,
        isLoading: true,
        isError: false,
      };
    });

    setImages([...images, ...tempImages]);

    // ✅ Step 2: Presigned URL을 통한 업로드 진행
    const updatedImages = [...tempImages];

    await Promise.all(
      validFiles.map(async (file, index) => {
        try {
          const { file_url, object_key } = await uploadToPresigned(file, `community/post/images/`);

          updatedImages[index] = {
            ...updatedImages[index],
            file_key: object_key,
            file_url,
            isLoading: false,
          };
        } catch (error) {
          console.error('Image upload failed:', error);
          updatedImages[index] = {
            ...updatedImages[index],
            isLoading: false,
            isError: true,
          };
        }
      })
    );

    setImages([...images, ...updatedImages]);
  };

  return (
    <ImageUploadWrapper>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        multiple
      />
      <IconButton onClick={handleImageClick}>
        <Icon type="picture" width={24} height={24} fill="grey71" />
      </IconButton>
    </ImageUploadWrapper>
  );
};

export default ImageUploadInput;

const ImageUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;
