import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { useCommunityChannelDetail } from 'hooks/community/queries';

import CommunityPostCreateContainer from 'containers/community/post/CommunityPostCreateContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import CreatePostHeader from 'components/pages/community/post/create/CreatePostHeader';

import { PostCreateInfo } from 'types/community/internal';

const CommunityPostCreate = () => {
  const { channelId } = useParams();

  const { data: channelData, isLoading: channelIsLoading } = useCommunityChannelDetail({
    channel_id: channelId ? Number(channelId) : undefined,
  });
  const [postInfo, setPostInfo] = useState<PostCreateInfo>({
    title: '',
    content: '',
    channel_id: channelId,
    video_key: '',
    video_url: '',
    hashtags: [],
    images: [],
  });

  return (
    <DashboardLayout headerTitle="Community" headerRight={<CreatePostHeader postInfo={postInfo} />}>
      {!channelIsLoading && channelData && (
        <CommunityPostCreateContainer channelData={channelData} postInfo={postInfo} setPostInfo={setPostInfo} />
      )}
    </DashboardLayout>
  );
};

export default CommunityPostCreate;
