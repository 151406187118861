import { Campaign as LibCampaign, getCampaignStatus } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { getImageList } from 'utils/campaign/getImageList';

import SectionLine from 'components/common/SectionLine';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import DetailsSection from 'components/pages/campaign/detail/modals/viewCardModal/DetailsSection';
import ImageSlider from 'components/pages/campaign/detail/modals/viewCardModal/ImageSlider';
import SummarySection from 'components/pages/campaign/detail/modals/viewCardModal/SummarySection';

import { color } from 'styles/assets';

import { Campaign, CampaignRouteParams } from 'types/campaign/internal';

interface Props {
  detailData: Campaign;
  opened: boolean;
  close: () => void;
  params: CampaignRouteParams;
}

const ViewCardModal = ({ opened, close, detailData, params }: Props) => {
  const campaignStatus = getCampaignStatus(detailData as LibCampaign);
  const { campaign } = params;
  const images = getImageList(detailData);

  return (
    <ModalForm visible={opened} backgroundOnPress={close} padding="0px">
      <ModalWrapper>
        <ImageWrapper>
          {campaignStatus !== 'Active' && (
            <CloseImageContainer>
              <StyledText text="CLOSED" fontColor="white" fontSize="xl2" fontWeight="bold" />
            </CloseImageContainer>
          )}
          <ImageSlider images={images} />
        </ImageWrapper>
        <SummarySection detailData={detailData} campaign={campaign} />
        <SectionLine height="1px" backgroundColor={color.greyd4} marginBottom="0px" marginTop="0px" />
        <DetailsSection detailData={detailData} params={params} />
      </ModalWrapper>
    </ModalForm>
  );
};

export default ViewCardModal;

const ModalWrapper = styled.div`
  display: flex;
  min-width: 340px;
  max-width: 375px;
  height: 100%;
  max-height: 90vh;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 375px;
`;

const CloseImageContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
`;
