import styled from 'styled-components';

import PostComment from 'components/pages/community/post/detail/comment/PostComment';

import { CommunityCommentAPI } from 'types/community/remote';

interface Props {
  data: CommunityCommentAPI['Get']['Response']['results'];
  postID: number;
  page: number;
}

const PostCommentList = ({ data, postID, page }: Props) => {
  return (
    <Container>
      {data.map((comment, index) => {
        return <PostComment key={index} postID={postID} data={comment} page={page} />;
      })}
    </Container>
  );
};

export default PostCommentList;

const Container = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;
