import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import PostListTableRow from 'components/pages/community/channel/detail/PostListTableRow';

import { CommunityChannelPostListAPI } from 'types/community/remote';

interface Props {
  data: CommunityChannelPostListAPI['Get']['Response']['results'];
  page: number;
}

const PostListTable = ({ data, page }: Props) => {
  return (
    <Container>
      <TableContainer>
        <Table gap={0}>
          <Table.Header borderBottom={{ style: 'solid', color: 'greyd4', width: 1 }}>
            <Table.Head flex={0.3} minWidth="57px">
              <StyledText text="" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={0.7} minWidth="106px">
              <StyledText text="Post Number" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={1.6} minWidth="220px">
              <StyledText text="Title" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={1.2} minWidth="170px">
              <StyledText text="Author" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={0.6} minWidth="90px">
              <StyledText text="Comments" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={0.4} minWidth="70px">
              <StyledText text="View" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={0.7} minWidth="90px">
              <StyledText text="Likes" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={1.7} minWidth="207px">
              <StyledText text="Created Date" fontSize="sm" fontColor="grey71" />
            </Table.Head>
            <Table.Head flex={0.7} minWidth="110px">
              <StyledText text="Hide" fontSize="sm" fontColor="grey71" />
            </Table.Head>
          </Table.Header>
          <Table.Body style={{ height: 'auto' }} borderBottom={{ width: 1, style: 'solid', color: 'greye4' }}>
            {data.map((data, index) => {
              return <PostListTableRow index={(page - 1) * 50 + index} data={data} key={index} />;
            })}
          </Table.Body>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default PostListTable;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  height: 100%;
  max-height: 700px;
  border-radius: 6px;
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;
