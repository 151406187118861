import { requestApi } from 'utils/common/axios-utils';

import {
  CommunityChannelDetailAPI,
  CommunityChannelListAPI,
  CommunityChannelPostListAPI,
  CommunityCommentAPI,
  CommunityCommentDetailAPI,
  CommunityCommentRepliesAPI,
  CommunityPostCreateAPI,
  CommunityPostDetailAPI,
} from 'types/community/remote';

export const getCommunityChannelListAPI = (queryParams: CommunityChannelListAPI['Get']['QueryParams']) => {
  const { page, page_size } = queryParams;

  return requestApi({
    method: 'get',
    url: '/ops/community/channel/list/',
    query: { page, page_size },
  });
};

export const getCommunityChannelDetailAPI = (queryParams: CommunityChannelDetailAPI['Get']['QueryParams']) => {
  const { channel_id } = queryParams;

  return requestApi({
    method: 'get',
    url: `/community/channel/${channel_id}/`,
  });
};
export const patchCommunityChannelDetailAPI = (
  queryParams: CommunityChannelDetailAPI['Patch']['QueryParams'],
  data: CommunityChannelDetailAPI['Patch']['RequestBody']
) => {
  const { channel_id } = queryParams;

  return requestApi({
    method: 'patch',
    url: `ops/community/channel/${channel_id}/`,
    data,
  });
};

export const getCommunityChannelPostListAPI = (queryParams: CommunityChannelPostListAPI['Get']['QueryParams']) => {
  const { channel_id, page, page_size, search } = queryParams;

  return requestApi({
    method: 'get',
    url: `ops/community/post/list/`,
    query: { channel_id, page, page_size, search },
  });
};

export const patchCommunityPostDetailAPI = (queryParams: CommunityPostDetailAPI['Patch']['QueryParams']) => {
  const { post_id, data } = queryParams;

  return requestApi({
    method: 'patch',
    url: `/community/post/${post_id}/`,
    data,
  });
};

export const getCommunityPostDetailAPI = (queryParams: CommunityPostDetailAPI['Get']['QueryParams']) => {
  const { post_id } = queryParams;

  return requestApi({
    method: 'get',
    url: `ops/community/post/${post_id}/`,
  });
};

export const postCommunityPostCreateAPI = (data: CommunityPostCreateAPI['Post']['RequestBody']) => {
  return requestApi({
    method: 'post',
    url: `/community/post/`,
    data,
  });
};

export const postCommunityCommentAPI = (data: CommunityCommentAPI['Post']['RequestBody']) => {
  return requestApi({
    method: 'post',
    url: `community/comment/`,
    data,
  });
};

export const getCommunityCommentAPI = (request: CommunityCommentAPI['Get']['QueryParams']) => {
  const { page, page_size, post_id } = request;

  return requestApi({
    method: 'get',
    url: `ops/community/comment/`,
    query: { page, page_size, post_id },
  });
};

export const getCommunityCommentRepliesAPI = (queryParams: CommunityCommentRepliesAPI['Get']['QueryParams']) => {
  const { page, page_size, comment_id } = queryParams;

  return requestApi({
    method: 'get',
    url: `ops/community/comment/${comment_id}/replies/`,
    query: { page, page_size },
  });
};

export const patchCommunityCommentAPI = (
  queryParams: CommunityCommentAPI['Patch']['QueryParams'],
  data: CommunityCommentAPI['Patch']['RequestBody']
) => {
  const { comment_id } = queryParams;

  return requestApi({
    method: 'patch',
    url: `community/comment/${comment_id}/`,
    data,
  });
};

export const getCommunityCommentDetailAPI = (queryParams: CommunityCommentDetailAPI['Get']['QueryParams']) => {
  const { comment_id } = queryParams;

  return requestApi({
    method: 'get',
    url: `ops/community/comment/${comment_id}/`,
  });
};

export const patchCommunityCommentDetailAPI = (
  queryParams: CommunityCommentDetailAPI['Patch']['QueryParams'],
  data: CommunityCommentDetailAPI['Patch']['RequestBody']
) => {
  const { comment_id } = queryParams;

  return requestApi({
    method: 'get',
    url: `community/comment/${comment_id}/`,
    data,
  });
};
