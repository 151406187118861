import { CTAButton } from '@nurihaus/web-design-system';
import styled from 'styled-components';

import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

interface Props {
  handleConfirm: () => void;
  close: () => void;
}

const CreatePostConfirmModal = ({ handleConfirm, close }: Props) => {
  return (
    <ModalForm width="400px" handleClose={close} backgroundOnPress={close}>
      <Container>
        <StyledText text="Create Post" fontSize="lg" fontWeight="bold" />
        <Gap side={10} />
        <StyledText text="If you’d like to continue,  click the Post button." fontSize="sm" textAlign="center" />
        <Gap side={30} />
        <FooterWrapper>
          <CTAButton size="m" text="Cancel" variant="ghost" onClick={close} style={{ width: '100%' }} />
          <CTAButton
            size="m"
            style={{ width: '100%' }}
            text="Confirm"
            onClick={() => {
              handleConfirm();
              close();
            }}
          />
        </FooterWrapper>
      </Container>
    </ModalForm>
  );
};

export default CreatePostConfirmModal;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -15px 0;
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
