import { Dispatch, SetStateAction } from 'react';

import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import EditBackgroundImage from 'components/pages/community/channel/edit/EditBackgroundImage';
import EditChannelDescription from 'components/pages/community/channel/edit/EditChannelDescription';
import EditChannelID from 'components/pages/community/channel/edit/EditChannelID';
import EditChannelLogo from 'components/pages/community/channel/edit/EditChannelLogo';

import { ChannelPatchInfo } from 'types/community/internal';

interface Props {
  channelInfo: ChannelPatchInfo | null;
  setChannelInfo: Dispatch<SetStateAction<ChannelPatchInfo | null>>;
}

const CommunityChannelEditContainer = ({ channelInfo, setChannelInfo }: Props) => {
  return (
    <>
      <StyledText text="Edit Channel" fontSize="xl" fontWeight="extraBold" />
      <Gap side={30} />
      <EditChannelID
        id={channelInfo?.id}
        name={channelInfo?.name}
        is_display={channelInfo?.is_display}
        setChannelInfo={setChannelInfo}
      />
      <Gap side={10} />
      <EditChannelLogo logo_url={channelInfo?.logo_url} />
      <Gap side={10} />
      <EditChannelDescription description={channelInfo?.description || ''} setChannelInfo={setChannelInfo} />
      <Gap side={10} />
      <EditBackgroundImage background_url={channelInfo?.background_url} />
    </>
  );
};

export default CommunityChannelEditContainer;
