// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-loading-container {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.loading-indicator-ring {
  position: relative;
  width: 20px;
  height: 20px;
}

.loading-indicator-single-ring {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

  border-color: var(--color-primary-white) transparent transparent transparent;

  &.dark {
    border-color: var(--color-primary-gray-e4) transparent transparent
      transparent;
  }
}

.loading-indicator-single-ring:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-indicator-single-ring:nth-child(2) {
  animation-delay: -0.3s;
}

.loading-indicator-single-ring:nth-child(3) {
  animation-delay: -0.15s;
}

.loading-indicator-single-ring:nth-child(4) {
  animation-delay: 0s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./components/loading/loading-style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,sBAAsB;EACtB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,8DAA8D;;EAE9D,4EAA4E;;EAE5E;IACE;iBACa;EACf;AACF;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".component-loading-container {\n  display: flex;\n  width: 20px;\n  height: 20px;\n  align-items: center;\n  justify-content: center;\n}\n\n.loading-indicator-ring {\n  position: relative;\n  width: 20px;\n  height: 20px;\n}\n\n.loading-indicator-single-ring {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 20px;\n  height: 20px;\n  border: 3px solid;\n  border-radius: 50%;\n  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n\n  border-color: var(--color-primary-white) transparent transparent transparent;\n\n  &.dark {\n    border-color: var(--color-primary-gray-e4) transparent transparent\n      transparent;\n  }\n}\n\n.loading-indicator-single-ring:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.loading-indicator-single-ring:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.loading-indicator-single-ring:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n.loading-indicator-single-ring:nth-child(4) {\n  animation-delay: 0s;\n}\n\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
