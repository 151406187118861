var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./badge-style.css";
import { BaseBadge } from "./base-badge";
var IconTextOptionBadge = function (props) {
    var icon = props.icon, text = props.text, option = props.option, restProps = __rest(props, ["icon", "text", "option"]);
    return (_jsxs(BaseBadge, __assign({}, restProps, { className: "icon-text-option", children: [_jsxs("span", { children: [icon, _jsx("span", { children: text })] }), option] })));
};
export default IconTextOptionBadge;
