import { createApiFunction } from 'utils/common/axios-utils';

import {
  BenefitList,
  ChatBenefitList,
  ChatBenefitListStaff,
  ChatQuestList,
  ChatQuestListStaff,
  QuestList,
} from 'types/campaign/remote';

export const getQuestList = createApiFunction<void, QuestList['Get']['Response'], QuestList['Get']['QueryParams']>({
  method: 'get',
  url: '/ops/lounge/quest/',
});

export const getBenefitList = createApiFunction<
  void,
  BenefitList['Get']['Response'],
  BenefitList['Get']['QueryParams']
>({
  method: 'get',
  url: '/ops/lounge/event/',
});

export const getChatQuestList = createApiFunction<
  void,
  ChatQuestList['Get']['Response'],
  ChatQuestList['Get']['QueryParams']
>({
  method: 'get',
  url: '/lounge/quest/chat/list/',
});

export const getChatQuestListStaff = createApiFunction<
  void,
  ChatQuestListStaff['Get']['Response'],
  ChatQuestListStaff['Get']['QueryParams']
>({
  method: 'get',
  url: '/lounge/quest/chat/list/staff/',
});

export const getChatBenefitList = createApiFunction<
  void,
  ChatBenefitList['Get']['Response'],
  ChatBenefitList['Get']['QueryParams']
>({
  method: 'get',
  url: '/lounge/event/chat/list/',
});

export const getChatBenefitListStaff = createApiFunction<
  void,
  ChatBenefitListStaff['Get']['Response'],
  ChatBenefitListStaff['Get']['QueryParams']
>({
  method: 'get',
  url: '/lounge/event/chat/list/staff/',
});
