import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  getCommunityChannelDetailAPI,
  getCommunityChannelListAPI,
  getCommunityChannelPostListAPI,
  getCommunityCommentAPI,
  getCommunityCommentDetailAPI,
  getCommunityCommentRepliesAPI,
  getCommunityPostDetailAPI,
  patchCommunityChannelDetailAPI,
  patchCommunityCommentAPI,
  patchCommunityPostDetailAPI,
  postCommunityCommentAPI,
  postCommunityPostCreateAPI,
} from 'api/community';

import {
  CommunityChannelDetailAPI,
  CommunityChannelListAPI,
  CommunityChannelPostListAPI,
  CommunityCommentAPI,
  CommunityCommentDetailAPI,
  CommunityCommentRepliesAPI,
  CommunityPostCreateAPI,
  CommunityPostDetailAPI,
} from 'types/community/remote';

export const useCommunityChannelDetail = (queryParams: CommunityChannelDetailAPI['Get']['QueryParams']) => {
  const { data, isLoading } = useQuery<CommunityChannelDetailAPI['Get']['Response'], AxiosError>(
    ['channelDetail', queryParams.channel_id],
    () => getCommunityChannelDetailAPI(queryParams),
    {
      enabled: !!queryParams.channel_id,
    }
  );

  return { data, isLoading };
};

export const useCommunityChannelList = (queryParams: CommunityChannelListAPI['Get']['QueryParams']) => {
  const { page, page_size } = queryParams;
  const queryClient = useQueryClient();

  const { data, isFetching, isRefetching, refetch, isLoading } = useQuery<
    CommunityChannelListAPI['Get']['Response'],
    AxiosError
  >(['channelList', page, page_size], () => getCommunityChannelListAPI({ page, page_size }), {
    keepPreviousData: true,
  });

  const invalidateChannelList = () => {
    queryClient.invalidateQueries(['channelList', page, page_size]);
  };

  return { data, isFetching, isRefetching, refetch, isLoading, invalidateChannelList };
};

export const useCommunityChannelPostList = (queryParams: CommunityChannelPostListAPI['Get']['QueryParams']) => {
  const { channel_id, page, page_size, search } = queryParams;
  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching, refetch } = useQuery<
    CommunityChannelPostListAPI['Get']['Response'],
    AxiosError
  >(
    ['postList', channel_id, page, page_size],
    () => getCommunityChannelPostListAPI({ channel_id, page, page_size, search }),
    {
      keepPreviousData: true,
    }
  );

  const invalidateChannelPostList = () => {
    queryClient.invalidateQueries(['postList', channel_id, page, page_size]);
  };

  return { data, isLoading, isRefetching, refetch, invalidateChannelPostList };
};

export const usePatchCommunityPostDetail = ({
  handleSuccess,
  handleError,
}: UsePostMutationProps<CommunityPostDetailAPI['Patch']['Response']>) => {
  const { mutate, isLoading, isError } = useMutation<
    CommunityPostDetailAPI['Patch']['Response'],
    AxiosError,
    CommunityPostDetailAPI['Patch']['QueryParams']
  >(patchCommunityPostDetailAPI, { onSuccess: handleSuccess, onError: handleError });

  return { mutate, isLoading, isError };
};

export const useGetCommunityPostDetail = (request: CommunityPostDetailAPI['Get']['QueryParams']) => {
  const { post_id } = request;

  return useQuery<CommunityPostDetailAPI['Get']['Response'], AxiosError>(
    ['postDetail', post_id],
    () => getCommunityPostDetailAPI({ post_id }),
    {
      enabled: !!post_id,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
    }
  );
};

interface UsePostMutationProps<TResponse> {
  handleSuccess?: (data?: TResponse) => void;
  handleError?: (error: AxiosError) => void;
}

export const usePostCommunityPost = ({
  handleSuccess,
  handleError,
}: UsePostMutationProps<CommunityPostCreateAPI['Post']['Response']>) => {
  const { mutate, isLoading, isError } = useMutation<
    CommunityPostCreateAPI['Post']['Response'],
    AxiosError,
    CommunityPostCreateAPI['Post']['RequestBody']
  >(postCommunityPostCreateAPI, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return { mutate, isLoading, isError };
};

export const usePostCommunityComment = ({
  handleSuccess,
  handleError,
}: UsePostMutationProps<CommunityCommentAPI['Post']['Response']>) => {
  const { mutate, isLoading, isError } = useMutation<
    CommunityCommentAPI['Post']['Response'],
    AxiosError,
    CommunityCommentAPI['Post']['RequestBody']
  >(postCommunityCommentAPI, { onSuccess: handleSuccess, onError: handleError });

  return { mutate, isLoading, isError };
};
export const useCommunityCommentList = (queryParams: CommunityCommentAPI['Get']['QueryParams']) => {
  const { page, page_size, post_id } = queryParams;

  const { data, isFetching, isRefetching, refetch, isLoading } = useQuery<
    CommunityCommentAPI['Get']['Response'],
    AxiosError
  >(['commentList', page, page_size, post_id], () => getCommunityCommentAPI({ page, page_size, post_id }), {
    keepPreviousData: false,
    staleTime: 0,
    cacheTime: 1000 * 5,
  });

  return { data, isFetching, isRefetching, refetch, isLoading };
};

export const useCommunityCommentReplies = ({ comment_id }: { comment_id: number }) => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } = useInfiniteQuery<
    CommunityCommentRepliesAPI['Get']['Response'],
    AxiosError
  >({
    queryKey: ['commentReplies', comment_id],
    queryFn: ({ pageParam = 1 }) => getCommunityCommentRepliesAPI({ comment_id, page: pageParam, page_size: 1000 }),
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.next ? allPages.length + 1 : undefined;
    },
  });

  return { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch };
};

export const usePatchCommunityComment = ({
  handleSuccess,
  handleError,
}: UsePostMutationProps<CommunityPostCreateAPI['Post']['Response']>) => {
  const queryClient = useQueryClient();

  const { data, mutate, isLoading } = useMutation<
    CommunityCommentAPI['Patch']['Response'],
    AxiosError,
    { queryParams: CommunityCommentAPI['Patch']['QueryParams']; data: CommunityCommentAPI['Patch']['RequestBody'] }
  >(({ queryParams, data }) => patchCommunityCommentAPI(queryParams, data), {
    onError: handleError,
    onSuccess: () => {
      if (handleSuccess) handleSuccess();

      queryClient.invalidateQueries(['commentReplies']);
    },
  });

  return { data, mutate, isLoading };
};

export const usePatchCommunityChannel = ({
  handleSuccess,
  handleError,
}: UsePostMutationProps<CommunityChannelDetailAPI['Patch']['Response']>) => {
  const queryClient = useQueryClient();

  const { data, mutate, isLoading } = useMutation<
    CommunityChannelDetailAPI['Patch']['Response'],
    AxiosError,
    {
      queryParams: CommunityChannelDetailAPI['Patch']['QueryParams'];
      data: CommunityChannelDetailAPI['Patch']['RequestBody'];
    }
  >(({ queryParams, data }) => patchCommunityChannelDetailAPI(queryParams, data), {
    onError: handleError,
    onSuccess: () => {
      if (handleSuccess) handleSuccess();

      queryClient.invalidateQueries(['commentReplies']);
    },
  });

  return { data, mutate, isLoading };
};

export const useGetCommunityCommentDetail = (queryParams: CommunityCommentDetailAPI['Get']['QueryParams']) => {
  return useQuery<CommunityCommentDetailAPI['Get']['Response'], AxiosError>(
    ['commentDetail', queryParams.comment_id],
    () => getCommunityCommentDetailAPI(queryParams)
  );
};

export const patchCommunityCommentDetail = () => {};
