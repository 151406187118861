import { ChangeEvent, useRef, useState } from 'react';

import styled from 'styled-components';

import { uploadToPresigned } from 'utils/common/file/uploadToPresigned';

import Icon from 'components/Icons/Icon';

interface VideoUploadProps {
  setVideoData: (data: { video_url: string; video_key: string }) => void;
}

const VideoUploadInput = ({ setVideoData }: VideoUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleVideoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    fileInputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const validTypes = ['video/mp4', 'video/quicktime'];
    const maxSize = 100 * 1024 * 1024; // 100MB 제한

    if (!validTypes.includes(file.type)) {
      alert('Only MP4 and MOV files are allowed.');

      return;
    }

    if (file.size > maxSize) {
      alert('Video must be under 100MB.');

      return;
    }

    setVideoData({ video_url: '', video_key: 'loading' });
    setIsUploading(true);

    try {
      const { file_url, object_key } = await uploadToPresigned(file, 'community/post/videos/');

      setVideoData({ video_url: file_url, video_key: object_key });
    } catch (error) {
      console.error('Video upload failed:', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <VideoUploadWrapper>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept="video/*" />
      <IconButton onClick={handleVideoClick} disabled={isUploading}>
        <Icon type="video" width={24} height={24} fill="grey71" />
      </IconButton>
    </VideoUploadWrapper>
  );
};

export default VideoUploadInput;

const VideoUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
