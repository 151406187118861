import { color, getReportTypeInfo } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { getKSTFormattedDate } from 'utils/common/dateFormat';

import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';

interface Props {
  reportId: number;
  reportType: number;
  created: string;
}

const ReportDetailIDBlock = ({ reportId, reportType, created }: Props) => {
  return (
    <Block padding="30px">
      <Container>
        <RightWrapper>
          <KeyAndValueWrapper>
            <KeyBadge>
              <StyledText text="Report ID" fontSize="sm" fontColor="grey52" />
            </KeyBadge>
            <StyledText text={String(reportId)} fontSize="sm" />
          </KeyAndValueWrapper>
          <KeyAndValueWrapper>
            <KeyBadge>
              <StyledText text="Report Type" fontSize="sm" fontColor="grey52" />
            </KeyBadge>
            <StyledText text={getReportTypeInfo(reportType)?.title} />
          </KeyAndValueWrapper>
        </RightWrapper>
        <Gap side={30} />
        <KeyAndValueWrapper>
          <KeyBadge>
            <StyledText text="Created Date" fontSize="sm" fontColor="grey52" />
          </KeyBadge>
          <StyledText text={getKSTFormattedDate(created)} />
        </KeyAndValueWrapper>
      </Container>
    </Block>
  );
};

export default ReportDetailIDBlock;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const RightWrapper = styled.div`
  width: 570px;
  display: flex;
  align-items: center;
  column-gap: 40px;
`;

const KeyAndValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const KeyBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: ${color.greyf4};
  border-radius: 50px;
`;
