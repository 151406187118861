var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BaseButton from "./base-button";
import Loading from "../loading/loading";
var CTAButton = function (props) {
    var text = props.text, _a = props.variant, variant = _a === void 0 ? "primary" : _a, _b = props.size, size = _b === void 0 ? "m" : _b, _c = props.disabled, disabled = _c === void 0 ? false : _c, _d = props.loading, loading = _d === void 0 ? false : _d, leftIcon = props.leftIcon, rightIcon = props.rightIcon, restProps = __rest(props, ["text", "variant", "size", "disabled", "loading", "leftIcon", "rightIcon"]);
    return (_jsx(BaseButton, __assign({ disabled: disabled }, restProps, { className: "nuri-theme cta-button ".concat(disabled ? "disabled" : variant, " ").concat(size), children: loading ? (_jsx(Loading, { variant: variant === "primary" || variant === "secondary" ? "light" : "dark" })) : (_jsxs("div", { style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
            }, children: [leftIcon, text, rightIcon] })) })));
};
export default CTAButton;
