import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./check-box-style.css";
import "../../styles/token/color.css";
import "../../styles/token/font.css";
import check from "../../assets/icons/check.svg";
import CheckBoxRoot from "./base-check-box";
var CheckBox = function (props) {
    var checked = props.checked, onChange = props.onChange, label = props.label, _a = props.iconSize, iconSize = _a === void 0 ? 24 : _a, id = props.id, disabled = props.disabled;
    return (_jsx(CheckBoxRoot, { id: id, checked: checked, onChange: onChange, disabled: disabled, children: _jsxs("div", { className: "check-box", children: [_jsx(CheckBoxRoot.Input, { asChild: true, children: _jsx("div", { className: "nuri-theme check-box-input", style: { width: "".concat(iconSize, "px"), height: "".concat(iconSize, "px") }, children: _jsx("img", { src: check, alt: "check" }) }) }), _jsx(CheckBoxRoot.Label, { children: label })] }) }));
};
export default CheckBox;
