import CommunityReportListContainer from 'containers/community/report/CommunityReportListContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const CommunityReportListPage = () => {
  return (
    <DashboardLayout headerTitle="Community" innerPadding="40px">
      <CommunityReportListContainer />
    </DashboardLayout>
  );
};

export default CommunityReportListPage;
