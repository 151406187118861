import { ChangeEvent, useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { CTAButton } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { usePostCommunityComment } from 'hooks/community/queries';

import PageLoading from 'components/common/loading/PageLoading';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

interface Props {
  postID: number;
  parentID: number;
  content: string;
  commentListPage?: number;
  close: () => void;
}

const PostCommentReplyModal = ({ close, postID, parentID, content, commentListPage }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    queryClient.invalidateQueries(['commentList', commentListPage, 20, postID]);
    queryClient.invalidateQueries(['postDetail', postID]);
    queryClient.invalidateQueries(['commentReplies', parentID]);

    close();
  };
  const handleError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };

  const { mutate: createComment, isLoading } = usePostCommunityComment({ handleError, handleSuccess });
  const [reply, setReply] = useState('');

  const handleReplyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 1000);
    setReply(value);
  };
  const handleCreateClick = () => {
    if (!isLoading) {
      createComment({
        post_id: postID,
        content: reply,
        parent_id: parentID,
        is_active: true,
        is_display: true,
      });
    }
  };

  const createDisabled = isLoading || reply.length < 3;

  return (
    <ModalForm width="500px" handleClose={close}>
      {isLoading && <PageLoading isLoading />}
      <Container>
        <TitleSection>
          <StyledText text="Add a Reply" fontSize="lg" fontWeight="bold" />
        </TitleSection>
        <InputSection>
          <ContentWrapper>
            <StyledText text="• Comment:" fontSize="sm" fontWeight="semiBold" fontColor="grey52" />
            <StyledText text={content} wordBreak="break-word" numberOfLines={1} fontSize="sm" />
          </ContentWrapper>
          <Gap side={16} />
          <StyledText text="• Reply" fontSize="sm" fontWeight="semiBold" fontColor="grey52" />
          <Gap side={14} />
          <ContentInput
            placeholder="Enter up to 1,000 characters (minimum 3)."
            onChange={handleReplyChange}
            value={reply}
          />
        </InputSection>
        <FooterSection>
          <CTAButton style={{ width: '100%' }} onClick={close} variant="ghost" text="Cancel" size="m" />
          <CTAButton
            style={{ width: '100%' }}
            onClick={handleCreateClick}
            variant="primary"
            text="Create"
            size="m"
            disabled={createDisabled}
          />
        </FooterSection>
      </Container>
    </ModalForm>
  );
};

export default PostCommentReplyModal;

const Container = styled.div`
  width: 100%;
`;

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 60px);
  padding: 0 0 30px 0;
  margin: -15px -30px 0 -30px;
  border-bottom: 1px solid ${color.greye4};
`;

const InputSection = styled.div`
  margin: 30px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const FooterSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-top: 20px;
`;

const ContentInput = styled.textarea`
  width: 100%;
  height: 200px;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  resize: none;
  padding: 14px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  &:focus {
    border: 1.5px solid ${color.violet4c};
    outline: none;
  }
`;
