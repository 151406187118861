import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { useGetCommunityCommentDetail } from 'hooks/community/queries';

import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

interface Props {
  commentId: number;
}

const ReportCommentInfo = ({ commentId }: Props) => {
  const { data, isLoading } = useGetCommunityCommentDetail({ comment_id: commentId });

  if (isLoading || !data) return <ComponentLoading size={30} />;

  return (
    <>
      <ContentsWrapper>
        <KeyBadge>
          <StyledText text="Reported Contents" fontColor="grey52" fontSize="sm" />
        </KeyBadge>
        <Gap side={20} />
        <StyledText text={data.content} numberOfLines={3} wordBreak="break-word" />
      </ContentsWrapper>
    </>
  );
};

export default ReportCommentInfo;

const ContentsWrapper = styled.div`
  padding: 30px;
`;
const KeyBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: ${color.greyf4};
  border-radius: 50px;
`;
