import styled from 'styled-components';

import Block from 'components/layout/common/Block';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

interface Props {
  background_url?: string;
}

const EditBackgroundImage = ({ background_url }: Props) => {
  return (
    <Block>
      <Container>
        <KeyBadge text="Background Image" />
        <BackgroundImage src={background_url} />
      </Container>
    </Block>
  );
};

export default EditBackgroundImage;

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;

const BackgroundImage = styled.img`
  width: 375px;
  height: 120px;
  border-radius: 6px;
  object-fit: cover;
`;
