var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./table-style.css";
import { forwardRef } from "react";
var TableRoot = function (props) {
    return _jsx("table", { className: "base-table table-root", children: props.children });
};
var TableHeader = function (props) {
    var _a = props.justify, justify = _a === void 0 ? "center" : _a, className = props.className, rest = __rest(props, ["justify", "className"]);
    return (_jsx("thead", { className: "base-table table-header ".concat(justify, " ").concat(className), children: props.children }));
};
var TableRow = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    var classNames = ["base-table", "table-row", className].join(" ");
    return (_jsx("tr", __assign({ className: classNames }, rest, { children: props.children })));
};
var TableBody = function (props) {
    var className = props.className, rest = __rest(props, ["className"]);
    var classNames = ["base-table", "table-body", className].join(" ");
    return (_jsx("tbody", __assign({ className: classNames }, rest, { children: props.children })));
};
var TableCell = forwardRef(function (props, ref) {
    var className = props.className, rest = __rest(props, ["className"]);
    var classNames = ["base-table", "table-cell", className].join(" ");
    return (_jsx("td", __assign({ ref: ref, className: classNames }, rest, { children: props.children })));
});
TableRoot.Header = TableHeader;
TableRoot.Body = TableBody;
TableRoot.Row = TableRow;
TableRoot.Cell = TableCell;
export default TableRoot;
