import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

const ReportListHeader = () => {
  return (
    <Container>
      <StyledText text={'Report'} fontWeight="bold" fontSize="xl3" />
    </Container>
  );
};

export default ReportListHeader;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
