import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import Icon from 'components/Icons/Icon';
import TextInput from 'components/common/input/customMuiInput/TextInput';
import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';
import ContentTextarea from 'components/pages/community/common/input/ContentTextarea';
import HashtagInput from 'components/pages/community/common/input/HashtagInput';
import ImageUploadInput from 'components/pages/community/common/input/ImageUploadInput';
import VideoUploadInput from 'components/pages/community/common/input/VideoUploadInput';

import { PostCreateInfo } from 'types/community/internal';
import { CommunityChannelDetailAPI } from 'types/community/remote';

interface Props {
  postInfo: PostCreateInfo;
  setPostInfo: Dispatch<SetStateAction<PostCreateInfo>>;
  channelData: CommunityChannelDetailAPI['Get']['Response'];
}

const CommunityPostCreateContainer = ({ postInfo, setPostInfo, channelData }: Props) => {
  const { title, video_url, video_key, images, content } = postInfo;

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 100);
    setPostInfo({ ...postInfo, title: value });
  };

  const handleHashtagClick = () => {
    setPostInfo({ ...postInfo, hashtags: [...postInfo.hashtags, ''] });
  };

  const handleVideoUpload = (data: { video_url: string; video_key: string }) => {
    setPostInfo({ ...postInfo, ...data });
  };

  const handleDeleteImage = (index: number) => {
    setPostInfo((prev) => {
      const updatedImages = prev.images
        .filter((_, i) => i !== index)
        .map((image, newIndex) => ({
          ...image,
          order: newIndex + 1,
        }));

      return {
        ...prev,
        images: updatedImages,
      };
    });
  };

  const handleDeleteVideo = () => {
    setPostInfo({ ...postInfo, video_url: '', video_key: '' });
  };

  const isDisplayImgVideoPrev = (video_url && video_key) || images.length > 0;

  return (
    <Container>
      <StyledText text="Add a New Post" fontWeight="bold" fontSize="xl2" />
      <Gap side={30} />
      <Block>
        <ChannelNameWrapper>
          <ChannelNameBadge>
            <StyledText text="Channel Name" fontSize="sm" fontColor="grey52" />
          </ChannelNameBadge>
          <StyledText text={channelData.name} fontSize="sm" />
        </ChannelNameWrapper>
      </Block>
      <Gap side={10} />
      <Block>
        <InputSection>
          <TitleWrapper>
            <StyledText text="Title" fontSize="sm" fontWeight="bold" />
            <TextInput
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter up to 100 characters (minimum 5)."
            />
            <MessageLength>
              <StyledText fontSize="base" fontWeight="medium" text={`${title?.length}/100`} fontColor="greya1" />
            </MessageLength>
          </TitleWrapper>
          <Gap side={20} />
          <BodyWrapper>
            <StyledText text="Body" fontSize="sm" fontWeight="bold" />
            <Gap side={10} />
            <InputWrapper>
              <ContentTextarea content={content} setContent={(value) => setPostInfo({ ...postInfo, content: value })} />
              <HashtagInput
                hashtags={postInfo.hashtags}
                setHashtags={(value) => setPostInfo({ ...postInfo, hashtags: value })}
              />
              {isDisplayImgVideoPrev && (
                <ImgVideoWrapper>
                  {video_url ? (
                    video_key === 'loading' ? (
                      <ImagePreviewWrapper>
                        <ImageDeleteButton onClick={handleDeleteVideo}>
                          <Icon type="trash" width={18} height={18} />
                        </ImageDeleteButton>
                        <ComponentLoading size={10} justifyContent="center" />
                      </ImagePreviewWrapper>
                    ) : (
                      <ImagePreviewWrapper>
                        <ImageDeleteButton onClick={handleDeleteVideo}>
                          <Icon type="trash" width={18} height={18} />
                        </ImageDeleteButton>
                        <VideoPreview src={video_url} controls preload="none" />
                      </ImagePreviewWrapper>
                    )
                  ) : null}

                  {images
                    ?.sort((a, b) => a.order - b.order) // ✅ order 기준 정렬하여 렌더링
                    ?.map((img, index) => (
                      <ImagePreviewContainer key={index}>
                        <ImageDeleteButton onClick={() => handleDeleteImage(index)}>
                          <Icon type="trash" width={18} height={18} />
                        </ImageDeleteButton>
                        {img.isLoading ? (
                          <ImagePreviewWrapper>
                            <ComponentLoading size={10} justifyContent="center" />
                          </ImagePreviewWrapper>
                        ) : (
                          <ImagePreviewWrapper>
                            <ImagePreview src={img.file_url} alt={`Uploaded ${index + 1}`} />
                          </ImagePreviewWrapper>
                        )}
                      </ImagePreviewContainer>
                    ))}
                </ImgVideoWrapper>
              )}
              <InputOptionWrapper>
                <ImageUploadInput images={images} setImages={(value) => setPostInfo({ ...postInfo, images: value })} />
                <VideoUploadInput setVideoData={handleVideoUpload} />
                <IconButton onClick={handleHashtagClick}>
                  <Icon type="hashtag" width={24} height={24} fill="grey71" />
                </IconButton>
              </InputOptionWrapper>
            </InputWrapper>
          </BodyWrapper>
        </InputSection>
      </Block>
    </Container>
  );
};

export default CommunityPostCreateContainer;

const Container = styled.div``;

const ChannelNameWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const ChannelNameBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  background-color: ${color.greyf4};
  padding: 8px 0;
  border-radius: 50px;
`;

const InputSection = styled.div``;

const TitleWrapper = styled.div`
  position: relative;
  row-gap: 8px;
`;

const MessageLength = styled.div`
  position: absolute;
  bottom: 12.5px;
  right: 14px;
`;

const BodyWrapper = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.greyd4};
  height: 516px;
  border-radius: 4px;
  overflow: hidden;
  &:focus-within {
    border: 1.5px solid ${color.violet4c};
  }
`;

const ImgVideoWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 98px;
  height: 98px;
  margin-bottom: 20px;
  padding: 0 14px;
  column-gap: 10px;
`;

const InputOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  border-top: 1.5px solid ${color.greyd4};
  padding: 0 20px;
  min-height: 50px;
  height: 50px;
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
`;

const ImagePreviewWrapper = styled.div`
  position: relative;
  width: 98px;
  height: 98px;
  border-radius: 10px;
  border: 1px solid ${color.greyd4};
  overflow: hidden;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
`;

const ImageDeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border-radius: 100%;
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: ${color.white};
  top: 6px;
  right: 6px;
`;

const VideoPreview = styled.video`
  width: 100%;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
`;
