import { useState } from 'react';

import { useCommunityCommentList } from 'hooks/community/queries';

import PageLoading from 'components/common/loading/PageLoading';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';
import PostCommentList from 'components/pages/community/post/detail/comment/PostCommentList';
import PostCommentPagination from 'components/pages/community/post/detail/comment/PostCommentPagination';

interface Props {
  id?: number;
}

const CommunityPostCommentContainer = ({ id: post_id }: Props) => {
  const [commentPage, setCommentPage] = useState(1);
  const commentPageSize = 20;

  const { data: commentData, isLoading: isCommentLoading } = useCommunityCommentList({
    post_id: Number(post_id),
    page: commentPage,
    page_size: commentPageSize,
  });

  if (!commentData || isCommentLoading) return <PageLoading isLoading />;

  const { count, results } = commentData;

  return (
    <>
      <Gap side={40} />
      <StyledText text={`Comments (${count})`} fontSize="xl2" fontWeight="bold" />
      <Gap side={30} />
      <Block padding="0px" overflowY="hidden">
        <PostCommentList data={results} page={commentPage} postID={Number(post_id)} />
        <PostCommentPagination page={commentPage} setPage={setCommentPage} page_size={commentPageSize} count={count} />
      </Block>
    </>
  );
};

export default CommunityPostCommentContainer;
