import { CTAButton } from '@nurihaus/web-design-system';
import { useNavigate, useParams } from 'react-router-dom';

import CommunityChannelDetailContainer from 'containers/community/channel/CommunityChannelDetailContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const CommunityChannelDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClickNewPost = () => {
    navigate(`/community/manage/channel/post/create/${id}`);
  };

  return (
    <DashboardLayout
      headerTitle="Community"
      innerPadding="40px"
      headerRight={<CTAButton text="+ New Post" size="m" style={{ width: '180px' }} onClick={handleClickNewPost} />}
    >
      <CommunityChannelDetailContainer />
    </DashboardLayout>
  );
};

export default CommunityChannelDetailPage;
