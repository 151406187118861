// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-text-field {
  &:where(.text-field-root) {
    width: 100%;
  }
}

.text-field {
  &.label {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-weight: var(--font-weight-bold);
  }

  &.input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
    padding: 14px;
    border: 1px solid var(--color-primary-gray-d4);
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  &.input {
    width: 100%;
    border: none;
    outline: none;
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-weight: var(--font-weight-medium);

    ::placeholder {
      color: var(--color-primary-gray-a1);
    }
  }

  &.helper-text {
    font-size: var(--font-size-sm);
    line-height: var(--line-height-sm);
    font-weight: var(--font-weight-medium);

    &.error {
      color: var(--color-accent-red-df);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./components/text-field/text-field-style.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,gCAAgC;IAChC,oCAAoC;IACpC,oCAAoC;EACtC;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,8CAA8C;IAC9C,kBAAkB;IAClB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,kCAAkC;IAClC,sCAAsC;;IAEtC;MACE,mCAAmC;IACrC;EACF;;EAEA;IACE,8BAA8B;IAC9B,kCAAkC;IAClC,sCAAsC;;IAEtC;MACE,iCAAiC;IACnC;EACF;AACF","sourcesContent":[".base-text-field {\n  &:where(.text-field-root) {\n    width: 100%;\n  }\n}\n\n.text-field {\n  &.label {\n    font-size: var(--font-size-base);\n    line-height: var(--line-height-base);\n    font-weight: var(--font-weight-bold);\n  }\n\n  &.input-wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 22px;\n    padding: 14px;\n    border: 1px solid var(--color-primary-gray-d4);\n    border-radius: 4px;\n    margin-top: 8px;\n    margin-bottom: 4px;\n  }\n\n  &.input {\n    width: 100%;\n    border: none;\n    outline: none;\n    font-size: var(--font-size-sm);\n    line-height: var(--line-height-sm);\n    font-weight: var(--font-weight-medium);\n\n    ::placeholder {\n      color: var(--color-primary-gray-a1);\n    }\n  }\n\n  &.helper-text {\n    font-size: var(--font-size-sm);\n    line-height: var(--line-height-sm);\n    font-weight: var(--font-weight-medium);\n\n    &.error {\n      color: var(--color-accent-red-df);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
