import { useEffect } from 'react';

import { useQueryParams } from 'hooks/common/useQueryParams';
import { useRouter } from 'hooks/common/useRouter';

import ChatStaffContainer from 'containers/chat/ChatStaffContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const ChatStaffPage = () => {
  const router = useRouter();
  const query = useQueryParams();

  useEffect(() => {
    if (query.get('search') != null) return;

    router.updateQS({ search: '' });
  }, []);

  return (
    <DashboardLayout headerTitle="Chat Staff">
      <ChatStaffContainer />
    </DashboardLayout>
  );
};

export default ChatStaffPage;
