// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nuri-theme {
  --font-size-xsm: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 24px;
  --font-size-3xl: 30px;
  --font-size-4xl: 36px;
  --font-size-5xl: 48px;
  --font-size-6xl: 60px;

  --line-height-xsm: 18px;
  --line-height-sm: 18px;
  --line-height-base: 22px;
  --line-height-lg: 24px;
  --line-height-xl: 28px;
  --line-height-2xl: 32px;
  --line-height-3xl: 36px;
  --line-height-4xl: 43px;
  --line-height-5xl: 66px;
  --line-height-6xl: 82px;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;

  font-family: "Twemoji Country Flags", "Pretendard", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./styles/token/font.css"],"names":[],"mappings":"AAIA;EACE,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;EACtB,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;;EAErB,uBAAuB;EACvB,sBAAsB;EACtB,wBAAwB;EACxB,sBAAsB;EACtB,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;EACvB,uBAAuB;;EAEvB,0BAA0B;EAC1B,yBAAyB;EACzB,2BAA2B;EAC3B,uBAAuB;EACvB,4BAA4B;;EAE5B;;;uEAGqE;AACvE","sourcesContent":["@import url(\"https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css\");\n@import url(\"https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap\");\n@import url(\"https://fonts.googleapis.com/icon?family=Material+Icons\");\n\n.nuri-theme {\n  --font-size-xsm: 12px;\n  --font-size-sm: 14px;\n  --font-size-base: 16px;\n  --font-size-lg: 18px;\n  --font-size-xl: 20px;\n  --font-size-2xl: 24px;\n  --font-size-3xl: 30px;\n  --font-size-4xl: 36px;\n  --font-size-5xl: 48px;\n  --font-size-6xl: 60px;\n\n  --line-height-xsm: 18px;\n  --line-height-sm: 18px;\n  --line-height-base: 22px;\n  --line-height-lg: 24px;\n  --line-height-xl: 28px;\n  --line-height-2xl: 32px;\n  --line-height-3xl: 36px;\n  --line-height-4xl: 43px;\n  --line-height-5xl: 66px;\n  --line-height-6xl: 82px;\n\n  --font-weight-regular: 400;\n  --font-weight-medium: 500;\n  --font-weight-semibold: 600;\n  --font-weight-bold: 700;\n  --font-weight-extrabold: 800;\n\n  font-family: \"Twemoji Country Flags\", \"Pretendard\", Pretendard, -apple-system,\n    BlinkMacSystemFont, system-ui, Roboto, \"Helvetica Neue\", \"Segoe UI\",\n    \"Apple SD Gothic Neo\", \"Noto Sans KR\", \"Malgun Gothic\", \"Apple Color Emoji\",\n    \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
