import { useParams } from 'react-router-dom';

import { useGetCommunityPostDetail } from 'hooks/community/queries';

import CommunityPostCommentContainer from 'containers/community/post/CommunityPostCommentContainer';
import CommunityPostDetailContainer from 'containers/community/post/CommunityPostDetailContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';
import CreatePostCommentButton from 'components/pages/community/post/detail/CreatePostCommentButton';

const CommunityPostDetail = () => {
  const { id } = useParams();

  if (!id) return null;

  const { data: postData, isLoading: isPostDataLoading } = useGetCommunityPostDetail({ post_id: Number(id) });

  return (
    <DashboardLayout headerRight={<CreatePostCommentButton postId={Number(id)} title={postData?.title} />}>
      {!isPostDataLoading && postData && <CommunityPostDetailContainer postData={postData} />}
      <CommunityPostCommentContainer id={Number(id)} />
    </DashboardLayout>
  );
};

export default CommunityPostDetail;
