import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { useGetCommunityPostDetail } from 'hooks/community/queries';

import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

interface Props {
  postId: number;
}

const ReportPostInfo = ({ postId }: Props) => {
  const { data, isLoading } = useGetCommunityPostDetail({ post_id: postId });

  if (isLoading || !data) return <ComponentLoading size={30} />;

  return (
    <>
      <TitleWrapper>
        <KeyAndValueWrapper>
          <KeyBadge>
            <StyledText text="Post Title" fontColor="grey52" fontSize="sm" />
          </KeyBadge>
          <StyledText text={data?.title} />
        </KeyAndValueWrapper>
      </TitleWrapper>
      <ContentsWrapper>
        <KeyBadge>
          <StyledText text="Reported Contents" fontColor="grey52" fontSize="sm" />
        </KeyBadge>
        <Gap side={20} />
        <StyledText text={data.content} numberOfLines={3} wordBreak="break-word" />

        {data.video_url || data.images.length > 0 ? (
          <>
            <Gap side={20} />
            <ImageWrapper>
              <VideoPreview src={data.video_url} controls preload="none" />
              {data.images
                .sort((a, b) => a.order - b.order)
                .map((img, index) => (
                  <ImagePreview key={index} src={img.file_url} alt={`Uploaded ${index + 1}`} />
                ))}
            </ImageWrapper>
          </>
        ) : (
          <></>
        )}
      </ContentsWrapper>
    </>
  );
};

export default ReportPostInfo;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  border-bottom: 1px solid ${color.greyd4};
`;

const KeyAndValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const KeyBadge = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: ${color.greyf4};
  border-radius: 50px;
`;

const ContentsWrapper = styled.div`
  padding: 30px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 209px;
  overflow-x: auto;
  column-gap: 20px;
`;

const VideoPreview = styled.video`
  width: 209px;
  height: 209px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 6px;
`;

const ImagePreview = styled.img`
  width: 209px;
  height: 209px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 6px;
`;
