var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import { useExternalState } from "../../helpers/hooks/useExternalState";
import "./check-box-style.css";
import Composer from "../core/composer";
import "../../styles/token/font.css";
import "../../styles/token/color.css";
var CheckBoxLabel = function (props) {
    var asChild = props.asChild, children = props.children;
    var id = useContext(CheckBoxContext).id;
    var Comp = asChild ? Composer : "label";
    return (_jsx(Comp, { className: "nuri-theme base-check-box label", htmlFor: id, children: children }));
};
var CheckBoxInput = function (props) {
    var asChild = props.asChild, children = props.children, className = props.className, rest = __rest(props, ["asChild", "children", "className"]);
    var _a = useContext(CheckBoxContext), checked = _a.checked, toggleChecked = _a.toggleChecked, id = _a.id, disabled = _a.disabled;
    var Comp = asChild ? Composer : "input";
    return (_jsx(Comp, __assign({ type: "checkbox", checked: checked, onChange: disabled ? undefined : toggleChecked, onClick: disabled ? undefined : toggleChecked, id: id, className: "nuri-theme check-box-input ".concat(className, " ").concat(checked ? "checked" : "unchecked", " ").concat(disabled ? "disabled" : "") }, rest, { children: children })));
};
var CheckBoxContext = createContext({
    checked: false,
    toggleChecked: function () { },
    id: "",
    disabled: false,
});
var CheckBoxRoot = function (props) {
    var externalChecked = props.checked, externalOnChange = props.onChange, id = props.id, children = props.children, disabled = props.disabled;
    var _a = useExternalState({
        prop: externalChecked,
        handler: externalOnChange,
        defaultProp: externalChecked !== null && externalChecked !== void 0 ? externalChecked : false,
    }), checked = _a[0], setChecked = _a[1];
    var toggleChecked = function () {
        setChecked(function (prev) { return !prev; });
    };
    return (_jsx("div", { className: "check-box-root", children: _jsx(CheckBoxContext.Provider, { value: { checked: checked, toggleChecked: toggleChecked, id: id, disabled: disabled }, children: children }) }));
};
CheckBoxRoot.Label = CheckBoxLabel;
CheckBoxRoot.Input = CheckBoxInput;
export default CheckBoxRoot;
