import { ColorTypes, color, getChannelStatusColorByLabel, getChannelStatusLabel } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import { addCommaToNumber } from 'utils/common/addCommaToNumber';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { CommunityChannelDetailAPI } from 'types/community/remote';

interface Props {
  data: CommunityChannelDetailAPI['Get']['Response'];
}

const ChannelDetailStatus = ({ data }: Props) => {
  const { is_display, name, description, post_count, total_likes, total_comments, total_views, background_url } = data;
  const statusLabel = getChannelStatusLabel(is_display);
  const statusColor = getChannelStatusColorByLabel(statusLabel);

  return (
    <Container>
      <TextSection>
        <StatusContainer>
          <StatusBadge>
            <StyledText text="Status" fontSize="sm" fontColor="grey52" />
          </StatusBadge>
          <StatusTextBadge borderColor={statusColor}>
            <StyledText text={statusLabel} fontColor={statusColor} fontWeight="semiBold" fontSize="sm" />
          </StatusTextBadge>
        </StatusContainer>
        <Gap side={30} />
        <ChannelInfoContainer>
          <LogoImg src={data.logo_url} />
          <ChannelInfoWrapper>
            <ChannelTextWrapper>
              <StyledText text={name} fontSize="sm" fontWeight="semiBold" numberOfLines={1} />
              <Gap side={10} />
              <StyledText text={description} fontSize="sm" numberOfLines={6} wordBreak="break-word" />
            </ChannelTextWrapper>
            <FooterWrapper>
              <PostTextWrapper>
                <StyledText text="• Posts" fontSize="sm" fontWeight="bold" />
                <StyledText text={addCommaToNumber(post_count)} fontSize="sm" />
              </PostTextWrapper>

              <CountContainer>
                <CountTextWrapper>
                  <Icon type="heart" width={20} />
                  <StyledText text={addCommaToNumber(total_likes)} fontSize="sm" />
                </CountTextWrapper>
                <CountTextWrapper>
                  <Icon type="speech_bubble" width={20} />
                  <StyledText text={addCommaToNumber(total_comments)} fontSize="sm" />
                </CountTextWrapper>
                <CountTextWrapper>
                  <Icon type="eye" width={20} />
                  <StyledText text={addCommaToNumber(total_views)} fontSize="sm" />
                </CountTextWrapper>
              </CountContainer>
            </FooterWrapper>
          </ChannelInfoWrapper>
        </ChannelInfoContainer>
      </TextSection>

      <ImageSection>
        <BackgroundImageBadge>
          <StyledText text="Background Image" fontColor="grey52" fontSize="sm" />
        </BackgroundImageBadge>

        <ChannelImg src={background_url} />
      </ImageSection>
    </Container>
  );
};

export default ChannelDetailStatus;

const Container = styled.div`
  display: flex;
  padding: 30px;
  column-gap: 80px;
  justify-content: space-between;
`;

const StatusContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;
const StatusBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82px;
  padding: 8px 20px;
  border-radius: 50px;
  background-color: ${color.greyf4};
`;

const StatusTextBadge = styled.div<{ borderColor: ColorTypes }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  padding: 8.25px 24px;
  border: 1px solid ${({ borderColor }) => color[borderColor]};
`;

const ChannelInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  height: 100%;
`;
const ChannelInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const LogoImg = styled.img`
  width: 58px;
  min-width: 58px;
  height: 58px;
  min-height: 58px;
  border-radius: 100%;
  border: 1px solid ${color.greye4};
`;

const ChannelTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PostTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const CountContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 38px;
`;

const CountTextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 375px;
  min-height: 262px;
`;

const BackgroundImageBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  background-color: ${color.greyf4};
  border-radius: 50px;
  width: 159px;
  margin-bottom: 50px;
`;

const ChannelImg = styled.img`
  width: 100%;
  aspect-ratio: 375/120;
  border-radius: 6px;
  overflow: hidden;
  object-fit: cover;
`;
