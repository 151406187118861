import { ChangeEvent, useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { CTAButton } from '@nurihaus/web-design-system';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { errToErrMsg } from 'utils/common/errToErrMsg';

import { usePostCommunityComment } from 'hooks/community/queries';

import PageLoading from 'components/common/loading/PageLoading';
import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

interface Props {
  title?: string;
  postId: number;
  close: () => void;
}

const CreatePostCommentModal = ({ title, postId, close }: Props) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    queryClient.invalidateQueries(['postDetail', postId]);
    queryClient.invalidateQueries(['commentList', 1, 20, postId]);

    close();
  };
  const handleError = (error: AxiosError) => {
    dispatch(
      SetSnackbar({
        snackbarOpen: true,
        snackbarMessage: errToErrMsg(error.response),
        severity: 'error',
      })
    );
  };
  const { mutate: createComment, isLoading } = usePostCommunityComment({ handleError, handleSuccess });
  const [content, setContent] = useState('');
  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 1000);
    setContent(value);
  };

  const handleCreateClick = () => {
    if (!isLoading) {
      createComment({ post_id: postId, content, is_active: true, is_display: true });
    }
  };

  const createDisabled = isLoading || content.length < 3;

  return (
    <ModalForm width="500px" handleClose={close}>
      {isLoading && <PageLoading isLoading />}
      <Container>
        <TitleWrapper>
          <StyledText text="Add a Comment" fontSize="lg" fontWeight="bold" />
        </TitleWrapper>
        <InputWrapper>
          <Gap side={30} />
          <PostTitleWrapper>
            <StyledText text="• Post:" fontSize="sm" fontColor="grey52" fontWeight="semiBold" />
            <StyledText text={title} fontSize="sm" wordBreak="break-word" numberOfLines={1} width="calc(100% - 60px)" />
          </PostTitleWrapper>
          <Gap side={16} />
          <CommentWrapper>
            <StyledText text="• Comment" fontSize="sm" fontColor="grey52" fontWeight="semiBold" />
            <Gap side={14} />
            <ContentInputWrapper>
              <ContentInput
                placeholder="Enter up to 1,000 characters (minimum 3)."
                value={content}
                onChange={handleInput}
              />
              <ContentLengthWrapper>
                <StyledText fontSize="base" fontWeight="medium" text={`${content?.length}/1000`} fontColor="greya1" />
              </ContentLengthWrapper>
            </ContentInputWrapper>
          </CommentWrapper>
        </InputWrapper>
        <Gap side={50} />
        <FooterWrapper>
          <CTAButton style={{ width: '100%' }} text="Cancel" variant="ghost" onClick={close} />
          <CTAButton style={{ width: '100%' }} text="Create" disabled={createDisabled} onClick={handleCreateClick} />
        </FooterWrapper>
      </Container>
    </ModalForm>
  );
};

export default CreatePostCommentModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${color.greye4};
  width: calc(100% + 60px);
  margin: -15px -30px 0 -30px;
  padding: 0 0 30px 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 16px;
`;

const PostTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const CommentWrapper = styled.div``;

const ContentInput = styled.textarea`
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  resize: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  position: relative;

  &:focus {
    outline: none;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const ContentInputWrapper = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  padding: 14px;
  transition: border 0.2s ease-in-out;

  &:focus-within {
    border: 1.5px solid ${color.violet4c};
    outline: none;
  }
`;

const ContentLengthWrapper = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;
  pointer-events: none;
`;
