import { ComponentPropsWithoutRef } from 'react';

import { UserType } from '@NURIHAUS-Dev/core-business';
import { ReactComponent as BusinessBadge } from 'assets/SVG/badge/business_badge.svg';
import { ReactComponent as CreatorBadge } from 'assets/SVG/badge/creator_badge.svg';
import { ReactComponent as MemberBadge } from 'assets/SVG/badge/member_badge.svg';
import { ReactComponent as StaffBadge } from 'assets/SVG/badge/staff_badge.svg';
import { ReactComponent as VerifiedBadge } from 'assets/SVG/badge/verified_badge.svg';

interface Props extends ComponentPropsWithoutRef<'svg'> {
  userType: UserType;
  width?: number;
  height?: number;
}

const BadgeIcon = ({ userType, width = 20, height = 20, ...props }: Props) => {
  switch (userType) {
    case 'normal':
      return <MemberBadge width={width} height={height} {...props} />;
    case 'influencer':
      return <CreatorBadge width={width} height={height} {...props} />;
    case 'verified':
      return <VerifiedBadge width={width} height={height} {...props} />;
    case 'partner':
    case 'brand':
      return <BusinessBadge width={width} height={height} {...props} />;
    case 'super':
      return <StaffBadge width={width} height={height} {...props} />;
    default:
      return <></>;
  }
};

export default BadgeIcon;
