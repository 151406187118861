import { ReactNode, createContext, useContext, useState } from 'react';

interface BrandListContextType {
  expanded: boolean;
  toggleExpanded: () => void;
  collapse: () => void;
}

export const BrandListContext = createContext<BrandListContextType | null>(null);

export const BrandListProvider = ({ children }: { children: ReactNode }) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const collapse = () => {
    setExpanded(false);
  };

  return (
    <BrandListContext.Provider value={{ expanded, toggleExpanded, collapse }}>{children}</BrandListContext.Provider>
  );
};

export const useBrandListContext = () => {
  const context = useContext(BrandListContext);
  if (!context) {
    throw new Error('useBrandListContext must be used within a BrandListProvider');
  }

  return context;
};
