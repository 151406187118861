import { Dispatch, SetStateAction } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { Pagination } from '@mui/material';
import styled from 'styled-components';

interface Props {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  page_size: number;
  count: number;
}

const PostCommentPagination = ({ page, setPage, page_size, count }: Props) => {
  const handlePaginationChange = (value: number) => {
    setPage(value);
  };

  return (
    <Container>
      <Pagination
        page={page}
        count={Math.ceil(count / page_size) || 1}
        boundaryCount={3}
        onChange={(_, v) => handlePaginationChange(v)}
      />
    </Container>
  );
};

export default PostCommentPagination;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${color.greye4};
  padding: 20px 0;
`;
