import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import TableRoot from "./base-table";
import BaseButton from "../button/base-button";
import "./table-style.css";
var DataTable = function (props) {
    var rows = props.rows, columns = props.columns, _a = props.fixedRowsIndex, fixedRowsIndex = _a === void 0 ? 0 : _a;
    var _b = useState(rows.map(function (row) { return ({ row: row, hide: false }); })), displayRows = _b[0], setDisplayRows = _b[1];
    useEffect(function () {
        setDisplayRows(rows.map(function (row) {
            var _a, _b;
            return ({
                row: row,
                hide: (_b = (_a = displayRows.find(function (item) { return item.row.id === row.id; })) === null || _a === void 0 ? void 0 : _a.hide) !== null && _b !== void 0 ? _b : false,
            });
        }));
    }, [rows]);
    return (_jsxs(TableRoot, { children: [_jsxs(TableRoot.Header, { className: "data-table", children: [_jsx(TableRoot.Row, { className: "fixed-rows", children: displayRows.map(function (item, index) {
                            if (index < fixedRowsIndex) {
                                return (_jsx(TableRoot.Cell, { children: item.row.value }, item.row.id));
                            }
                        }) }), _jsx(TableRoot.Row, { children: displayRows.map(function (item, index) {
                            var _a;
                            if (index >= fixedRowsIndex) {
                                return (_jsx(TableRoot.Cell, { "data-id": item.row.id, className: "".concat(item.hide ? "hide" : ""), children: _jsx(BaseButton, { asChild: true, onClick: function () { var _a, _b; return (_b = (_a = item.row).onClickRow) === null || _b === void 0 ? void 0 : _b.call(_a, item.row.id); }, children: (_a = item.row.value) !== null && _a !== void 0 ? _a : _jsx(_Fragment, {}) }) }, item.row.id));
                            }
                        }) })] }), _jsx(TableRoot.Body, { className: "data-table", children: columns.map(function (column, index) {
                    return (_jsxs("td", { style: { display: "flex" }, className: "data-table common-row", children: [_jsx(TableRoot.Row, { className: "fixed-rows", children: displayRows.map(function (item, i) {
                                    var _a, _b;
                                    if (i < fixedRowsIndex) {
                                        return (_jsx(TableRoot.Cell, { "data-id": item.row.id, className: "".concat(item.hide ? "hide" : ""), children: _jsx(BaseButton, { asChild: true, onClick: function () {
                                                    var _a, _b;
                                                    return (_b = (_a = column[item.row.id]).onClickCell) === null || _b === void 0 ? void 0 : _b.call(_a);
                                                }, children: (_b = (_a = column[item.row.id]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : _jsx(_Fragment, {}) }) }, item.row.id + Math.random()));
                                    }
                                }) }), _jsx(TableRoot.Row, { children: displayRows.map(function (item, i) {
                                    var _a, _b;
                                    if (i >= fixedRowsIndex) {
                                        return (_jsx(TableRoot.Cell, { "data-id": item.row.id, className: "".concat(item.hide ? "hide" : ""), children: _jsx(BaseButton, { asChild: true, onClick: function () {
                                                    var _a, _b;
                                                    return (_b = (_a = column[item.row.id]).onClickCell) === null || _b === void 0 ? void 0 : _b.call(_a);
                                                }, children: (_b = (_a = column[item.row.id]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : _jsx(_Fragment, {}) }) }, item.row.id + Math.random()));
                                    }
                                }) })] }, index));
                }) })] }));
};
export default DataTable;
