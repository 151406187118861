export var colors = {
    black: "#000000",
    white: "#ffffff",
    grayFa: "#fafafa",
    grayF4: "#f4f4f5",
    grayE4: "#e4e4e7",
    grayD4: "#d4d4d8",
    grayA1: "#a1a1aa",
    gray71: "#71717a",
    gray52: "#52525b",
    gray3f: "#3f3f46",
    gray27: "#27272a",
    gray18: "#18181b",
    purple39: "#391d5d",
    purple4c: "#4c277c",
    purpleDd: "#ddd1f0",
    purpleE9: "#e9e0f5",
    purpleF4: "#f4effa",
    purple41: "#4c1d88",
    purple95: "#957eb4",
    accentRedDf: "#df535c",
    accentRedC8: "#c8424b",
    accentMagentaB0: "#b02e4c",
    accentGreen28: "#289a3a",
};
