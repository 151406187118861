import { ChangeEvent } from 'react';

import { Grid } from '@mui/material';
import styled from 'styled-components';

import NormalButton from 'components/common/button/NormalButton';
import ImageInput from 'components/common/input/ImageInput';
import TextInput from 'components/common/input/customMuiInput/TextInput';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import BlockToCenter from 'components/layout/common/BlockToCenter';
import Gap from 'components/layout/common/Gap';
import { Column, FullBorder, Row } from 'components/layout/common/InfoCard';

import { color } from 'styles/assets';

interface Props {
  basicInfo: {
    name: string;
    coverUrl: string;
  };
  infoHandler: (
    key: 'name' | 'coverUrl' | 'welcome'
  ) => (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  welcomeMsg: string;
  next: () => void;
  welcomeDisable?: boolean;
  edit?: boolean;
}

const ChannelInfoInput = ({
  basicInfo,
  infoHandler,
  welcomeMsg,
  next,
  welcomeDisable = false,
  edit = false,
}: Props) => {
  return (
    <Column gap="30px" alignItems="center">
      <BlockToCenter>
        <StyledText fontSize="xl2" fontWeight="bold" text="New Group Chat" />
      </BlockToCenter>
      <Grid item xs={12}>
        <BlockToCenter>
          <Block>
            <TextInput label="Title" value={basicInfo.name} onChange={infoHandler('name')} placeholder="Title" />
          </Block>
        </BlockToCenter>
      </Grid>
      <Grid item xs={12}>
        <BlockToCenter>
          <Block>
            <Column>
              <StyledText fontSize="base" fontWeight="bold" text="Image" />
              <FullBorder margin="20px" />
              <Gap side={5} />
              <Row justifyContent="center">
                <ImageInput img={basicInfo.coverUrl} imgHandler={infoHandler('coverUrl')} />
              </Row>
            </Column>
          </Block>
        </BlockToCenter>
      </Grid>
      {!welcomeDisable && (
        <Grid item xs={12}>
          <BlockToCenter>
            <Block>
              <Column>
                <StyledText fontSize="base" fontWeight="bold" text="Starting Message" />
                <FullBorder margin="20px" />
                <MessageWrapper>
                  <MessageInput
                    maxLength={100}
                    value={welcomeMsg}
                    onChange={infoHandler('welcome')}
                    placeholder="Please write a short introduction about this group chat."
                  />
                  <MessageLength>
                    <StyledText
                      fontSize="base"
                      fontWeight="medium"
                      text={`${welcomeMsg.length}/100`}
                      fontColor="greya1"
                    />
                  </MessageLength>
                </MessageWrapper>
              </Column>
            </Block>
          </BlockToCenter>
        </Grid>
      )}
      <Grid item xs={12}>
        <BlockToCenter>
          <ButtonWrapper>
            <NormalButton
              sizeType="xLarge"
              text={edit ? 'Save Changes' : 'Next'}
              onPress={next}
              width="210px"
              disabled={!basicInfo.name || !basicInfo.coverUrl || (!welcomeDisable && !welcomeMsg)}
            />
          </ButtonWrapper>
        </BlockToCenter>
      </Grid>
    </Column>
  );
};

export default ChannelInfoInput;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: '100%';
`;

const MessageWrapper = styled.div`
  position: relative;
`;

const MessageInput = styled.textarea`
  width: 100%;
  min-height: 268px;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  padding: 14px;
  resize: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${color.black};
  background-color: ${color.white};
  ::placeholder {
    color: ${color.greya1};
  }
`;

const MessageLength = styled.div`
  position: absolute;
  bottom: 23px;
  right: 14px;
`;
