var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./text-style.css";
import "../../../styles/token/font.css";
import "../../../styles/token/color.css";
var Text = function (props) {
    var text = props.text, size = props.size, weight = props.weight, color = props.color, textAlign = props.textAlign, textDecoration = props.textDecoration, wordBreak = props.wordBreak, whiteSpace = props.whiteSpace, as = props.as, style = props.style;
    var Comp = as ? as : "p";
    return (_jsx(Comp, { className: "nuri-theme base-text ".concat(/^\d+xl$/.test(size) ? "t-".concat(size) : size, " ").concat(weight, " textAlign-").concat(textAlign, " textDecoration-").concat(textDecoration, " wordBreak-").concat(wordBreak, " whiteSpace-").concat(whiteSpace, " ").concat(color ? "color-".concat(color) : ""), style: __assign({}, style), children: text }));
};
export default Text;
