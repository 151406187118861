import { getUserRole } from '@NURIHAUS-Dev/core-business';

import { UserDetails, UserInfo } from 'types/account/internal';

export type UserType = 'super' | 'brand' | 'influencer' | 'rejected' | 'partner' | 'verified' | 'normal';

export const loginPermission = (user: UserDetails) => {
  const { account_type, email_valid } = user;
  const userType = getUserRole(user);
  // staff and partner and can access
  if (userType === 'super' || userType === 'partner' || userType === 'nuricrew_leader') return 'accessAvailable';
  // influencer, rejected account block
  else if (account_type !== 2) throw { response: { data: "You don't have permission." } };
  // normal user and business user need to authenticate email
  else if (!email_valid) return 'AuthenticateEmail';
  // business user who authenticated email can access
  else return 'accessAvailable';
};

export const getUpperMenu = (
  user: UserDetails
): { primary: string; pathname: string; query?: { [key: string]: string }; check: (p: string) => boolean }[] => {
  const userType = getUserRole(user);

  const dashboard = {
    primary: 'Dashboard',
    pathname: '',
    check: (pathname: string) => pathname.split('/')?.[1] === '',
  };
  const chat = {
    primary: 'Chat',
    pathname: 'chat',
    query: { tab: 'campaign', search: '' },
    check: (pathname: string) => pathname.split('/')?.[1] === 'chat',
  };
  const banner = {
    primary: 'Banner',
    pathname: 'banner',
    check: (pathname: string) => pathname.split('/')?.[1] === 'banner',
  };
  const benefit = {
    primary: 'Benefit',
    pathname: 'list/benefit',
    check: (pathname: string) => pathname.split('/')?.[2] === 'benefit',
  };
  const quest = {
    primary: 'Quest',
    pathname: 'list/quest',
    check: (pathname: string) => pathname.split('/')?.[2] === 'quest',
  };
  const users = {
    primary: 'Users',
    pathname: 'users',
    check: (pathname: string) => pathname.split('/')?.[1] === 'users',
  };
  const myJelly = {
    primary: 'My Jelly',
    pathname: 'point/my-jelly',
    check: (pathname: string) => pathname.split('/')?.[1] === 'point' && pathname.split('/')?.[2] === 'my-jelly',
  };
  const payout = {
    primary: 'Payout',
    pathname: 'payout',
    check: (pathname: string) => pathname.split('/')?.[1] === 'payout',
  };
  const analytics = {
    primary: 'Analytics',
    pathname: 'analytics',
    check: (pathname: string) => pathname.split('/')?.[1] === 'analytics',
  };
  const notifications = {
    primary: 'Notifications',
    pathname: 'notifications',
    check: (pathname: string) => pathname.split('/')?.[1] === 'notifications',
  };

  const community = {
    primary: 'Community',
    pathname: 'community/manage/channel/list',
    check: (pathname: string) => pathname.startsWith('/community'),
    subMenu: [
      {
        primary: 'Manage Channel',
        pathname: 'community/manage/channel/list',
        check: (pathname: string) => pathname.startsWith('/community/manage/channel'),
      },
      {
        primary: 'Manage Report',
        pathname: 'community/manage/report/list',
        check: (pathname: string) => pathname.startsWith('/community/manage/report'),
      },
    ],
  };

  switch (userType) {
    case 'partner':
      return [dashboard, chat, banner, benefit, quest, users, notifications, community];
    case 'super':
      return [dashboard, chat, banner, benefit, quest, users, myJelly, payout, analytics, notifications, community];
    case 'brand':
      return [dashboard, chat, benefit, quest, myJelly];
    case 'nuricrew_leader':
      return [dashboard, chat, benefit, quest];

    default:
      return [];
  }
};

export const getLowerMenu = (guideLink: string, userInfo: UserInfo, mutate: any) => {
  const { refresh_token } = userInfo;

  const guide = {
    primary: 'Admin Guide',
    pathname: 'bulb',
    onClick: () => window.open(guideLink, '_blank'),
  };

  const setting = {
    primary: 'Setting',
    pathname: 'settings',
    check: (pathname: string) => pathname.split('/')?.[1] === 'settings',
  };

  const logout = {
    primary: 'Logout',
    pathname: 'logout',
    onClick: () => mutate({ refresh: refresh_token }),
  };

  return [guide, setting, logout];
};
