/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

import { CurrentCampaignType } from '@NURIHAUS-Dev/core-business';
import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  bulkAccept,
  completeApplyAndLeaveChat,
  completeCampaign,
  getBenefitStatisticsByIdAPI,
  getCampaignSummaryApi,
  getCarriers,
  getCompleteStateListCSV,
  getCreateCategoryList,
  getEarnDetailApi,
  getEarnState,
  getQuestStatisticsByIdAPI,
  getSpendDetailApi,
  getSpendState,
  getStateHistoryDetailApi,
  getStateHistoryListApi,
  getStateList,
  getStateListCSV,
  postBenefitBulkCompleteByIdsAPI,
  postBenefitBulkDropByIdsAPI,
  postBulkShippingInfo,
  postQuestBulkCompleteByIdsAPI,
  postQuestBulkDropByIdsAPI,
  postShippingInfo,
} from 'api/lounge';

import { useQueryParams } from 'hooks/common/useQueryParams';
import { useSuspendedInfiniteQuery } from 'hooks/common/useSuspendedInfiniteQuery';

import { selectAccount } from 'features/redux/selectors/accounts';
import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { ApplyHistory, Campaign, CampaignStatistics, CampaignSummary } from 'types/campaign/internal';
import {
  GetCompleteStateListCSVPayload,
  GetStateListCSVPayload,
  GetStateListPayload,
  PostBulkShippingInfoPayload,
  PostBulkShippingInfoResponse,
  PostShippingInfoPayload,
  PostShippingInfoResponse,
  QuestBulkCompleteAPI,
  QuestBulkDropAPI,
} from 'types/campaign/remote';

export const campaignDetailKeys = {
  all: ['campaignDetail'] as const,
  stateHistoryLists: () => [...campaignDetailKeys.all, 'stateHistoryList'] as const,
  stateHistory: (
    campaign?: 'benefit' | 'quest' | string,
    objectId?: number,
    filters?: string,
    order?: string,
    page?: number,
    pageSize?: number
  ) => [...campaignDetailKeys.stateHistoryLists(), campaign, objectId, filters, order, page, pageSize] as const,
  stateHistoryDetail: (stateId: number) => [...campaignDetailKeys.all, 'stateHistoryDetail', stateId] as const,
};

export const useCampaignDetailData = ({
  campaign,
  detailId,
}: {
  campaign?: 'benefit' | 'quest' | string;
  detailId: number;
}) => {
  const queryClient: any = useQueryClient();
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    if (campaign === 'quest') {
      return getEarnDetailApi(access_token, detailId);
    } else {
      return getSpendDetailApi(access_token, detailId);
    }
  };

  return useQuery<any, AxiosError>(['detailData', { campaign, detailId }], getDataFn, {
    initialData: () => {
      const detailData: Campaign = queryClient
        ?.getQueryData(['campaignList', { campaign }])
        ?.results?.find((data: Campaign) => data?.id === detailId);
      if (detailData) return detailData;
      else return undefined;
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
  });
};

export const useCampaignSummaryData = (campaign: 'quest' | 'benefit', id: number) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    return getCampaignSummaryApi(access_token, { campaign, id });
  };

  return useQuery<CampaignSummary, AxiosError>(['summaryData', { campaign, id }], getDataFn, {
    keepPreviousData: false,
  });
};

export const useCampaignStatisticData = (campaign: 'quest' | 'benefit', id: number) => {
  const getDataFn = () => {
    if (campaign === 'quest') return getQuestStatisticsByIdAPI({ id });
    else return getBenefitStatisticsByIdAPI({ id });
  };

  const { data, isLoading } = useQuery<CampaignStatistics, AxiosError>(
    ['statisticsData', { campaign, id }],
    getDataFn,
    {
      keepPreviousData: false,
    }
  );

  return { data, isLoading };
};

export const useCampaignComplete = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (e: AxiosError) => void;
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useMutation(
    ({ model, id }: { model: 'event' | 'quest' | 'market'; id: number }) => completeCampaign(model, id, access_token),
    {
      onSuccess,
      onError,
    }
  );
};

export const useHistoryData = ({
  campaign,
  id,
  page,
  pageSize,
  options,
}: {
  campaign: 'benefit' | 'quest';
  id: number;
  page?: number;
  pageSize?: number;
  options?: GetStateListPayload['options'];
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const convertCampaign = campaign === 'benefit' ? 'event' : 'quest';

  const getDataFn = () =>
    getStateList({
      access_token,
      type: convertCampaign,
      id,
      page,
      page_size: pageSize,
      options,
    });

  return useQuery(
    [
      'apply-history',
      {
        campaign,
        id,
        page,
        pageSize,
        options,
      },
    ],
    getDataFn,
    {
      keepPreviousData: false,
      cacheTime: 0,
    }
  );
};

export const useInfiniteHistoryData = ({
  campaign,
  id,
  pageSize = 20,
  options,
  enabled = true,
}: {
  campaign: 'benefit' | 'quest';
  id: number;
  page?: number;
  pageSize?: number;
  options?: GetStateListPayload['options'];
  enabled?: boolean;
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const convertCampaign = campaign === 'benefit' ? 'event' : 'quest';

  const getDataFn = async ({ pageParam = 1 }): Promise<any> => {
    const res = await getStateList({
      access_token,
      type: convertCampaign,
      id,
      page: pageParam,
      page_size: pageSize,
      options,
    });

    return res;
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess, refetch } = useInfiniteQuery<
    { count: number; next: string | null; prev: string | null; results: ApplyHistory[] },
    AxiosError
  >(['stateHistoryData', { campaign, id, pageSize, options }], getDataFn, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.next) return pages?.length + 1;
    },
    keepPreviousData: false,
    cacheTime: 0,
    enabled: enabled,
  });

  return { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isSuccess };
};

export const useGetCampaignHistoryDetail = ({
  campaign,
  stateId,
  contentId,
  enabled,
}: {
  campaign: 'quest' | 'benefit';
  stateId: number;
  contentId: number;
  enabled?: boolean;
}) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  const getDataFn = () => {
    if (campaign === 'quest') {
      return getEarnState(access_token, contentId, stateId);
    } else {
      return getSpendState(access_token, contentId, stateId);
    }
  };

  return useQuery(['quest-history-detail', stateId, contentId], getDataFn, {
    retry: true,
    enabled,
  });
};

export const useGetCategoryData = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useQuery<{ count: number; results: { id: number; name: string; slug: string }[] }, AxiosError>(
    ['create-category'],
    () => getCreateCategoryList(access_token),
    { staleTime: 30 * 60 * 1000, cacheTime: 30 * 60 * 1000, keepPreviousData: true }
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBulkAccept = (options: { onSuccess?: (data: any) => void; onError?: (error: any) => void }) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const mutation = useMutation(
    async (data: { ids: number[] }) => {
      setLoadingMessage('In progress...');
      const response = await bulkAccept(access_token, data);

      return response;
    },
    {
      onSuccess: (data) => {
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
      },
      onSettled: () => {
        setTimeout(() => setLoadingMessage(undefined), 2000);
      },
    }
  );

  return {
    mutate: mutation.mutate,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    loadingMessage,
  };
};

export const useGetCarriers = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useQuery(['carriers'], () => getCarriers(access_token), {
    select(data) {
      return data.filter((item) => item.is_active);
    },
  });
};

export const usePostShippingInfo = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation<PostShippingInfoResponse, AxiosError, PostShippingInfoPayload, void>({
    mutationFn: (shippingInfo: PostShippingInfoPayload) => postShippingInfo(access_token, shippingInfo),
    onSuccess() {
      queryClient.invalidateQueries(campaignDetailKeys.stateHistoryLists());
      dispatch(
        SetSnackbar({
          snackbarOpen: true,
          snackbarMessage: 'Successfully Saved!',
          severity: 'success',
        })
      );
    },
  });
};

export const usePostBulkShippingInfo = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useMutation<PostBulkShippingInfoResponse, AxiosError, PostBulkShippingInfoPayload[], unknown>(
    (bulkShippingInfo: PostBulkShippingInfoPayload[]) => postBulkShippingInfo(access_token, bulkShippingInfo)
  );
};

export const useGetStateListCSV = (stateListCSVParams: GetStateListCSVPayload, onComplete?: () => void) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const [enabledQuery, setEnableQuery] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading, isSuccess } = useQuery({
    queryKey: ['stateListCSV', stateListCSVParams],
    queryFn: () => getStateListCSV(access_token, stateListCSVParams),
    enabled: enabledQuery,
    onSuccess(data) {
      setIsDownloading(true);
      const BOM = '\uFEFF';
      const csvContent = BOM + data;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        const downloadUrl = window.URL.createObjectURL(xhr.response);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `${stateListCSVParams.content_type_id === 74 ? 'quest' : 'benefit'}_${
          stateListCSVParams.object_id
        }_user`;

        a.onclick = () => {
          setTimeout(() => {
            window.URL.revokeObjectURL(downloadUrl);
            window.URL.revokeObjectURL(url);
            setIsDownloading(false);
            onComplete?.();
          }, 100);
        };

        a.click();
      };

      xhr.send();
    },
    onSettled() {
      setEnableQuery(false);
    },
  });

  const download = async () => {
    setEnableQuery(true);
  };

  return {
    isLoading: isLoading || isDownloading,
    download,
    isSuccess,
  };
};

export const useGetCompleteStateListCSV = (stateListCSVParams: GetCompleteStateListCSVPayload) => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;
  const [enabledQuery, setEnableQuery] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading } = useQuery({
    queryKey: ['completeStateListCSV', stateListCSVParams],
    queryFn: () => getCompleteStateListCSV(access_token, stateListCSVParams),
    enabled: enabledQuery,
    onSuccess(data) {
      setIsDownloading(true);
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;

      a.download = `${stateListCSVParams.content_type_id === 74 ? 'quest' : 'benefit'}_${
        stateListCSVParams.object_id
      }_complete`;
      a.click();
      window.URL.revokeObjectURL(url);

      setTimeout(() => {
        setIsDownloading(false); // 다운로드 완료 후 로딩 상태 해제
      }, 500);
    },
    onSettled() {
      setEnableQuery(false);
    },
  });

  const download = () => {
    setEnableQuery(true);
  };

  return {
    isLoading: isLoading || isDownloading,
    download,
  };
};

export const useBulkComplete = (options: { onSuccess?: (data: any) => void; onError?: (error: any) => void }) => {
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    async (data: { campaign: CurrentCampaignType } & QuestBulkCompleteAPI['Post']['QueryParams']) => {
      const { campaign, object_id, state_ids } = data;
      setLoadingMessage('In progress...');
      const response =
        campaign === 'quest'
          ? await postQuestBulkCompleteByIdsAPI({ object_id, state_ids })
          : await postBenefitBulkCompleteByIdsAPI({ object_id, state_ids });

      return response;
    },
    {
      onSuccess: (data) => {
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
      },
      onSettled: () => {
        setTimeout(() => setLoadingMessage(undefined), 2000);
      },
    }
  );

  return {
    mutate: mutate,
    isLoading: isLoading,
    isSuccess: isSuccess,
    isError: isError,
    loadingMessage,
  };
};

export const useBulkDrop = (options: { onSuccess?: (data: any) => void; onError?: (error: any) => void }) => {
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    async (data: { campaign: CurrentCampaignType } & QuestBulkDropAPI['Post']['QueryParams']) => {
      const { campaign, object_id, state_ids } = data;
      setLoadingMessage('In progress...');
      const response =
        campaign === 'quest'
          ? await postQuestBulkDropByIdsAPI({ object_id, state_ids })
          : await postBenefitBulkDropByIdsAPI({ object_id, state_ids });

      return response;
    },
    {
      onSuccess: (data) => {
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      },
      onError: (error) => {
        if (options?.onError) {
          options.onError(error);
        }
      },
      onSettled: () => {
        setTimeout(() => setLoadingMessage(undefined), 2000);
      },
    }
  );

  return {
    mutate: mutate,
    isLoading: isLoading,
    isSuccess: isSuccess,
    isError: isError,
    loadingMessage,
  };
};

//v2 server

export const useGetStateHistoryListApi = () => {
  const { id, campaign } = useParams();
  const query = useQueryParams();
  const page = query.get('page');
  const pageSize = query.get('page_size');
  const filters =
    query
      .get('filters')
      ?.split('+')
      .filter((item) => item.split(':')[1])
      .join('+') ?? '';
  const order =
    query
      .get('order')
      ?.split('+')
      .filter((item) => item.split(':')[1])
      .join('+') ?? '';

  const { data, refetch } = useSuspendedInfiniteQuery({
    queryKey: campaignDetailKeys.stateHistory(
      campaign as 'benefit' | 'quest',
      Number(id),
      filters,
      order,
      page ? Number(page) : 1,
      pageSize ? Number(pageSize) : 50
    ),
    queryFn: () => {
      return getStateHistoryListApi({
        page: page ? Number(page) : 1,
        page_size: pageSize ? Number(pageSize) : 50,
        object_id: Number(id),
        content_type_id: campaign === 'quest' ? 74 : 85,
        filters,
        order,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage?.next) {
        return pages.length + 1;
      } else {
        return undefined;
      }
    },
  });

  return { data: data?.pages.flatMap((page) => page.results), dataCount: data.pages[0].count, refetch };
};

export const useGetStateHistoryDetailApi = (state_id: number) => {
  const { data } = useQuery({
    queryKey: campaignDetailKeys.stateHistoryDetail(state_id),
    queryFn: () => getStateHistoryDetailApi({ state_id: Number(state_id) }),
  });

  return { data };
};

export const usePostCompleteApplyAndLeaveChat = () => {
  const account = useSelector(selectAccount);
  const access_token = account.userInfo.access_token;

  return useMutation({
    mutationFn: (stateHistoryId: number) => completeApplyAndLeaveChat(access_token, stateHistoryId),
  });
};
