import { ChangeEvent } from 'react';

import { Pagination } from '@mui/material';
import { t } from 'i18next';
import styled from 'styled-components';

import SectionLine from 'components/common/SectionLine';
import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import BulkMessageDetailTableRow from 'components/pages/chat/bulkMessage/sentCard/BulkMessageDetailTableRow';

import { color } from 'styles/assets';

import { Messaging } from 'types/bulkMessge';
import { PaginationType } from 'types/common';

interface Props {
  page: number;
  page_size: number;
  paginationHandler: (event: ChangeEvent<unknown>, page: number) => void;
  listData: PaginationType<any>;
  bulkMessage: Messaging;
}

const BulkMessageDetailTable = ({ page, page_size, paginationHandler, listData, bulkMessage }: Props) => {
  const { count, results = [] } = listData;

  return (
    <Container>
      <TableWrapper>
        <Table gap={60} isEmpty={results.length === 0}>
          <Table.Header borderBottom={{ width: 1, style: 'solid', color: 'greye4' }} paddingBottom={24} paddingTop={24}>
            <Table.Head flex={1} minWidth="70px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-no')} />
            </Table.Head>
            <Table.Head flex={2} minWidth="170px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-ID')} />
            </Table.Head>
            <Table.Head flex={2} minWidth="170px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-nickname')} />
            </Table.Head>
            <Table.Head flex={2} minWidth="250px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-email')} />
            </Table.Head>
            <Table.Head flex={2} minWidth="120px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-bulkID')} />
            </Table.Head>
            <Table.Head flex={4} minWidth="440px">
              <StyledText
                fontSize="base"
                fontWeight="medium"
                text={t('chat.bulkMessage-detail-user-list-campaignTitle')}
              />
            </Table.Head>
            <Table.Head flex={3} minWidth="230px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-status')} />
            </Table.Head>
            <Table.Head flex={1} minWidth="270px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-send')} />
            </Table.Head>
            <Table.Head flex={1} minWidth="170px">
              <StyledText fontSize="base" fontWeight="medium" text={t('chat.bulkMessage-detail-user-list-sendTime')} />
            </Table.Head>
          </Table.Header>
          <Table.Body borderBottom={{ width: 1, style: 'solid', color: 'greye4' }}>
            {results.map((data: any, index: number) => {
              return <BulkMessageDetailTableRow key={index} rowData={data} bulkMessage={bulkMessage} />;
            })}
          </Table.Body>
        </Table>
      </TableWrapper>
      <SectionLine height="1px" backgroundColor={color.greye4} marginBottom="19px" marginTop="0px" />
      <Pagination
        count={Math.ceil(count / page_size) || 1}
        page={page}
        onChange={paginationHandler}
        boundaryCount={3}
        style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
      />
      <Gap side={20} />
    </Container>
  );
};

export default BulkMessageDetailTable;

const Container = styled.div`
  background-color: ${color.white};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: 910px;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;
