import CommunityChannelListContainer from 'containers/community/channel/CommunityChannelListContainer';

import DashboardLayout from 'components/layout/dashboard/DashboardLayout';

const CommunityChannelListPage = () => {
  return (
    <DashboardLayout headerTitle="Community" innerPadding="40px">
      <CommunityChannelListContainer />
    </DashboardLayout>
  );
};

export default CommunityChannelListPage;
