import React, { CSSProperties, useEffect, useState } from 'react';

import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

import { FontSizeTypes, FontWeightTypes, color } from 'styles/assets';

interface Option<T> {
  value: T;
  label: string;
}

interface Props<T extends string | number> {
  value?: T;
  defaultValue?: T;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: Option<T>[];
  column?: boolean;
  gap?: string;
  name?: string;
  disabled?: boolean;
  buttonStyle?: CSSProperties;
  textStyle?: { fontSize?: FontSizeTypes; fontWeight?: FontWeightTypes };
}

const RadioButton = <T extends string | number>({
  value,
  defaultValue,
  onChange,
  options = [],
  column = false,
  gap = '11px',
  name,
  disabled,
  buttonStyle,
  textStyle = { fontSize: 'base', fontWeight: 'medium' },
}: Props<T>) => {
  const [internalValue, setInternalValue] = useState(
    defaultValue || (options.length > 0 ? options[0].value : undefined)
  );

  useEffect(() => {
    if (!defaultValue && options.length > 0 && internalValue === undefined) {
      setInternalValue(options[0].value);
    }
  }, [options, defaultValue, internalValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    } else {
      setInternalValue(e.target.value as T);
    }
  };

  const currentValue = value !== undefined ? value : internalValue;

  return (
    <CheckboxContainer column={column} gap={gap} data-testid="radio-container">
      {options.map((el, idx) => {
        const isChecked = currentValue === el.value;

        return (
          <CheckBoxWrapper key={idx}>
            <CheckBox style={buttonStyle} disabled={disabled} checked={isChecked} htmlFor={`option-${el.label}-${idx}`}>
              <HiddenCheckbox
                id={`option-${el.label}-${idx}`}
                name={name}
                type="radio"
                data-testid={`radio-${el.value}`}
                onChange={handleChange}
                value={el.value}
                checked={isChecked}
                disabled={disabled}
              />
            </CheckBox>
            {el.label && (
              <Label htmlFor={`option-${el.label}-${idx}`} data-testid={`radio-label-${el.value}`}>
                <StyledText fontSize={textStyle.fontSize} fontWeight={textStyle.fontWeight} text={el.label} />
              </Label>
            )}
          </CheckBoxWrapper>
        );
      })}
    </CheckboxContainer>
  );
};

export default RadioButton;

const CheckboxContainer = styled.div<{ column: boolean; gap: string }>`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: ${(props) => (props.column ? 'flex-start' : 'center')};
  gap: ${(props) => props.gap};
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBox = styled.label<{ checked: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: ${color.greye4};
  cursor: pointer;
  ::after {
    content: '';
    width: 42%;
    height: 42%;
    border-radius: 100%;
    background-color: ${(props) => (props?.disabled ? color.greya1 : color.violet39)};
    display: ${(props) => (props.checked ? 'block' : 'none')};
  }
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Label = styled.label`
  display: inline-block;
  padding-left: 8px;
  cursor: pointer;
`;
