import { ChangeEvent, useState } from 'react';

import styled from 'styled-components';

import { useGetReportList } from 'hooks/account/queries';

import Gap from 'components/layout/common/Gap';
import ReportListHeader from 'components/pages/community/report/list/ReportListHeader';
import ReportTable from 'components/pages/community/report/list/ReportTable';

const CommunityReportListContainer = () => {
  const [page, setPage] = useState(1);

  const paginationHandler = (_e: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const { data, isLoading } = useGetReportList({ page, page_size: 14 });

  if (isLoading || !data) return null;

  return (
    <Container>
      <ReportListHeader />
      <Gap side={20} />

      <ReportTable page={page} listData={data} paginationHandler={paginationHandler} />
    </Container>
  );
};

export default CommunityReportListContainer;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 60px;
  max-height: 1200px;
`;
