// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-check-box {
  &.label {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
    color: var(--color-primary-black);
  }
}

.check-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-box-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1.5px solid var(--color-point-purple-39);

  &:where(.unchecked) {
    background-color: #fff;
  }

  &:where(.checked) {
    background-color: var(--color-point-purple-39);
  }

  &:where(.disabled) {
    border: none;
    background-color: var(--color-primary-gray-d4);
  }
}
`, "",{"version":3,"sources":["webpack://./components/check-box/check-box-style.css"],"names":[],"mappings":"AAAA;EACE;IACE,8BAA8B;IAC9B,sCAAsC;IACtC,iCAAiC;EACnC;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gDAAgD;;EAEhD;IACE,sBAAsB;EACxB;;EAEA;IACE,8CAA8C;EAChD;;EAEA;IACE,YAAY;IACZ,8CAA8C;EAChD;AACF","sourcesContent":[".base-check-box {\n  &.label {\n    font-size: var(--font-size-sm);\n    font-weight: var(--font-weight-medium);\n    color: var(--color-primary-black);\n  }\n}\n\n.check-box {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n\n.check-box-input {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  border-radius: 4px;\n  border: 1.5px solid var(--color-point-purple-39);\n\n  &:where(.unchecked) {\n    background-color: #fff;\n  }\n\n  &:where(.checked) {\n    background-color: var(--color-point-purple-39);\n  }\n\n  &:where(.disabled) {\n    border: none;\n    background-color: var(--color-primary-gray-d4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
