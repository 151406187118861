import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import Block from 'components/layout/common/Block';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

interface Props {
  logo_url?: string;
}

const EditChannelLogo = ({ logo_url }: Props) => {
  return (
    <Block>
      <Container>
        <KeyBadge text="Channel logo" />
        <LogoImage src={logo_url} />
      </Container>
    </Block>
  );
};

export default EditChannelLogo;

const LogoImage = styled.img`
  width: 124px;
  height: 124px;
  border-radius: 100%;
  border: 1px solid ${color.greye4};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;
