import { color, getUserRole } from '@NURIHAUS-Dev/core-business';
import { Avatar } from '@nurihaus/web-design-system';
import styled from 'styled-components';

import { useCountry } from 'hooks/common/queries';
import { useUserDataDetail } from 'hooks/user/queries/detail';

import BadgeIcon from 'components/Icons/BadgeIcon';
import ComponentLoading from 'components/common/loading/ComponentLoading';
import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';

import { ReportDetailAPI } from 'types/account/remote';

interface Props {
  reportUserId: ReportDetailAPI['Get']['Response']['report_author']['id'];
  contentStatus: ReportDetailAPI['Get']['Response']['content_status'];
}

const ReportUserBlock = ({ reportUserId, contentStatus }: Props) => {
  const { data: authorData, isLoading } = useUserDataDetail({ id: reportUserId });

  const { filteredCountry } = useCountry({ id: authorData?.branch_id || 118 });

  const getNicknameText = () => {
    let text = '';
    if (filteredCountry) {
      text += filteredCountry.emoji;
    }

    text += authorData.nickname;

    return text;
  };

  const contentsStatusText = contentStatus ? 'Active' : 'Disabled';

  return (
    <Block padding="30px">
      <Container>
        {isLoading || !authorData ? (
          <ComponentLoading size={30} justifyContent="center" />
        ) : (
          <>
            <KeyAndValueWrapper style={{ width: '570px' }}>
              <KeyBadge>
                <StyledText text="Reported User" fontColor="grey52" fontSize="sm" />
              </KeyBadge>
              <Avatar
                size="s"
                src={authorData.profile}
                badgeOption={<BadgeIcon userType={getUserRole(authorData as any)} />}
              />
              <StyledText text={getNicknameText()} fontSize="sm" fontWeight="bold" />
            </KeyAndValueWrapper>
            <Gap side={30} />
            <KeyAndValueWrapper>
              <KeyBadge>
                <StyledText text="Contents Status" fontColor="grey52" fontSize="sm" />
              </KeyBadge>
              <StatusBadge>
                <StyledText text={contentsStatusText} fontSize="sm" fontColor="violet39" fontWeight="semiBold" />
              </StatusBadge>
            </KeyAndValueWrapper>
          </>
        )}
      </Container>
    </Block>
  );
};

export default ReportUserBlock;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const KeyAndValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;

const KeyBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  background-color: ${color.greyf4};
  border-radius: 50px;
`;

const StatusBadge = styled.div`
  padding: 11px 24px;
  border-radius: 40px;
  border: 1px solid ${color.violet39};
`;
