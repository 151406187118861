/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import SnackbarCollection from 'components/common/SnackbarCollection';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import GNB from 'components/layout/dashboard/GNB';

import { selectSnackbar } from 'features/redux/selectors/snackbar';
import { SetSnackbar } from 'features/redux/slices/snackbarSlice';

import { color } from 'styles/assets';

interface Props {
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  headerTitle?: string;
  children?: React.ReactNode;
  customStyle?: {
    backgroundColor?: string;
  };
  innerPadding?: string;
}

const DashboardLayout = ({
  headerLeft,
  headerRight,
  headerTitle,
  children,
  customStyle,
  innerPadding = '24px',
}: Props) => {
  const dispatch = useDispatch();

  const snackbar = useSelector(selectSnackbar);
  const { backgroundColor } = customStyle || {};

  const snackbarCloseHandler = () => dispatch(SetSnackbar({ ...snackbar, snackbarOpen: false }));

  return (
    <>
      <Container>
        <GNB />
        <Body>
          <Header>
            {headerTitle ? (
              <StyledText fontSize="xl" fontWeight="extraBold" text={headerTitle} />
            ) : (
              headerLeft || <Gap side={0} />
            )}
            {headerRight || <></>}
          </Header>
          <Content backgroundColor={backgroundColor}>
            <div style={{ position: 'absolute' }}>
              <SnackbarCollection
                open={snackbar.snackbarOpen}
                message={snackbar.snackbarMessage}
                severity={snackbar.severity}
                handleClose={snackbarCloseHandler}
              />
            </div>
            <ContentInner innerPadding={innerPadding} className="dashboard-layout">
              {children}
            </ContentInner>
          </Content>
        </Body>
      </Container>
    </>
  );
};

export default DashboardLayout;

const Container = styled.div`
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Body = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || color.greye4};
  display: flex;
  overflow: auto;
  :-webkit-scrollbar {
    display: none; /* Webkit 기반 브라우저에서 스크롤바 숨김 */
  }
`;

const ContentInner = styled.div<{ innerPadding: string }>`
  width: 100%;
  min-width: 1000px;
  max-width: 1920px;
  padding: ${(props) => props.innerPadding};
`;
