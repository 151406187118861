import { Suspense, useEffect } from 'react';

import { useOverlay } from '@nurihaus/use-overlay';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import {
  ApiErrorBoundary,
  BaseErrorBoundary,
  RuntimeErrorBoundary,
  TimeoutErrorBoundary,
} from 'components/common/errorBoundary/ErrorBoundary';
import ReportModal from 'components/common/errorBoundary/ReportModal';
import ComponentLoading from 'components/common/loading/ComponentLoading';

import { selectAccount } from 'features/redux/selectors/accounts';

interface Props {
  isAtRoot?: boolean;
  children: React.ReactNode;
  resetKeys?: any[];
  fallbackStyle?: React.CSSProperties;
  customLoading?: React.ReactNode;
  onError?: <T extends Error>(error?: T) => void;
  level?: 'fatal' | 'error';
  ignoreError?: boolean;
}

const SafeBoundary = (props: Props) => {
  const { children, fallbackStyle, resetKeys, isAtRoot = false, onError, ignoreError = false, customLoading } = props;
  const queryClient = useQueryClient();
  const reportOverlay = useOverlay();
  const account = useSelector(selectAccount);

  useEffect(() => {
    if (!isAtRoot) return;

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.altKey && (e.metaKey || e.ctrlKey) && (e.key === 'e' || e.key === 'Dead')) {
        reportOverlay.open(({ close }) => (
          <ReportModal
            error={{
              message: 'unhandled',
              info: null,
            }}
            close={close}
          />
        ));
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      if (!isAtRoot) return;

      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <RuntimeErrorBoundary
      fallbackStyle={fallbackStyle}
      resetKeys={resetKeys}
      onError={onError}
      ignoreError={ignoreError}
    >
      <TimeoutErrorBoundary
        fallbackStyle={fallbackStyle}
        resetKeys={resetKeys}
        onError={onError}
        ignoreError={ignoreError}
      >
        <ApiErrorBoundary
          fallbackStyle={fallbackStyle}
          resetKeys={resetKeys}
          onReset={() => {
            queryClient.removeQueries(resetKeys);
            queryClient.refetchQueries(resetKeys);
          }}
          onError={onError}
          ignoreError={ignoreError}
        >
          <BaseErrorBoundary user={account}>
            <Suspense fallback={customLoading ?? <ComponentLoading size={20} />}>{children}</Suspense>
          </BaseErrorBoundary>
        </ApiErrorBoundary>
      </TimeoutErrorBoundary>
    </RuntimeErrorBoundary>
  );
};

export default SafeBoundary;
