import { ColorTypes } from 'styles/assets';

import Icon from './Icon';

export type GNBIconType =
  | 'banner'
  | 'list/benefit'
  | 'list/quest'
  | 'users'
  | 'chat'
  | 'client'
  | 'logout'
  | 'point/my-jelly'
  | 'bulb'
  | 'settings'
  | 'point/my-credit'
  | 'payout'
  | 'analytics'
  | 'notifications'
  | 'community/manage/channel/list'
  | 'community/manage/report';

interface Props {
  type: GNBIconType;
  width?: number;
  height?: number;
  color?: ColorTypes;
}

export default function GNBIcon({ type, width = 24, height = 24, color = 'black' }: Props) {
  switch (type) {
    case 'banner':
      return <Icon type="picture" width={width} height={height} fill={color} />;
    case 'list/benefit':
      return <Icon type="gift" width={width} height={height} fill={color} />;
    case 'list/quest':
      return <Icon type="map" width={width} height={height} fill={color} />;
    case 'users':
      return <Icon type="users" width={width} height={height} fill={color} />;
    case 'chat':
      return <Icon type="speech_bubble_writing" width={width} height={height} fill={color} />;
    case 'client':
      return <Icon type="handbag" width={width} height={height} fill={color} />;
    case 'logout':
      return <Icon type="exit_left" width={width} height={height} fill={color} />;
    case 'point/my-jelly':
      return <Icon type="jelly" width={width} height={height} fill={color} />;
    case 'settings':
      return <Icon type="setting" width={width} height={height} fill={color} />;
    case 'bulb':
      return <Icon type="bulb" width={width} height={height} fill={color} />;
    case 'point/my-credit':
      return <Icon type="credit" width={width} height={height} fill={color} />;
    case 'payout':
      return <Icon type="credit_card" width={width} height={height} fill={color} />;
    case 'analytics':
      return <Icon type="analytics" width={width} height={height} fill={color} />;
    case 'notifications':
      return <Icon type="notifications" width={width} height={height} fill={color} />;
    case 'community/manage/channel/list':
      return <Icon type="global_community" width={width} height={height} fill={color} />;
    case 'community/manage/report':
      return <Icon type="global_community" width={width} height={height} fill={color} />;
    default:
      return <Icon type="template" width={width} height={height} fill={color} />;
  }
}
