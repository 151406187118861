import { postUploadFileByPresignedURL, uploadToS3 } from 'api/common';

/**
 * Presigned URL을 요청하여 S3에 업로드하는 함수
 * @param file 업로드할 파일
 * @param type 업로드 타입 ('images' | 'videos')
 */
export const uploadToPresigned = async (file: File, pathPrefix: string) => {
  try {
    const presignedResponse = await postUploadFileByPresignedURL({
      path_prefix: pathPrefix,
      file_name: file.name,
      content_type: file.type,
    });

    await uploadToS3(presignedResponse.presigned_url, file);

    return {
      file_url: presignedResponse.file_url,
      object_key: presignedResponse.object_key,
    };
  } catch (error) {
    console.error('Upload failed:', error);
    throw error;
  }
};
