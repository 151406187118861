import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';
import Block from 'components/layout/common/Block';
import Gap from 'components/layout/common/Gap';
import KeyBadge from 'components/pages/community/post/detail/KeyBadge';

import { ChannelPatchInfo } from 'types/community/internal';

interface Props {
  description?: string;
  setChannelInfo: Dispatch<SetStateAction<ChannelPatchInfo | null>>;
}

const EditChannelDescription = ({ description, setChannelInfo }: Props) => {
  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 300);
    setChannelInfo((prev) => (prev ? { ...prev, description: value } : prev));
  };

  return (
    <Block>
      <KeyBadge text="Channel Description" />
      <Gap side={20} />
      <ContentInputWrapper>
        <ContentInput value={description} onChange={handleInputChange} />
        <ContentLengthWrapper>
          <StyledText fontSize="base" fontWeight="medium" text={`${description?.length}/300`} fontColor="greya1" />
        </ContentLengthWrapper>
      </ContentInputWrapper>
    </Block>
  );
};

export default EditChannelDescription;

const ContentInputWrapper = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  padding: 14px;
  transition: border 0.2s ease-in-out;

  &:focus-within {
    border: 1.5px solid ${color.violet4c};
    outline: none;
  }
`;

const ContentInput = styled.textarea`
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  resize: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  position: relative;

  &:focus {
    outline: none;
  }
`;

const ContentLengthWrapper = styled.div`
  position: absolute;
  right: 14px;
  bottom: 14px; /* 입력 영역 내부 하단에 고정 */
  pointer-events: none;
`;
