import { useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { Pagination } from '@mui/material';
import styled from 'styled-components';

import { useCommunityChannelList } from 'hooks/community/queries';

import SectionLine from 'components/common/SectionLine';
import ComponentLoading from 'components/common/loading/ComponentLoading';
import Table from 'components/common/table/Table';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import ListTableRow from 'components/pages/community/channel/list/ListTableRow';

const CommunityChannelListContainer = () => {
  const [page, setPage] = useState(1);
  const page_size = 10;
  const { data, isLoading } = useCommunityChannelList({ page, page_size });

  const paginationHandler = (value: number) => {
    setPage(value);
  };

  if (isLoading)
    return (
      <>
        <StyledText text="Channel" fontWeight="bold" fontSize="xl2" />
        <Gap side={30} />
        <Container style={{ height: '1200px' }}>
          <ComponentLoading size={50} justifyContent="center" />
        </Container>
      </>
    );

  const count = data?.count || 0;
  const channelList = data?.results || [];

  return (
    <>
      <StyledText text="Channel" fontWeight="bold" fontSize="xl2" />
      <Gap side={30} />
      <Container>
        <TableContainer>
          <Table gap={0}>
            <Table.Header borderBottom={{ style: 'solid', color: 'greyd4', width: 1 }}>
              <Table.Head flex={1} minWidth="111px">
                <StyledText text="Channel Number" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.4} minWidth="40px">
                <StyledText text="Logo" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={2}>
                <StyledText text="Channel Name" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.8}>
                <StyledText text="Status" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.6}>
                <StyledText text="Post" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.6}>
                <StyledText text="Comments" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.7}>
                <StyledText text="View" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.6}>
                <StyledText text="Likes" fontSize="sm" fontWeight="bold" />
              </Table.Head>
              <Table.Head flex={0.4}>
                <StyledText text="Edit" fontSize="sm" fontWeight="bold" />
              </Table.Head>
            </Table.Header>
            <Table.Body borderBottom={{ width: 1, style: 'solid', color: 'greye4' }}>
              {channelList.map((data, index) => {
                return <ListTableRow key={index} data={data} />;
              })}
            </Table.Body>
          </Table>
        </TableContainer>

        <SectionLine height="1px" backgroundColor={color.greye4} marginBottom="30px" marginTop="0px" />

        <Pagination
          count={Math.ceil(count / page_size) || 1}
          page={page}
          onChange={(_e, value) => paginationHandler(value)}
          boundaryCount={1}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        />
        <Gap side={30} />
      </Container>
      <Gap side={80} />
    </>
  );
};

export default CommunityChannelListContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${color.white};
  border-radius: 6px;
  height: 100%;
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;
