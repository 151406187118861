import { UserType } from '@NURIHAUS-Dev/core-business';
import { Navigate, Outlet } from 'react-router-dom';

import { hasAccessToPage } from 'utils/common/permissions';

import { UserAccessControl } from 'types/permissions';

interface ProtectedRouteProps {
  userType: UserType;
  page: keyof UserAccessControl;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ userType, page }) => {
  return hasAccessToPage(userType, page) ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
