import { ChangeEvent } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import styled from 'styled-components';

import StyledText from 'components/common/text/StyledText';

interface Props {
  content: string;
  setContent: (content: string) => void;
}

const ContentTextarea = ({ content, setContent }: Props) => {
  const handleContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 3000);
    setContent(value);
  };

  return (
    <ContentWrapper>
      <Textarea
        value={content}
        onChange={(e) => handleContentChange(e)}
        placeholder={`Enter up to 3,000 characters (minimum 10).\n\n \u00A0\u00A0• Off-topic posts or posts with many reports may be hidden.\n \u00A0\u00A0• Uploading illegal or prohibited content may lead to penalties, including a permanent suspension.`}
      />
      <MessageLength>
        <StyledText fontSize="base" fontWeight="medium" text={`${content?.length}/3000`} fontColor="greya1" />
      </MessageLength>
    </ContentWrapper>
  );
};

export default ContentTextarea;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  &::placeholder {
    font-size: 14px;
    white-space: pre-line;
    font-weight: 400;
    color: ${color.greya1};
  }
  margin-bottom: 20px;
  padding: 14px 14px 0 14px;
`;

const MessageLength = styled.div`
  position: absolute;
  bottom: 14px;
  right: 14px;
`;
