import styled from 'styled-components';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  indicatorColor?: ColorTypes;
  size: number;
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
}

const ComponentLoading = ({ indicatorColor = 'greye4', size, justifyContent = 'flex-start' }: Props) => {
  return (
    <ComponentLoadingContainer data-testid="loading-component" justifyContent={justifyContent}>
      <LoadingIndicatorRing size={size}>
        <LoadingIndicatorSingleRing size={size} indicatorColor={indicatorColor} />
        <LoadingIndicatorSingleRing size={size} indicatorColor={indicatorColor} />
        <LoadingIndicatorSingleRing size={size} indicatorColor={indicatorColor} />
        <LoadingIndicatorSingleRing size={size} indicatorColor={indicatorColor} />
      </LoadingIndicatorRing>
    </ComponentLoadingContainer>
  );
};

export default ComponentLoading;

const ComponentLoadingContainer = styled.div<{
  justifyContent: 'flex-start' | 'center' | 'flex-end' | 'space-between';
}>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const LoadingIndicatorRing = styled.div<{ size: number }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const LoadingIndicatorSingleRing = styled.div<{ size: number; indicatorColor: ColorTypes }>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border: 3px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ indicatorColor }) => color[indicatorColor]} transparent transparent transparent;
  :nth-child(1) {
    animation-delay: -0.45s;
  }
  :nth-child(2) {
    animation-delay: -0.3s;
  }
  :nth-child(3) {
    animation-delay: -0.15s;
  }
  :nth-child(4) {
    animation-delay: -0s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
