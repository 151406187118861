import { useOverlay } from '@nurihaus/use-overlay';
import { CTAButton } from '@nurihaus/web-design-system';

import CreatePostCommentModal from 'components/pages/community/post/detail/CreatePostCommentModal';

interface Props {
  postId: number;
  title?: string;
}

const CreatePostCommentButton = ({ postId, title }: Props) => {
  const createOverlay = useOverlay();

  const handleNewCommentClick = () => {
    createOverlay.open(({ close }) => {
      return <CreatePostCommentModal postId={postId} title={title} close={close} />;
    });
  };

  return <CTAButton text="+ New Comment" onClick={handleNewCommentClick} size="r" style={{ width: '180px' }} />;
};

export default CreatePostCommentButton;
