import { Fragment } from 'react';

import { t } from 'i18next';
import styled from 'styled-components';

import { formatShortDate, formatShortDateWithTime } from 'utils/common/dateFormat';

import Icon from 'components/Icons/Icon';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';
import DDayLabel from 'components/pages/campaign/detail/modals/viewCardModal/DDayLabel';

import { color } from 'styles/assets';

import { TimeLineData } from 'types/campaign/internal';

interface Props {
  timeLineData: TimeLineData;
  isExpired: boolean;
}

interface TimeLineType {
  label: string;
  dateFrom?: string | null;
  dateTo?: string | null;
  displayTime?: boolean;
  marginBottom?: number;
}

const CampaignCardTimeLine = ({ timeLineData, isExpired }: Props) => {
  const {
    date_from,
    date_to,
    selection_start_at,
    selection_end_at,
    estimated_receipt_start_at,
    estimated_receipt_end_at,
    result_deadline,
  } = timeLineData;

  const timeLineList: TimeLineType[] = [
    {
      label: t('campaign.detail-summary-schedule-apply'),
      dateFrom: date_from,
      dateTo: date_to,
      displayTime: true,
      marginBottom: 10,
    },
    {
      label: t('campaign.detail-summary-schedule-select'),
      dateFrom: selection_start_at,
      dateTo: selection_end_at,
      marginBottom: 26,
    },
    {
      label: t('campaign.detail-summary-schedule-receipt'),
      dateFrom: estimated_receipt_start_at,
      dateTo: estimated_receipt_end_at,
      marginBottom: 26,
    },
    {
      label: t('campaign.detail-summary-schedule-submit'),
      dateFrom: result_deadline,
    },
  ];
  const validTimeLineList = timeLineList.filter((timeLineData) => timeLineData.dateFrom || timeLineData.dateTo);

  return (
    <ScheduleCard>
      <ScheduleCardHeader>
        <ScheduleCardHeaderInner>
          <ScheduleCardHeaderTitle>
            <Icon type="check_calender" />
            <StyledText fontSize="sm" fontWeight="medium" text={t('campaign.detail-appView-timeline')} />
          </ScheduleCardHeaderTitle>
          {date_to && !isExpired && <DDayLabel dueDate={date_to} />}
        </ScheduleCardHeaderInner>
      </ScheduleCardHeader>
      <ScheduleCardBody>
        <TimeLines>
          {validTimeLineList.length > 1 && <Line />}
          {validTimeLineList.map((timeLineData, index) => (
            <Fragment key={timeLineData.label}>
              <TimeLine timeLineData={timeLineData} />
              {index < validTimeLineList.length - 1 && <Gap side={timeLineData.marginBottom || 0} />}
            </Fragment>
          ))}
        </TimeLines>
      </ScheduleCardBody>
    </ScheduleCard>
  );
};

export default CampaignCardTimeLine;

const getValueText = (dateFrom?: string | null, dateTo?: string | null, displayTime?: boolean) => {
  const formatter = displayTime ? formatShortDateWithTime : formatShortDate;
  const separator = displayTime ? '\n- ' : ' - ';

  if (dateFrom && dateTo) {
    return `${formatter(dateFrom)}${separator}${formatter(dateTo)}`;
  }

  if (dateFrom) {
    return formatter(dateFrom);
  }

  if (dateTo) {
    return formatter(dateTo);
  }

  return null;
};

const TimeLine = ({ timeLineData }: { timeLineData: TimeLineType }) => {
  const { label, dateFrom, dateTo, displayTime } = timeLineData;
  const valueText = getValueText(dateFrom, dateTo, displayTime);

  const isActive = isWithinPeriod(dateFrom, dateTo);
  const isPast = isDatePast(dateTo);
  const activeColor = isActive ? 'violet39' : 'grey71';
  const weight = isActive ? 'semiBold' : 'medium';

  if (!valueText) return null;

  return (
    <TimeLineWrapper>
      <IconWrapper>
        {isActive ? (
          <Icon type="check_circle_filled" fill="violet4c" width={26} height={26} />
        ) : isPast ? (
          <Icon type="check_circle_filled" fill="greyd4" width={26} height={26} />
        ) : (
          <EmptyCircle />
        )}
      </IconWrapper>
      <KeyValue>
        <TextWrapper>
          <StyledText fontSize="sm" fontWeight={weight} text={label} fontColor={activeColor} />
        </TextWrapper>
        <TextWrapper>
          <StyledText fontSize="sm" fontWeight={weight} text={valueText} textAlign="right" fontColor={activeColor} />
        </TextWrapper>
      </KeyValue>
    </TimeLineWrapper>
  );
};

export const isWithinPeriod = (dateFrom?: string | null, dateTo?: string | null): boolean => {
  const now = new Date();

  if (dateFrom && dateTo) {
    const startDate = new Date(dateFrom);
    const endDate = new Date(dateTo);

    return now >= startDate && now <= endDate;
  }

  if (dateFrom) {
    const startDate = new Date(dateFrom);

    return now >= startDate;
  }

  if (dateTo) {
    const endDate = new Date(dateTo);

    return now <= endDate;
  }

  // dateFrom과 dateTo가 모두 없는 경우
  return false;
};

export const isDatePast = (dateString?: string | null) => {
  if (!dateString) return false;

  const now = new Date();
  const date = new Date(dateString);

  return now > date;
};

const ScheduleCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${color.greye4};
  border-radius: 6px;
  width: 100%;
  padding: 20px;
`;

const ScheduleCardHeader = styled.div`
  margin-bottom: 20px;
`;

const ScheduleCardHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ScheduleCardHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ScheduleCardBody = styled.div``;

const TimeLines = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Line = styled.div`
  position: absolute;
  top: 12.5px;
  bottom: 12.5px;
  left: 12.5px;
  width: 1px;
  background-color: ${color.greyd4};
  z-index: 0;
`;

const TimeLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
`;

const KeyValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin-top: 4px;
  gap: 4px;
`;

const TextWrapper = styled.div``;

const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`;

const EmptyCircle = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${color.greyd4};
  border-radius: 7px;
  background-color: ${color.white};
`;
