import { ChangeEvent, useState } from 'react';

import { color } from '@NURIHAUS-Dev/core-business';
import { CTAButton } from '@nurihaus/web-design-system';
import styled from 'styled-components';

import { firstLetterUpperCase } from 'utils/common/firstLetterUpperCase';

import ModalForm from 'components/common/modal/ModalForm';
import StyledText from 'components/common/text/StyledText';
import Gap from 'components/layout/common/Gap';

interface Props {
  type: string;
  contents: string;
  handleHideClick: (request: { reason_for_display_off: string }) => void;
  close: () => void;
}

const HideContentsModal = ({ type, contents, handleHideClick, close }: Props) => {
  const [inputText, setInputText] = useState('');

  const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 500);
    setInputText(value);
  };

  return (
    <ModalForm handleClose={close} width="500px">
      <Container>
        <TitleSection>
          <StyledText text="Hide This Contents" fontSize="lg" fontWeight="bold" />
        </TitleSection>
        <Gap side={30} />
        <InputSection>
          <TextWrapper>
            <StyledText text="• Type:" fontSize="sm" fontColor="grey52" />
            <StyledText text={firstLetterUpperCase(type)} fontSize="sm" />
          </TextWrapper>
          <Gap side={16} />
          <TextWrapper>
            <StyledText text="• Contents:" fontSize="sm" fontColor="grey52" />
            <StyledText width="350px" text={contents} numberOfLines={1} fontSize="sm" wordBreak="break-word" />
          </TextWrapper>
          <Gap side={16} />
          <StyledText text="• Please Write The Hide Reason" fontSize="sm" fontColor="grey52" />
          <Gap side={14} />
          <ContentInputWrapper>
            <ContentInput
              placeholder={'Enter up to 500 characters'}
              value={inputText}
              onChange={handleInput}
              name="content"
            />
            <ContentLengthWrapper>
              <StyledText text={inputText.length + '/500'} fontSize="sm" fontColor="greya1" />
            </ContentLengthWrapper>
          </ContentInputWrapper>
        </InputSection>
        <Gap side={43} />
        <FooterSection>
          <CTAButton style={{ width: '100%' }} text="Cancel" size="m" variant="ghost" onClick={close} />
          <CTAButton
            style={{ width: '100%' }}
            text="Hide"
            size="m"
            onClick={() => {
              handleHideClick({ reason_for_display_off: inputText });
              close();
            }}
            disabled={inputText.length === 0}
          />
        </FooterSection>
      </Container>
    </ModalForm>
  );
};

export default HideContentsModal;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TitleSection = styled.div`
  display: flex;
  width: calc(100% + 60px);
  justify-content: center;
  align-items: center;
  margin: -15px -30px 0px -30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${color.greye4};
`;

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterSection = styled.div`
  display: flex;
  column-gap: 16px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const ContentInputWrapper = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  border: 1px solid ${color.greyd4};
  border-radius: 4px;
  padding: 14px;
  transition: border 0.2s ease-in-out;

  &:focus-within {
    border: 1.5px solid ${color.violet4c};
    outline: none;
  }
`;

const ContentInput = styled.textarea`
  width: 100%;
  height: 150px;
  border: none;
  outline: none;
  resize: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  position: relative;

  &:focus {
    outline: none;
  }
`;

const ContentLengthWrapper = styled.div`
  position: absolute;
  right: 14px;
  bottom: 14px;
  pointer-events: none;
`;
