import { ReactNode } from 'react';

import styled from 'styled-components';

import { color } from 'styles/assets';

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const SelectorBtn = ({ isOpen, children, onClick, disabled = false }: Props) => {
  return (
    <SelectorBtnContainer data-testid="selector-button" isOpen={isOpen} onClick={onClick} disabled={disabled}>
      {children}
    </SelectorBtnContainer>
  );
};

export default SelectorBtn;

const SelectorBtnContainer = styled.button<{ isOpen: boolean; disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  height: 50px;
  width: 100%;
  border-radius: 6px;
  background-color: ${({ disabled }) => (disabled ? color.greyf4 : color.white)};
  border: 1.5px solid ${({ isOpen, disabled }) => (disabled ? 'transparent' : isOpen ? color.violet39 : color.greyd4)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    border: ${({ disabled }) => (disabled ? `1.5px solid transparent` : `1.5px solid ${color.violet39}`)};
  }
`;
